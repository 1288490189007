import ibbApi from 'src/api/ibbApi';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GET_YEAR } from "src/constants";


// export const fetchYearList = createAsyncThunk(
//     "years/fetchYearList",
//     (token: string) => {
//         const params = {
//             "for": "year",
//             "tag": "api",
//             "access_token": token
//         }
//         return ibbApi
//             .post(
//                 '/api/Swaraj2',
//                 params,
//             )
//             .then((response) => response.data.data)
//             .catch((error) => error)
//     }
// );
const params = {
    "constants":  ["filter_year"],
    "platform": process.env.REACT_APP_PLATFORM_ID
}
export const fetchYearList = createAsyncThunk(
    "years/fetchYearList",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${GET_YEAR}`,
                params,
                {
                    headers: headers
                }
            )
            .then((response) => response.data.data)
            .catch((error) => error)
        return res
    }
);

const initialState = {
    status: "idle",
    data: [],
    error: {},
};

const yearSlice = createSlice({
    name: "yearList",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchYearList.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchYearList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchYearList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export default yearSlice.reducer;