import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { SAVE_ENQUIRY } from "src/constants";

export const makeEnquiry = createAsyncThunk(
    "enquiry",
    (payload: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                SAVE_ENQUIRY,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => response.data)
            .catch((error) => error)
        return res
    }
)

const initialState={
   
}
const enquirySlice = createSlice({
    name: 'enquiryInfo',
    initialState,
    reducers: {
   
    },
  })
  
  export default enquirySlice.reducer