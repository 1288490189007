import {  FormControl, FormLabel,  Select } from '@chakra-ui/react';
import {useState ,useEffect} from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';

const styles = {
    formControl: {
        mb: 5
    },
    scroller: {
        maxHeight: 200,
        overflowY: "scroll",
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        mb: 4
    }
}

interface IFMake {
    updateStocks?:(type:any,value:any)=>void,
    filterMake:any
}
const FMake = ({ updateStocks,filterMake}: IFMake) => {
    const dispatch = useAppDispatch()
    const makeList = useAppSelector((state: any) => state.makes.data);
    const [make, setMake] = useState<string>("");
   

    useEffect(()=>{
        setMake(filterMake)
         },[filterMake])
    
 


    const handleSubmit=(e:any)=>{
  
    setMake(e.target.value);
    dispatch(fetchModelList(e.target.value)); 
    updateStocks &&  updateStocks("make",e.target.value);
    // dispatch(updateModalValue(e.target.value));
    }
    return (
        <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.legend}>Make</FormLabel>
           
            <Select placeholder='Select Make' onChange={handleSubmit} variant='mySelect'>
                {
                    (makeList.length !== 0) ? makeList.map((item: any, index: number) => (<option key={index} value={item}
                        selected={item==make}
                    >{item}</option>)) : (<option>Loading</option>)
                }
            </Select>
        </FormControl>
    );
}

export default FMake;