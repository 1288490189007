//import oemApi from 'src/api/oemApi';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IBuyFilter ,IApiResponse} from 'src/commonTypes'
import { STOCK_LISTING_API,SEARCH_BUY_LOCAL } from "src/constants";
import ibbApi from "src/api/ibbApi";



export const fetchBuyStocks = createAsyncThunk(
    "filter/fetchStocks",
    (payload: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${SEARCH_BUY_LOCAL}`,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => {
                return response.data.data})
            .catch((error) => error)
        return res
    }
)

const defaultCity= localStorage.getItem("cityId")!;

const apiResponseData: IApiResponse={
    current_page: null,
    data: [],
    first_page_url: "",
    from: null,
    last_page: null,
    last_page_url: "",
    links: [],
    next_page_url: "",
    path: "",
    per_page: null,
    prev_page_url: "",
    to: null,
    total: null
}
const filterInitialState:IBuyFilter = {
    status: "idle",
    perPage: 6,
    page: 1,
    sortOrder: "",
    certifiedStatus:false,
    minPrice: "",
    maxPrice:"",
    minYear:"",
    maxYear: "",
    minKm:"",
    maxKm:"",
    city: defaultCity? defaultCity: "",
    model: "",
    bodyType:"",
    fuelType:"",
    transmissionType:"",
    data: {},
    previousData:[],
    error: {}
};

const filterSlice = createSlice({
    name: "buyFilter",
    initialState: filterInitialState,
    reducers: {
        updatePerPage: (state, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        },
        updatePage: (state, action: PayloadAction<number>) => {
            state.page = action.payload;
        },

        updateSortOrder: (state, action: PayloadAction<string>) => {
            state.sortOrder = action.payload;
        },
        updatCertifiedStatus: (state, action: PayloadAction<boolean>) => {
            state.certifiedStatus = action.payload;
        },
        updatefuelType: (state, action: PayloadAction<string>) => {
            state.fuelType = action.payload;
        },
        updateMinYear: (state, action: PayloadAction<any>) => {
            state.minYear = action.payload;
        },
        updateMaxYear: (state, action: PayloadAction<any>) => {
            state.maxYear = action.payload;
        },
        updateMinPrice: (state, action: PayloadAction<any>) => {
            state.minPrice = action.payload;
        },
        updateMaxPrice: (state, action: PayloadAction<any>) => {
            state.maxPrice = action.payload;
        },
        updateMinKm: (state, action: PayloadAction<any>) => {
            state.minKm = action.payload;
        },
        updateMaxKm: (state, action: PayloadAction<any>) => {
            state.maxKm = action.payload;
        },
        updateFCity: (state, action: PayloadAction<string> ) => {
            state.city = action.payload;
        },
        updateTransmissionType: (state, action: PayloadAction<string>) => {
            state.transmissionType = action.payload;
        },
        updateBodyType: (state, action:PayloadAction<string>) => {
            state.bodyType = action.payload;
        },
        updateModel: (state, action: PayloadAction<string>) => {
            state.model = action.payload;
        },
        updatePreviousData: (state, action: PayloadAction<any>) => {
            state.previousData = action.payload;
        },


    },
    extraReducers: {
        [fetchBuyStocks.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchBuyStocks.fulfilled.type]: (state, action: any) => {
            state.status = "success"    
            state.data=action.payload ; 
       // state.previousData=[...state.previousData,...action.payload.data]
        },
        [fetchBuyStocks.rejected.type]: (state, action: PayloadAction<object>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export const {
    updatePerPage,
    updatePage,
    updateSortOrder,
    updatCertifiedStatus,
    updatefuelType,
    updateMinYear,
    updateMaxYear,
    updateMinPrice,
    updateMaxPrice,
    updateMinKm,
    updateMaxKm,
    updateFCity,
    updateTransmissionType,
    updateBodyType,
    updateModel,
    updatePreviousData
} = filterSlice.actions

export default filterSlice.reducer;


