import React, { FunctionComponent, lazy, Suspense } from 'react'
import { Spinner, Center } from '@chakra-ui/react'
const CustomView: FunctionComponent = (props) => {
  let themeName = `${process.env.REACT_APP_THEME}`
  const DynamicComponent = lazy(() => import(`./${themeName[0].toUpperCase() + themeName.slice(1)}`))

  return (
    <Suspense
      fallback={
        <Center>
        <Spinner
          thickness='2px'
          speed='0.65s'
          emptyColor='gray.200'
          color='gray.500'
          size='xl'
        />
        </Center>
      }>
      <DynamicComponent {...props} />
    </Suspense>
  )  
}   
 
export default CustomView