import React, { useEffect, useMemo, useState, useRef } from 'react'
import {
    Box,
    Grid,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Button,
    useDisclosure,
    useToast,
    Spinner,
    Skeleton
   
} from "@chakra-ui/react";
import VehicleCard from "../VehicleCard";
import VehicleCardLoader from '../VehicleCardLoader';
import { useAppDispatch } from 'src/redux/hooks';
import { useAppSelector } from 'src/redux/hooks';
import { fetchStocks } from 'src/redux/reducers/filterReducer';
import { fetchBuyStocks, updatePage, updateFCity } from 'src/redux/reducers/filterBuyReducer';
import { MakeEnquiry } from '../Product/makeEnquiry';
import { TAppState } from 'src/redux/reducers';
import { CompareDrawer } from '../Product/compareDrawer';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';
import InfiniteScroll from "react-infinite-scroll-component";
import { ProductCard } from './ProductCard';



export default function ProductsList({ product,handleCompare,handloadMore,updateStocks,loading ,handleResetRange}: any) {
    const toast = useToast();
    const compareDrawer = useDisclosure();

    const [comparePayload, setComparePayload] = React.useState<
        Array<{
            leadId: any,
            imageUrl: string,
            yearOfManufacture: any,
            make: string,
            model: string,
            variant: string
        }>
    >([])

    let count = useMemo(() => {
        return comparePayload.length
    }, [comparePayload]);

  
  
    return (
        <>
            <Box as="section" bg={"sectionGray"} pb={"60px"} mb={"30px"}> 
             
              {
                (loading)?(<>
                   <Grid
                        gap={{ base: "0px", xl: "10px" }}
                        templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
                    >
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                     <Skeleton startColor='#cccccc' endColor='white' height='400px' />
                    </Grid>
                </>):(
                (product.length === 0) ? (
                    <Box my={10}>
                        <Alert status='warning' variant='left-accent' flexDirection='column' alignItems='left'
                            justifyContent='left'
                            textAlign='left'
                            color='#DD6B20'
                        >
                            <AlertTitle mt={3} mb={1} >
                                Dear customer, the model that you are looking for is currently out of stock.
                            </AlertTitle>
                            <AlertDescription>
                                Please fill the below details. Your preferred dealer will get in touch with you shortly.
                            </AlertDescription>
                        </Alert>
                        <MakeEnquiry updateStocks={updateStocks} handleResetRange={handleResetRange}/>
                    </Box>
                ) : (
                    <>
                       <InfiniteScroll
                            dataLength={product.length}
                            next={handloadMore}
                            hasMore={true}
                            loader={<h4></h4>}
                        > <ProductCard product={product} handleCompare={handleCompare} />
                        </InfiniteScroll>
                       {/* <Box display={"block"} w="full">
                            <Button onClick={handloadMore} my={4} alignContent="center" alignItems={"center"}> Load more Results</Button>
                        </Box> */}
                        
                    </>
                ))
                }
            </Box>
           
        </>

    );
}
