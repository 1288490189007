import {
    Checkbox, FormControl, FormLabel, VStack,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Box, Flex, Spacer
} from '@chakra-ui/react';
import React, { ChangeEvent, FunctionComponent, useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updatePriceRange } from 'src/redux/reducers/filterReducer';
import { BiChevronLeftCircle } from "react-icons/bi";
import { BiChevronRightCircle } from "react-icons/bi";
import { updateMaxKm, updateMinKm ,updatePage,updatePreviousData} from 'src/redux/reducers/filterBuyReducer';

const styles = {
    formControl: {
        mb: 5
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        maxHeight: 200,
        overflowY: "scroll",
        mb: 4
    }
}
interface IFKMRange {
    updateStocks?:(type:any,value:any)=>void
}

const FKMRange = ({ updateStocks}: IFKMRange) => {
    const dispatch = useAppDispatch()
    const priceRange = useAppSelector((state: any) => state.commonData.priceRange)
    const FPriceRange = useAppSelector((state: any) => state.filterData.priceRange)
    const kmList = useAppSelector((state: any) => state.kmList.data)

    const [minKm, setMinKm] = useState(kmList?.[0]);
    const [maxKm, setMaxKm] = useState(kmList?.[kmList.length - 1]);

    const handleChangePrice = (val: any) => {
        setMinKm(val[0]);
        setMaxKm(val[1]);
        // dispatch(updateMinKm(val[0]));
        // dispatch(updateMaxKm(val[1]));
        // dispatch(updatePage(1));
        updateStocks  && updateStocks("kmRange",val);
        // dispatch(updatePreviousData([]));
    }

    useEffect(() => {
        setMinKm(kmList?.[0]);
        setMaxKm(kmList?.[kmList.length - 1]);
    }, [kmList])


    // const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { value, checked }: any = e.target
    //     let setArr

    //     if (checked && !FPriceRange.includes(value)) {
    //         setArr = [...FPriceRange, value]
    //     } else {
    //         setArr = FPriceRange.filter((ele: any) => ele !== value)
    //     }
    //     return dispatch(updatePriceRange(setArr))
    // }

    return (
        <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.legend}>Kilometers run</FormLabel>
            {/* <VStack sx={styles.vstack}>
                {
                    priceRange.map((item: any, index: number) => (
                        <Checkbox
                            key={index}
                            sx={styles.checkbox}
                            value={`${item.value.min}${item.value.max !== null ? '_' + item.value.max : ''}`}
                            defaultChecked={FPriceRange.includes(item) ? true : false}
                            onChange={(e) => handleSubmit(e)}
                        >{item.name}</Checkbox>
                    ))
                }
            </VStack> */}
            <Box px={6}>
                <RangeSlider aria-label={['min', 'max']} defaultValue={[0, 500000]} min={0} max={500000} step={10000} onChangeEnd={(val) => handleChangePrice(val)}>
                    <RangeSliderTrack bg='lightgray'>
                        <RangeSliderFilledTrack bg='gray5' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={10} index={0}>
                        <Box color='gray5' as={BiChevronLeftCircle} boxSize={10} />
                    </RangeSliderThumb>
                    <RangeSliderThumb boxSize={10} index={1}>
                        <Box color='gray5' as={BiChevronRightCircle} boxSize={10} />
                    </RangeSliderThumb>
                </RangeSlider>
            </Box>
            <Flex>
                <Box px='4'>
                    {minKm}
                </Box>
                <Spacer />
                <Box px='4'>
                    {maxKm}
                </Box>
            </Flex>
        </FormControl>
    );
}

export default FKMRange;