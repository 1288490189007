import React from 'react';
import { Box, Text } from '@chakra-ui/react';

export const About = () => {
    return (
        <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb="60px" pt={"40px"}>
            <Text as="h1" fontSize={"3rem"} fontWeight={"bold"} mb={"30px"} textAlign={"center"}>
                About Us
            </Text>
            <Text as="h2" fontSize={"2rem"} fontWeight={"bold"} mb={"20px"}>
                Audi - the German luxury car manufacturer
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                As a manufacturer of high-quality and innovative luxury cars, Audi is one of the world's leading premium brands. The basis of its success comprises pioneering concepts in the domains of advanced technology and design. Audi is represented in 110 countries worldwide and since 2004, Audi has been selling its products in the Indian market.
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            In March 2007, Audi set up its own sales company for India. By establishing Audi India as a Division of Volkswagen Group Sales India Pvt. Ltd. in Mumbai, Audi is making a clear long-term statement in the country with ambitious growth plans. Audi's goal is to become the leading automobile luxury brand in the Indian market in the next few years.
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            The Audi India strategy encompasses significant investments in branding, marketing, exclusive dealerships and after sales service for the upcoming years.
            </Text>
        </Box>
    )
}
