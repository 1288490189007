import React, { } from 'react';
import {
    Box,
    Text,
    Flex,
    Grid,
    GridItem,
    Button,
} from "@chakra-ui/react";
import { useAppSelector } from 'src/redux/hooks';
interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    setYear: (values: any) => void;

}

export default function SliderYear({ setYear, sliderStep, nextSliderStep }: IData) {
    const yearList = useAppSelector((state: any) => state.yearList.data);

    const handleSubmit = (values: any) => {
        setYear(values)
        nextSliderStep()
    }

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT MANUFACTURED YEAR
            </Text>
            <Box overflowY={"scroll"}>
                <Grid templateColumns="repeat(4, 1fr)" gap="34px">
                    {
                        yearList.map((item: string, i: number) => (
                            <GridItem key={i}>
                                <Button
                                    variant="secondary"
                                    size={'lg'}
                                    onClick={() => handleSubmit(item)}
                                >
                                    {item}
                                </Button>
                            </GridItem>
                        ))
                    }

                </Grid>
            </Box>
        </Flex>
    );
}