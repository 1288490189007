
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
const initialState = {
    leadIds:[],
    compareList:[]
    };
    
    const compareListSlice = createSlice({
      name: "compareList",
      initialState: initialState,
      reducers: {
        updateCompareList: (state, action) => {
          state.compareList = action.payload;
     
      },
      updateLeadIds: (state, action) => {
        state.leadIds = action.payload;
    },

      },
      extraReducers: {},
    });
    
export const { updateCompareList, updateLeadIds } = compareListSlice.actions;   
 export default compareListSlice.reducer;