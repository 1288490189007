import React from 'react';
import { useAppSelector } from 'src/redux/hooks';
import {
    Box,
    Text,
    Grid,
} from '@chakra-ui/react'
import VehicleCard from '../VehicleCard'
import VehicleCardLoader from '../VehicleCardLoader';
import VehicleCardEmpty from '../VehicleCardEmpty';

const DealerStocks = () => {
    const loader = useAppSelector((state: any) => state.dealer.stocks.status)
    const stocks = useAppSelector((state: any) => state.dealer.stocks.data)

    const content = () => {
        if (loader === "success") {
            if (stocks.length === 0) {
                return <VehicleCardEmpty />
            } else {
                return stocks.map((item: any) => (
                    <VehicleCard key={item.lead_id} lead_id={item.lead_id} make={item.make} model={item.model} variant={item.variant} selling_price={item.selling_price} horse_power={item.horse_power} year_of_manufacture={item.year_of_manufacture} image_url={item.image_url} dealer={item.dealer_name} odoReading={item.odo_reading} city={item.city} dealerId={item.dealer_id}  certification={item.certification}/>
                ))
            }
        }
        else if (loader === "loading") {
            return (<>
                <VehicleCardLoader />
                <VehicleCardLoader />
                <VehicleCardLoader />
                <VehicleCardLoader />
            </>)
        } else {
            return <Text>Something went wrong !!</Text>
        }
    }

    return (
        <Box as="section">
            <Text as='h2' fontSize="20px" fontWeight={'semibold'} mb="20px" color='textClr2'>Available Tractors</Text>
            <Grid
                gap={{ base: '20px', xl: '30px' }}
                templateColumns={{ base: 'repeat(1, 1fr)', xl: 'repeat(4, 1fr)' }}
            >
                {content()}
            </Grid>

        </Box>
    )
}
export default DealerStocks