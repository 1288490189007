import { Box, Checkbox, FormControl, FormLabel, Text, VStack, Select } from '@chakra-ui/react';
import { ChangeEvent, FunctionComponent,useState ,useEffect} from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updateModalValue } from 'src/redux/reducers/commonReducer';
import { updateModel,updatePage,updatePreviousData} from 'src/redux/reducers/filterBuyReducer';

const styles = {
    formControl: {
        mb: 5
    },
    scroller: {
        maxHeight: 200,
        overflowY: "scroll",
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        mb: 4
    }
}

interface IFModel {
    updateStocks?:(type:any,value:any)=>void,
    model:any
}
const FModel = ({ updateStocks}: IFModel) => {
    const dispatch = useAppDispatch()
    const loading = useAppSelector((state: any) => state.modelList.status)
    const modelList = useAppSelector((state: any) => state.modelList.data);
    const [model, setModel] = useState<string>("");
    const modelValue = useAppSelector((state: any) => state.commonData.modelValue);

    useEffect(()=>{
        setModel(modelValue)
         },[modelValue])
    
   // const FModels = useAppSelector((state: any) => state.filterData.models)

    // const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { value, checked }: any = e.target
    //     let setArr

    //     if (checked && !FModels.includes(value)) {
    //         setArr = [...FModels, value]
    //     } else {
    //         setArr = FModels.filter((ele: any) => ele !== value);
    //     }
    //     return dispatch(updateModels(setArr))
    // }


    const handleSubmit=(e:any)=>{
    // dispatch(updateModel(e.target.value));
    // dispatch(updatePage(1));
    // dispatch(updatePreviousData([]));
    setModel(e.target.value);
    updateStocks &&  updateStocks("model",e.target.value);
    dispatch(updateModalValue(e.target.value));
    }
    return (
        <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.legend}>Model</FormLabel>
            {/* <Box sx={styles.scroller}>
                {(loading === 'loading') ? <Box>Loading...</Box> : null}
                {(modelList.length === 0) ? <Box>Kindly select any make</Box> :
                    modelList.map((item: any, i: number) => (
                        <Box key={i}>
                            <Text fontWeight={"semibold"}>{item.make}</Text>
                            <VStack sx={styles.vstack}>
                                {
                                    item.models.map((model: string, subindex: number) => (
                                        <Checkbox
                                            key={subindex}
                                            value={model}
                                            alignItems="center"
                                            sx={styles.checkbox}

                                            onChange={(e) => handleSubmit(e)}
                                        >
                                            {model}
                                        </Checkbox>
                                    ))
                                }
                            </VStack>
                        </Box>
                    ))}
            </Box> */}
            <Select placeholder='Select Model' onChange={handleSubmit} variant='mySelect'>
                {
                    (modelList.length !== 0) ? modelList.map((item: any, index: number) => (<option key={index} value={item}
                        selected={item==model}
                    >{item}</option>)) : (<option>Loading</option>)
                }
            </Select>
        </FormControl>
    );
}

export default FModel;