import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks'
import {
    Tabs,
    TabPanels,
    TabPanel,
    Box,
    Text,
    Grid,
    GridItem,
    FormControl,
    Select,
    Button,
    Divider
} from "@chakra-ui/react";
import { useAppDispatch } from 'src/redux/hooks';
import { DealerList } from './DealerList';
import '../../theme/slider.css';
import dealerRequest from 'src/api/dealerRequest';
import Cookies from 'js-cookie';
import getDealerDetails from 'src/api/getDealerDetails';

export default function SearchDealer({ handleSubmit }: any) {
    const dispatch = useAppDispatch();
    const [cities, setCities] = useState<object[] | []>([])
    const stateListStatus = useAppSelector((state: any) => state.stateList.status)
    const stateList = useAppSelector((state: any) => state.stateList.data);
    const [showline, setShowLine] = useState(false);
    const [dealers, setDealers] = useState<[]>([])
    const [status, setStatus] = useState<string>("");
    const [selectedState, setSelectedState] = useState<any>("");
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    const cityList = useAppSelector((state: any) => state.cities.data)
    const dealerId = Number(Cookies.get('dealer'));

   
    useEffect(() => {
            const dealerCityId = Number(Cookies.get('cityId'));
            const cityDetails = cityList.find((item: any) => item.city_id === dealerCityId);
            const selected = cityDetails && cityDetails.state_id;
            if (selected && selected !== selectedState) {
                setSelectedState(selected);
                fetchState(selected);
            }
        
    }, [selectedState]);

    const fetchState = (value: any) => {
        const res = dealerRequest(value);
        res.then((data: any) => {
            setDealers(data.data);
            setStatus(data.status);
        })
        setShowLine(true);
    }

    const fetchCities = (e: any) => {
        const stateId = Number(e.target.value);
        const res = dealerRequest(stateId);
        res.then((data: any) => {
            setDealers(data.data);
            setStatus(data.status);
        })
        setShowLine(true);
    }

    return (
            <Box mb="60px">
                <Box maxWidth={400}>
                    <form >
                        <FormControl>
                            <Select placeholder="Find Dealers by Location" onChange={fetchCities} name="state" variant={"mySelect"} >
                                {(stateListStatus === "success" && stateList.length !== 0) ?
                                    (Object.entries(stateList).map((t: any, k: any) =>
                                        <option key={k} value={t[0]} selected={t[0] == selectedState}>{t[1]}</option>))
                                    : (<option>Loading</option>)}
                            </Select>
                        </FormControl>
                    </form>
                </Box>
                {showline && <Divider mt={10} />}
                <DealerList dealers={dealers} status={status} />
            </Box>
    );
}
