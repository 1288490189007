import oemApi from "./oemApi";
import { DEALER_DETAIL } from "src/constants"
import ibbApi from "./ibbApi";

const getDealerDetails = (dealerID: any) => {
    const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
    let headers: any = {};
    if (LSToken) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LSToken.token}`
        }
    }

    const res= ibbApi
        .get(
            `${DEALER_DETAIL}/${dealerID}`,
            {
                headers: headers
            }
        )
        .then((response) => 
        {
            return response.data}
            )
        .catch((error) => error)
        return res
};
export default getDealerDetails