import React, { FunctionComponent } from 'react'
import { Button, Box } from '@chakra-ui/react';

interface ButtonGroupProps {
    active: string
    data: string[]
    callback: (value: string) => void
}

const styles = {
    wrapper: {
        display: { base: 'flex', xl: 'inline-flex' },
        borderRadius: 'base',
        backgroundColor: '#f7f7f7',
        padding: '7px 9px',
        marginBottom: '20px'
    },
    btn: {
        color: 'rgba(1,36,19,0.6)',
        borderRadius: '10px',
        padding: '8px 30px',
        height: '36px',
        fontWeight: 'medium',
        fontSize: '14px',
        _hover: {
            color: 'primary',
        },
        '&.active': {
            color: 'primary',
            backgroundColor: 'white',
            boxShadow: '0 3px 6px 0 rgb(0 0 0 / 16%)',
        },
    }
}

const ButtonGroup: FunctionComponent<ButtonGroupProps> = ({ active, data, callback }) => {
    return (
        <Box sx={styles.wrapper}>
            {
                data.map((item: string, i: number) => <Button variant={'blank'} sx={styles.btn} key={i} className={(item === active) ? 'active' : ''} onClick={() => callback(item)}>{item}</Button>)
            }
        </Box>
    );
}

export default ButtonGroup;