import React, { useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import AutoComplete from './AutoComplete';
import {
    Text,
    Button,
    useDisclosure,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    Grid,
    Spinner,
    Alert,
    AlertIcon,
    AlertTitle,
    Center
    // ModalCloseButton,
    // Input
} from '@chakra-ui/react'
import { ICityData } from 'src/commonTypes';
//import { openCityModal, updateCityId, fetchCityInfo } from 'src/redux/reducers/globalCityReducer';
import {openCityModal, updateCity, updateCityId, updateDealer}  from 'src/redux/reducers/cityListReducer';
import { openCustomerModal } from 'src/redux/reducers/CustomerFormReducer';
import { updateFCity } from 'src/redux/reducers/filterBuyReducer';
import Cookies from 'js-cookie';
import { fetchDealersByCity } from 'src/redux/reducers/dealerReducer';
import { useNavigate ,useLocation} from 'react-router-dom';
import { updateModalValue, updatePriceValue } from 'src/redux/reducers/commonReducer';

const styles = {
    modalContent: {
        p: ['20px', '60px'],
        borderRadius:"none"
    },
    modalHeader: {
        p: 0,
        color: 'textClr2',
        fontSize: 'xl',
        textTransform: 'uppercase',
        fontWeight: 'medium',
        textAlign: 'center',
        mb: 10
    },
    modalBody: {
        p: 0,
    },
    modalSubttl: {
        fontSize: 'md',
        color: 'textClr2',
        textTransform: 'uppercase',
        fontWeight: 'medium',
        mb: 4
    },
    modalBtn: {},
}

const CityModal = () => {
    const { isOpen, onOpen, onClose } = useDisclosure()
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    
    //const openModal = useAppSelector((state: any) => state.globalCity.openModal);
    const openModal = useAppSelector((state: any) => state.cities.openModal);
    const getCityId = useAppSelector((state: any) => state.globalCity.cityId);
    const cityLoader = useAppSelector((state: any) => state.cities.status);
    const popularCities = useAppSelector((state: any) => state.cities.popularCities)
    const cities = useAppSelector((state: any) => state.cities.data)
    const dataCities: object[] = []
    cities.filter((item: any) => dataCities.push({ id: item.city_id, name: item.city_name }));
    const cookieValue = Cookies.get('cust');

    useEffect(() => {
        if (openModal) {
            onOpen()
        } else {
            onClose()
        }
    }, [openModal, onOpen, onClose, cityLoader])

    // useEffect(() => {
    //     if (getCityId) {
    //         dispatch(fetchCityInfo(getCityId))
    //     }
    // }, [getCityId])//eslint-disable-line

    const handleSubmit = async(id: any, name:any)=> {
        dispatch(updateCityId(id));
        dispatch(updateCity(name));
        dispatch(openCityModal(false));
        await dispatch(fetchDealersByCity(id));  
        !cookieValue && dispatch(openCustomerModal(true));    
       // dispatch(updateFCity(id));  
        // dispatch(updateModalValue("")); 
        // dispatch(updatePriceValue("50000000")); 
        // window.scrollTo({ top: 0, behavior: "smooth" });
        if (location.pathname !== '/sell') {
            navigate('/buy');
          }  
    }

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={'xl'} autoFocus={false} closeOnEsc={false} closeOnOverlayClick={false}>
            <ModalOverlay
                // bg='blackAlpha.300'
                // backdropFilter='blur(10px)'
                 />
            <ModalContent sx={styles.modalContent}>
                <ModalHeader sx={styles.modalHeader}>Select your city</ModalHeader>
                <ModalBody sx={styles.modalBody}>
                    {
                        (cityLoader === "success") ?
                            (
                                <>
                                    <AutoComplete data={dataCities} cb={handleSubmit} />

                                    <Text sx={styles.modalSubttl}>Popular Cities</Text>
                                    <Grid gap={4} templateColumns={['1fr','1fr','repeat(2, 1fr)','repeat(3, 1fr)']}>
                                        {
                                            popularCities.map((item: ICityData, i: number) => (
                                                <Button
                                                    key={i}
                                                    sx={styles.modalBtn}
                                                    onClick={() => handleSubmit(item.city_id,item.city_name)}
                                                >{item.city_name}</Button>
                                            ))
                                        }
                                    </Grid>
                                </>
                            ) : ((cityLoader === "error") ?
                                <Alert status='error'>
                                    <AlertIcon />
                                    <AlertTitle>Your browser is outdated!</AlertTitle>
                                </Alert>
                                :
                                <Center><Spinner /></Center>)
                    }
                </ModalBody>
            </ModalContent>
        </Modal>
    )
}

export default CityModal