import { useEffect, useState, } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
  Box,
  Grid,
  Text, Link, Image, Skeleton, VStack, Spinner, Heading, Button, HStack, Stack, useDisclosure, Center
} from "@chakra-ui/react";
import { fetchProductDetails } from 'src/redux/reducers/productDetailsReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import ImageGallery from 'src/components/Product/ImageGallery';
import { currencyFormatter } from 'src/lib/currencyFormatter';
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { MakeEnquiryModal } from 'src/components/Product/makeEnquiryModal';
import { IMakeEnquiryPayload } from 'src/commonTypes';
import { setConstantValue } from 'typescript';


const styles = {
  section: {
    mt: { base: 15, xl: 30 },
  },

  subTitle: {
    fontSize: { base: 'xl', xl: '20px' },
    fontWeight: 'extendedBold',
  },
  text: {
    fontSize: { base: 'tiny', xl: 'base' },
    mb: { base: 6, xl: 8 },
  },

}
export const ProductDetails = () => {
  const { id } = useParams<{ id?: string }>();
  const dispatch = useAppDispatch()
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [leadId, setLeadId] = useState<any>();
  const images = useAppSelector((state: any) => state.productDetails.data.Images);
  const vechicleDetails = useAppSelector((state: any) => state.productDetails.data.vechicleDetails);
  const stockDetails = useAppSelector((state: any) => state.productDetails.data.stockDetails);
  const dealerDetails = useAppSelector((state: any) => state.productDetails.data.dealer);
  const status = useAppSelector((state: any) => state.productDetails.status);
  const [payload, setPayload] = useState<object>(
    {
      make: vechicleDetails?.make,
      city: vechicleDetails?.city,
      variant: vechicleDetails?.variant,
      model: vechicleDetails?.model,
      leadId: vechicleDetails?.lead_id,
      dealerId: dealerDetails?.id,
    });

  const name = vechicleDetails && (vechicleDetails.make + ' ' + vechicleDetails.model);
  const subTitle = vechicleDetails && (vechicleDetails.year_of_manufacture + ' ' + vechicleDetails.variant + ' ' + vechicleDetails.fuel_type);

  useEffect(() => {

    const decodedData = atob(id!);
    let lead = Number(decodedData);
    setLeadId(lead);
    dispatch(fetchProductDetails(lead));
  }, []);

  useEffect(() => {
    setPayload({
      make: vechicleDetails?.make,
      city: vechicleDetails?.city,
      variant: vechicleDetails?.variant,
      model: vechicleDetails?.model,
      leadId: vechicleDetails?.lead_id,
      dealerId: dealerDetails?.id,
    });
  }, [vechicleDetails, dealerDetails])

  const convertDate = (value: string) => {
    if (value) {
      const date = new Date(value);
      const monthYear = date.toLocaleString('default', { month: 'long', year: 'numeric' }).replace(/(\d{4}).(\d{2})/, '$2-$1');
      return monthYear;
    }
    else {
      return "Unregistered";
    }
  }

  return (
    <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb={{ base: 10, xl: 25 }}>
      <Box my={4}>
        <Link
          as={RouterLink}
          to={`/buy`}>
          <ChevronLeftIcon mx={2} boxSize={6} />
          Back To Search Page
        </Link>
      </Box>
      {(status === "success") ?
        <>

          <ImageGallery data={images} leadId={leadId} video={stockDetails.video} />
          <Box my={10}>
            <Text as='h5' color="gray" sx={styles.subTitle}
              textTransform="capitalize"
              title={name}
            >
              {name}
            </Text>
            <Text as='h5' color="RGBA(0, 0, 0, 0.48)"
              textTransform="capitalize"
              title={subTitle}
            >
              {subTitle}
            </Text>
            <Text
              fontSize="15px"
              fontWeight={'bold'}
              color="secondary"
              mb={'10px'}
            >{stockDetails && currencyFormatter(stockDetails.selling_price)}</Text>
            <Button variant='primary' maxW={"24rem"} onClick={onOpen} textTransform={"none"}>
              I am interested
            </Button>
          </Box>
          <Heading color="gray" mb={4} fontSize={"30px"}>Overview</Heading>
          <Box bg={"lightgray"} p={{ base: 5, xl: 10 }}>
            <Grid
              columnGap={{ base: "15px", xl: "20px" }}
              rowGap={{ base: "15px", xl: "40px" }}
              templateColumns={{ base: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }}
            >
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/calendar.svg`} alt="model" boxSize={10} />
                <Stack spacing={0}>
                  <Box as="p" color="gray" >
                    Model
                  </Box>
                  <Box as="p" color="gray" fontWeight="bold" >
                    {vechicleDetails && vechicleDetails.model}
                  </Box>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/mileage.svg`} alt="mileage" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    KM's Driven
                  </Text>
                  <Text color="gray" fontWeight="bold" >
                    {vechicleDetails && vechicleDetails.odo_reading}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "10px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/clock.svg`} alt="clock" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Manufacturing Month/Year
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.month_of_manufacture}  ${vechicleDetails.year_of_manufacture}`}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/calendar.svg`} alt="calendar" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Registration Month/Year
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && convertDate(vechicleDetails.date_of_first_registration)}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/car.svg`} alt="calendar" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Variant
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.variant}`}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/user.svg`} alt="user" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Ownership Status
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.no_of_veh_owner}`}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/fuel.svg`} alt="fuel" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Fuel
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.fuel_type}`}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/document.svg`} alt="document" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Certification Status
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {stockDetails && `${stockDetails.certification}`}
                  </Text>
                </Stack>
              </HStack>
              {vechicleDetails?.transmission_type &&
                <HStack spacing={{ base: "20px", xl: "24px" }}>
                  <Image src={`/images/${process.env.REACT_APP_THEME}/icons/transmission.svg`} alt="transmission" boxSize={10} />
                  <Stack spacing={0}>
                    <Text color="gray" >
                      Transmission
                    </Text>
                    <Text color="gray" mt={0} fontWeight="bold" >
                      {`${vechicleDetails.transmission_type}`}
                    </Text>
                  </Stack>
                </HStack>
              }
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/color.svg`} alt="color" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    Exterior colour
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.colour}`}
                  </Text>
                </Stack>
              </HStack>
              <HStack spacing={{ base: "20px", xl: "24px" }}>
                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/loc-pointer.svg`} alt="location" boxSize={10} />
                <Stack spacing={0}>
                  <Text color="gray" >
                    City
                  </Text>
                  <Text color="gray" mt={0} fontWeight="bold" >
                    {vechicleDetails && `${vechicleDetails.city_name}`}
                  </Text>
                </Stack>
              </HStack>
            </Grid>
          </Box>
          <Box my={{ base: 5, xl: 15 }}>
            <Heading color="gray" mb={4} fontSize={"30px"}>Dealer Information</Heading>
            <Box bg={'white'} boxShadow={'2px 2px 2px 2px rgb(0 0 0 / 2%)'} maxW={{ xl: "33%" }} p={{ base: 5, xl: 10 }}>
              {dealerDetails &&
                <>
                  <Text as="h1" fontSize={"1.8em"} fontWeight={"bold"} mb={"10px"} >
                    {dealerDetails.name}
                  </Text>
                  <Text as="p" mb={"10px"} fontSize={"1rem"} color='gray 500' >
                    {dealerDetails.address}
                  </Text>
                  <Link href={`mailto:${dealerDetails.email}`}>
                    <HStack mb={"10px"} spacing='8px'>
                      <HiOutlineMail size={20} />
                      <Text as="p" mb={"10px"} fontSize={"1rem"}>
                        {dealerDetails.email}
                      </Text>
                    </HStack>
                  </Link>
                  <Link href={`tel:${dealerDetails.mobile}`}>
                    <HStack mb={"10px"} spacing='8px'>
                      <FaPhoneAlt size={20} />
                      <Text as="p" mb={"10px"} fontSize={"1rem"}>
                        {dealerDetails.mobile}
                      </Text>
                    </HStack>
                  </Link>
                  <Button variant='primary' onClick={onOpen}> I am interested</Button>
                </>
              }
            </Box>
          </Box>

        </>
        :
        <Center>
          <Spinner
            thickness='4px'
            emptyColor='gray.200'
            color='blue.500'
            size='xl'
          />
        </Center>
      }
      <MakeEnquiryModal open={isOpen} close={onClose} payload={payload} />
    </Box>
  )
}
