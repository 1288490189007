import ibbApi from "./ibbApi";
const FetchCitiesByState = (token: string, state: string): any => {
  const payload = {
    for: "cities",
    state: state,
    access_token: token,
  };
  const data = ibbApi
    .post("/get-dealers-cities", payload)
    .then((res) => res.data.data)
    .catch((err) => err);
  return data;
};
export default FetchCitiesByState;
