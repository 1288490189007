import React from 'react'
import {
    Stack,
    Skeleton,
    Box,
    HStack,
    Center
} from '@chakra-ui/react'

const VehicleCardLoader = () => {
    return (<Stack bg={"white"} borderRadius="10px">
        <Skeleton height='200px' borderRadius="10px 10px 0 0" />
        <Box p="14px 20px">
            <Skeleton height='20px' w={'200px'} mb="10px" />
            <Skeleton height='28px' w={'66px'} mb="10px" />
            <HStack mb="14px">
                <Skeleton height='32px' w={'74px'} />
                <Skeleton height='32px' w={'74px'} />
            </HStack>
            <Center>
                <Skeleton height='32px' w={'180px'} />
            </Center>
        </Box>
    </Stack>)
}

export default VehicleCardLoader