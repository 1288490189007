import ibbApi from 'src/api/ibbApi';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { GET_CONSTANT } from "src/constants";

const params = {
    "constants": ["filter_fuel_type", "filter_transmission_type", "filter_body_type"],
    "platform": process.env.REACT_APP_PLATFORM_ID
}
export const fetchConstants = createAsyncThunk(
    "fetchConstant",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${GET_CONSTANT}`,
                params,
                {
                    headers: headers
                }
            )
            .then((response) => {
                return response.data.data
            })
            .catch((error) => error)
        return res
    }
);

const initialState = {
    status: "idle",
    data: {
        bodyType: [],
        fuelType: [],
        transmissionType: []
    },
    error: {},
};

const constantSlice = createSlice({
    name: "constantList",
    initialState: initialState,
    reducers: {},
    extraReducers: {
        [fetchConstants.pending.type]: (state, action) => {
            state.status = "loading"
            state.data.bodyType = []
            state.data.fuelType=[]
            state.data.transmissionType=[]
            state.error = {}
        },
        [fetchConstants.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            //state.data = action.payload
            state.data.bodyType = action.payload.filter_body_type;
            state.data.fuelType = action.payload.filter_fuel_type
            state.data.transmissionType = action.payload.filter_transmission_type
            state.error = {}
        },
        [fetchConstants.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data.bodyType = []
            state.data.fuelType=[]
            state.data.transmissionType=[]
            state.error = action.payload
        },
    },
});

export default constantSlice.reducer;