import React, { useEffect,useState } from 'react';
import 'react-18-image-lightbox/style.css'; 
import Lightbox from 'react-18-image-lightbox';

export const LightBox = (props:any) => {
    const [autoSlideIndex, setAutoSlideIndex] = useState<number>(props.photoIndex);
    useEffect(() => {
        const timer = setTimeout(() => {
          const nextIndex = (autoSlideIndex + 1) % props.lightboxImages.length;
          setAutoSlideIndex(nextIndex);
        }, 3000);
    
        return () => {
          clearTimeout(timer);
        };
      }, [autoSlideIndex, props.lightboxImages.length]);

      useEffect(() => {
        setAutoSlideIndex(props.photoIndex);
      }, [props.photoIndex]);

    return (
        <Lightbox
        mainSrc={props.lightboxImages[autoSlideIndex]}
        nextSrc={props.lightboxImages[(autoSlideIndex + 1) % props.lightboxImages.length]}
        prevSrc={props.lightboxImages[(autoSlideIndex + props.lightboxImages.length - 1) % props.lightboxImages.length]}
        onCloseRequest={() => props.setIsOpen(false)}
        onMovePrevRequest={() =>
          setAutoSlideIndex((autoSlideIndex + props.lightboxImages.length - 1) % props.lightboxImages.length)
        }
        onMoveNextRequest={() =>
          setAutoSlideIndex((autoSlideIndex + 1) % props.lightboxImages.length)
        }
      />

    )
}
