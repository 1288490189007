import { useEffect } from 'react';
import ReactPlayer from 'react-player';

export const VideoModal = ({ video, setIsOpen, isVideoLightbox }: any) => {
  useEffect(() => {
    const handleEscapeKey = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        setIsOpen(false);
      }
    };

    document.addEventListener('keydown', handleEscapeKey);

    return () => {
      document.removeEventListener('keydown', handleEscapeKey);
    };
  }, [setIsOpen]);

  const handleClose = () => {
    setIsOpen(false);
  };

  return (
    <>
      {isVideoLightbox && (
        <div
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            zIndex: 1000,
          }}
          onClick={handleClose}
        >
          <ReactPlayer
            url={video}
            width="80%"
            height="80%"
            playing={true}
            controls={false}
            onClick={(e: any) => e.stopPropagation()}
            volume={0} // Mute the player by setting volume to 0
            config={{
              file: {
                attributes: {
                  muted: true // Ensure the video element is muted
                }
              }
            }}
          />
          <button
            style={{
              position: 'absolute',
              top: '10px',
              right: '10px',
              background: 'none',
              border: 'none',
              color: 'white',
              fontSize: '1.5rem',
              cursor: 'pointer',
            }}
            onClick={handleClose}
          >
            X
          </button>
        </div>
      )}
    </>
  );
};
