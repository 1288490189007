import React, { FunctionComponent, useEffect } from 'react';
import { Box } from '@chakra-ui/react';
import { useLocation } from 'react-router-dom';
import Hero from 'src/components/Dealer/Hero';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchDealerDetails, fetchDealerStocks } from 'src/redux/reducers/dealerReducer'
import HeroLoader from 'src/components/Dealer/HeroLoader';
import DealerStocks from 'src/components/Dealer/DealerStocks';
const styles = {
    wrapper: {
        bg: "sectionGray",
        px: {
            base: "bleed",
            xl: "bleed-xl"
        },
        pb: "60px",
        pt: "40px"
    }
}
const DealerDetailsPage: FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const { pathname } = useLocation()
    const dealerId = Number(pathname.substring(pathname.lastIndexOf('/') + 1, pathname.length))
    const loader = useAppSelector((state: any) => state.dealer.current.status)

    useEffect(() => {
        dispatch(fetchDealerDetails(dealerId))
        dispatch(fetchDealerStocks(dealerId))
    }, [dealerId, dispatch])

    if (loader === "loading") {
        return (<Box as="main" sx={styles.wrapper}><HeroLoader /></Box>)
    }

    return (
        <Box as="main" sx={styles.wrapper}>
            <Hero />
            <DealerStocks />
        </Box>
    );
}

export default DealerDetailsPage;