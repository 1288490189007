import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Text, Flex, Select, Button, Image, Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';
import { useNavigate } from 'react-router-dom';
import { updateModalValue, updatePriceValue } from 'src/redux/reducers/commonReducer';


export const SearchBuyBar = () => {
    const navigate = useNavigate();
    const modelList = useAppSelector((state: any) => state.modelList.data);
    //const priceList = useAppSelector((state: any) => state.priceList.data);
    const [model, setModel] = useState<string>("");
    const [price, setPrice] = useState<string>("");
    const token = localStorage.getItem("token")!;
    const dispatch = useAppDispatch();
  

    const rangeOptions = [
        { label: "10 Lakh", value: "1000000" },
        { label: "20 Lakh", value: "2000000" },
        { label: "30 Lakh", value: "3000000" },
        { label: "50 Lakh", value: "5000000" },
        { label: "70 Lakh", value: "7000000" },
        { label: "1 Cr", value: "10000000" },
        { label: "2 Cr", value: "20000000" },
        { label: "3 Cr", value: "30000000" },
        { label: "4 Cr", value: "40000000" },
        { label: "5 Cr", value: "50000000" },
    ];

    useEffect(() => {
        token && dispatch(fetchModelList("Audi"));
    }, [token]);

    const handleSubmit = () => {
        navigate('/buy', { state: { model, price } });
    }
    const handleUpdateModal = (e: ChangeEvent<HTMLSelectElement>) => {
        setModel(e.target.value);
        dispatch(updateModalValue(e.target.value));    
    }

    const handleUpdatePrice = (e: ChangeEvent<HTMLSelectElement>) => {
        setPrice(e.target.value);
        dispatch(updatePriceValue(e.target.value));    
    }

    return (
        <>
            <Flex flexWrap={{ base: "wrap", xl: "nowrap" }} justifyContent={"center"}>
                <Box w={{ base: "100%", md: "auto" }} mb={{ base: "4", md: "0" }}>
                    <Select placeholder="Model" variant="mySearch" onChange={handleUpdateModal}>
                        {
                            (modelList.length !== 0) ? modelList.map((item: any, index: number) => (<option key={index} value={item}>{item}</option>)) : (<option>Loading</option>)
                        }
                    </Select>
                </Box>
                <Text as={'h3'}
                    fontSize={{ base: "15px", xl: "20px" }}
                    fontWeight={'bold'}
                    letterSpacing="wide"
                    color="secondary"
                    whiteSpace="nowrap"
                >
                    in the range
                </Text>
                <Box w={{ base: "100%", md: "auto" }} mb={{ base: "4", md: "0" }}>
                    <Select placeholder="Price" variant="mySearch" onChange={handleUpdatePrice} >

                        {
                            (rangeOptions.length !== 0) ? rangeOptions.map((item: any, index: number) => (<option key={index} value={item.value}>{item.label}</option>)) : (<option>Loading</option>)
                        }
                    </Select>
                </Box>
                <Button colorScheme="blue" variant="blank" onClick={handleSubmit}><Image src={`/images/${process.env.REACT_APP_THEME}/search-app-submit.png`} alt="waranty"></Image></Button>
            </Flex>

        </>
    )
}
