import React from "react";
import { Box ,useMultiStyleConfig,Image, Heading,Button} from "@chakra-ui/react";
import SwiperCore, { Autoplay, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";
import { useAppSelector } from 'src/redux/hooks';
import HTMLReactParser from 'html-react-parser';
import { Link as RouterLink } from 'react-router-dom';

SwiperCore.use([Autoplay, Pagination]);

type Props = {
  images: string[];
};

const CarouselSlider = ({ images }: Props) => {
    const styles = useMultiStyleConfig(`HomeBanner`);
    const containerStyle = useMultiStyleConfig(`Container`)
    const data = useAppSelector((state: any) => state.homePage.banner)
  return (
    <Box width="100%">
      <Swiper
        autoplay={{ delay: 3000 }}
        pagination={{ clickable: true }}
        loop={true}
      >
        {images.map((image, index) => (
          <SwiperSlide key={index}>
            <Image src={image} alt={`slide-${index}`} />
          </SwiperSlide>
        ))}
      </Swiper>
      <Box sx={{ ...styles.caption, ...containerStyle.wrapper }} display={{base:"none"}}>
                {data.title ? <Heading as='h1' sx={{ ...styles.title }}>{HTMLReactParser(data.title)}</Heading> : null}
                {data.link ? <Button as={RouterLink} to={data.link.href} sx={{ ...styles.link }}>{data.link.text}</Button> : null}
            </Box>
      
    </Box>
  );
};

export default CarouselSlider;