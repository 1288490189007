import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { GET_PRICE } from 'src/constants'

const params = {
    "tag": "Buy",
    "platform": process.env.REACT_APP_PLATFORM_ID
}

export const fetchPiceList = createAsyncThunk(
    "price/fetchPrices",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .get(
                `${GET_PRICE}/Buy`,
               // params,
                {
                    headers: headers
                }
        )
            .then((response) =>{ 
        return response.data.data} )
            .catch((error) => error)
        return res
    }
);

const priceInitialState = {
    status: "idle",
    data: [],
    error: {},
};

const priceListSlice = createSlice({
    name: "priceList",
    initialState: priceInitialState,
    reducers: {},
    extraReducers: {
        [fetchPiceList.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchPiceList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchPiceList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export default priceListSlice.reducer;