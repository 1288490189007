import React, { FormEvent, ChangeEvent, FunctionComponent, useState } from 'react'
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    // DrawerCloseButton,
    useDisclosure,
    Text,
    FormControl,
    Input,
    Select,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    Center,
    useToast,
    HStack,
    VStack,
    PinInput,
    PinInputField,
    Icon,
    IconButton,
    Flex,
    Grid
} from '@chakra-ui/react'
import { FiArrowLeft } from 'react-icons/fi'
import FetchSendSMS from 'src/api/sendSMS'
import UserLoginCheck from 'src/api/user_logincheck'
import { useAppSelector } from 'src/redux/hooks'
import FetchVerifySMS from 'src/api/verifySMS'
import registerUser from 'src/api/registerUser'
const styles = {
    title: {
        color: '#343a40',
        fontSize: '20px',
        fontWeight: 'semibold',
        textTransform: 'uppercase',
        mx: "auto"
    },
    formControl: {
        mb: '40px'
    },
    hgroup: {
        mb: '40px',
        justifyContent: "center",
        alignItems: "center"
    },
    hgroupIcon: {
        justifySelf: 'flex-start',
    },
    linkText: {
        color: 'textClr2',
        fontSize: "16px",
        fontWeight: 'medium',
        textAlign: 'center'
    },
    link: {
        textDecoration: 'underline',
        _hover: {
            color: 'primary'
        }
    },
    formGrid: {
        gridTemplateColumns: '1fr 1fr',
        gap: 10
    },
    mobileGrid: {
        gridTemplateColumns: '1fr .3fr',
        gap: 10
    },
    regText: {
        p: "0.4rem 0.8rem",
        fontSize: "12px",
        fontWeight: 'medium'
    },
    regLink: {
        display: 'block',
        textDecoration: 'underline',
        cursor: 'pointer',
        _hover: {
            color: 'primary',
        }
    },
}
interface RegisterModalProps {
    user: string,
    from?: string
}
const RegisterModal: FunctionComponent<RegisterModalProps> = ({ user, from }) => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const stateList = useAppSelector((state: any) => state.stateList.data)
    const cityList = useAppSelector((state: any) => state.cities.data)
    const [cities, setCities] = useState<object[]>([])
    const [name, setName] = useState<string>("")
    const [invalidName, setInvalidName] = useState<boolean>(false)
    const [mobileNo, setMobileNo] = useState<string>("")
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false)
    const [state, setState] = useState<number>()
    const [invalidState, setInvalidState] = useState<boolean>(false)
    const [city, setCity] = useState<number>()
    const [invalidCity, setInvalidCity] = useState<boolean>(false)
    const [OTPBtnLoading, setOTPBtnLoading] = useState<boolean>(false)
    const [showOTP, setShowOTP] = useState<boolean>(false)
    const [verifyOTP, setVerifyOTP] = useState<boolean>(false)
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false)
    const [OTP, setOTP] = useState<string>("")


    const updateCities = (e: ChangeEvent<HTMLSelectElement>) => {
        const stateId = Number(e.target.value)
        const filterCities = cityList.filter((item: any) => item.state_id === stateId)
        setCities(filterCities)
        setState(stateId)
    }

    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setMobileNo(e.target.value)
        }
    }
    const sendSMS = () => {
        const res = FetchSendSMS(mobileNo)
        res.then((data: any) => {
            setOTP(data.otp)
            toast({
                title: `Send OTP to this ${mobileNo} mobile number. ${data.otp}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        })
    }
    const sendOTP = () => {
        if (mobileNo.length > 10 || mobileNo.length < 10) {
            setInvalidMobile(true)
        } else {
            setInvalidMobile(false)
            setOTPBtnLoading(true)
            setShowOTP(true)
            const fetchLoginCheck = UserLoginCheck(mobileNo, user)
            fetchLoginCheck.then((data: any) => {
                setOTPBtnLoading(false)
                if (data.status === "success") {
                    toast({
                        title: "You already registered with us",
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                } else {
                    sendSMS()
                }
            })
        }
    }
    const handleOTPVerification = () => {
        setOTPBtnLoading(true)
        const res = FetchVerifySMS(mobileNo, OTP)
        res.then((data: any) => {
            if (data.status === "success") {
                setOTPBtnLoading(false)
                setVerifyOTP(true)
                setShowOTP(false)
                toast({
                    title: "OTP verified successfully!",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
            } else {
                toast({
                    title: data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setOTPBtnLoading(false)
                setVerifyOTP(false)
                setShowOTP(true)
            }
        })
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitBtnLoading(true)
        if (name.length === 0) {
            setInvalidName(true)
        }
        if (state === undefined) {
            setInvalidState(true)
        }
        if (city === undefined) {
            setInvalidCity(true)
        }
        const params = {
            mobileNo: mobileNo,
            name: name,
            stateId: Number(state),
            cityId: Number(city),
            userType: user
        }
        const res = registerUser(params)
        res.then((data: any) => {
            if (data.status === "success") {
                toast({
                    title: data.message,
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                onClose()
            } else {
                toast({
                    title: data.message,
                    status: 'warning',
                    isClosable: true,
                })
            }
        })
        setSubmitBtnLoading(false)
    }
    return (
        <>
            {(from === "profile") ?
                <Box sx={styles.regText}>
                    Want To Become a Exchange Partners? <Box as="span" sx={styles.regLink} onClick={onOpen}>Register Here</Box>
                </Box> :
                <>
                    <Box sx={styles.linkText} hidden={user !== "user"}>Not yet registered? <Box as="span" sx={styles.link} onClick={onOpen}>Create a account here</Box></Box>
                    <Box sx={styles.linkText} hidden={user === "user"}>Not Yet Requested For Exchange Partners?  <Box as="span" sx={styles.link} onClick={onOpen}>Request Now</Box></Box>
                </>
            }

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size={'lg'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerBody p={["20px", "60px"]}>
                        <Flex sx={styles.hgroup}>
                            <IconButton
                                variant={"blank"}
                                aria-label='go back'
                                sx={styles.hgroupIcon}
                                icon={<Icon as={FiArrowLeft} fontSize={"32px"} />}
                                onClick={onClose} />
                            <Text as="h3" sx={styles.title}>{user === 'user' ? 'User Register' : 'Exchange Partner'}</Text>
                        </Flex>
                        <Box mb="56px">
                            <form onSubmit={handleSubmit}>
                                <FormControl id="first-name" isRequired sx={styles.formControl} isInvalid={invalidName}>
                                    <Input placeholder="Enter Your Name" size='lg' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} type="text" />
                                    <FormErrorMessage>Your name shouldn't be blank.</FormErrorMessage>
                                </FormControl>
                                <Grid sx={styles.mobileGrid}>
                                    <FormControl id="mobileno2" isRequired sx={styles.formControl} isInvalid={invalidMobile}>
                                        <Input placeholder="Enter Mobile No" size='lg' value={mobileNo} onChange={validateMobileNo} type="tel" />
                                        <FormHelperText>OTP will send to this mobile number</FormHelperText>
                                        <FormErrorMessage>Your Mobile No. is invalid</FormErrorMessage>
                                    </FormControl>
                                    <Button
                                        type='submit'
                                        variant={verifyOTP ? 'primary' : 'outline'}
                                        size="lg"
                                        isLoading={OTPBtnLoading}
                                        loadingText='Sending OTP'
                                        onClick={sendOTP}
                                    >{verifyOTP ? "OTP Verified" : "Send OTP"}</Button>
                                </Grid>
                                <VStack hidden={!showOTP} mb="50px">
                                    <FormLabel>Enter OTP</FormLabel>
                                    <HStack>
                                        <PinInput
                                            onComplete={handleOTPVerification}
                                        >
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                            <PinInputField />
                                        </PinInput>
                                    </HStack>
                                    <Button variant={"blank"} onClick={sendSMS} color="#ffb31a">Re-send OTP</Button>
                                </VStack>
                                <Grid sx={styles.formGrid}>
                                    <FormControl id="state" isRequired sx={styles.formControl} isInvalid={invalidState}>
                                        <Select placeholder="Select State" size='lg' onChange={updateCities} >
                                            {
                                                (stateList.length !== 0) ? stateList.map((item: any) => (<option key={item.state_id} value={item.state_id}>{item.state_name}</option>)) : (<option>Loading</option>)
                                            }
                                        </Select>
                                        <FormErrorMessage>Select any state</FormErrorMessage>
                                    </FormControl>
                                    <FormControl id="city" isRequired sx={styles.formControl} isInvalid={invalidCity}>
                                        <Select placeholder="Select City" size='lg' onChange={(e: ChangeEvent<HTMLSelectElement>) => setCity(Number(e.target.value))} >
                                            {
                                                cities.length !== 0 ? cities.map((item: any) => (<option key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                                            }
                                        </Select>
                                        <FormErrorMessage>Select any city</FormErrorMessage>
                                    </FormControl>
                                </Grid>

                                <Center>
                                    <Button
                                        type='submit'
                                        variant={'primary'}
                                        size="lg"
                                        isLoading={submitBtnLoading}
                                        loadingText='Submitting'
                                        disabled={!verifyOTP}
                                    >Submit</Button>
                                </Center>
                            </form>
                        </Box>
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default RegisterModal;