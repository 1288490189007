import React from 'react'
import {
    Grid,
    Text,
    Box,
} from '@chakra-ui/react'
const styles = {
    card: {
        bg: "sectionGray",
        borderRadius: "10px",
        border: "1px solid #e5e5e5",
        boxShadow: "0 10px 40px 0 rgb(0 0 0 / 2%)",
        p: "24px",
    },
    table: {
        gap: "30px",
    },
    row: {
        columnGap: "40px",
        gridTemplateColumns: ["1fr 1fr", ".4fr .6fr"],
    },
    title: {
        marginBottom: "15px",
        fontSize: "20px",
        color: '#343a40',
        lineHeight: "21px",
        fontWeight: 'bold',
        textTransform: 'uppercase',
        mb: '30px'
    },
    label: {
        fontSize: "16px",
        lineHeight: "32px",
        color: 'dark',
        fontWeight: 'semibold',
        position: "relative",

        _after: {
            content: '" : "',
            position: "absolute",
            right: "0px",
            top: "0px",
            color: 'dark',
        },
    },
    value: {
        fontSize: "1rem",
        lineHeight: "1.87",
        color: "#0009",
        fontWeight: 'medium',
        wordBreak: "breakall",
        flexGrow: 0,
        flexShrink: 0,
        flexBasis: "25%",
        flex: ["100%", "25%"],
    },
}

function TableGrayCard({ title, data }: any) {
    return (
        <Box sx={styles.card}>
            {title.length !== 0 ? <Text as="h2" sx={styles.title}>{title}</Text> : null}
            <Grid sx={styles.table}>
                {data.map((item: any, i: number) => (
                    <Grid key={i} sx={styles.row}>
                        <Text sx={styles.label}> {item.label} </Text>
                        <Text sx={styles.value}> {item.value} </Text>
                    </Grid>
                ))}
            </Grid>
        </Box>
    )
}

export default TableGrayCard