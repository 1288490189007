
import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import { Grid, SimpleGrid, Text, Flex, HStack, Alert, AlertTitle, AlertDescription, Box ,Link} from '@chakra-ui/react';
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { DealerMap } from './DealerMap';
import DealerStocks from './DealerStocks';
interface dealerProps {
    dealers: [],
    status: string
}

export const DealerList = (props: dealerProps) => {
    const { dealers, status } = props

    return (
        <>
            {(status === 'success') ?
                <>
                    {dealers.length !== 0 ?
                    <>
                        <Grid gap="30px" my={10} templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}>
                            {dealers && dealers.map((item: any, i: any) => (
                                <>
                                    <Box>
                                        <Text as="h1" fontSize={"1.8em"} fontWeight={"bold"} mb={"10px"} >
                                            {item.name}
                                        </Text>
                                        <Text as="p" mb={"10px"} fontSize={"1rem"} color='gray 500' >
                                            {item.address}
                                        </Text>
                                        <Link href={`mailto:${item.email}`}>
                                        <HStack mb={"10px"} spacing='8px'>
                                            <HiOutlineMail size={20} />
                                            <Text as="p" mb={"10px"} fontSize={"1rem"}>
                                                {item.email}
                                            </Text>
                                        </HStack>
                                        </Link>
                                        <Link href={`tel:${item.mobile}`}>
                                        <HStack mb={"10px"} spacing='8px'>
                                            <FaPhoneAlt size={20} />
                                            <Text as="p" mb={"10px"} fontSize={"1rem"}>
                                                {item.mobile}
                                            </Text>
                                        </HStack>
                                        </Link>
                                    </Box>
                                </>
                            ))}
                        </Grid>
                        <Box>
                        <DealerMap dealers={dealers}/>
                        </Box>
                        </>
                        :
                        <Box my={10}>
                            <Alert status='warning' variant='left-accent' flexDirection='column' alignItems='left'
                                justifyContent='left'
                                textAlign='left'
                                color='#DD6B20'
                            >
                                <AlertTitle mt={3} mb={1} fontSize='lg'  >
                                    No Dealer
                                </AlertTitle>
                                <AlertDescription>
                                    We have no dealer in this State
                                </AlertDescription>
                            </Alert>
                        </Box>
                    }
                </>
                :
                <> </>
            }
        </>
    )
}
