import {
    Box,
    Button,
    Flex,
    Switch, useBoolean
} from "@chakra-ui/react";

import { CloseIcon } from '@chakra-ui/icons';
import FPriceRange from './FPriceRange';
import FCity from './FCity';
import FState from './FState';
import FYear from './FYear';
import FMake from './FMake';
import FModel from "./FModel";
import FBodyType from "./FBodyType";
import FFuelType from "./FFuelType";
import FTransmissionType from "./FTransmissionType";
import FKMRange from "./FKMRange";
import { useEffect, useState, FunctionComponent} from "react";
import {
    useAppDispatch,
    useAppSelector
} from 'src/redux/hooks';
import {
    updatCertifiedStatus, updatePage, updateMaxKm, updateMinKm,
    updateMinPrice, updateMaxPrice, updateMinYear, updateMaxYear, updateModel,
    updateBodyType, updateTransmissionType, updatefuelType
} from "src/redux/reducers/filterBuyReducer";
import { updateModalValue, updatePriceValue } from "src/redux/reducers/commonReducer";
import { fetchModelList } from 'src/redux/reducers/modelListReducer';



const styles = {
    aside: {
        bg: "white",
        position: { base: 'fixed', xl: 'relative' },
        right: { base: '100%', xl: '0' },
        w: { base: '100vw', xl: '100%' },
        top: 0,
        zIndex: { base: '40', xl: '0' },
        p: { base: '92px 20px 20px', xl: 0 },
        h: { base: 'full', xl: 'auto' },
        overflowY: { base: 'scroll', xl: 'auto' },
        overscrollBehavior: 'contain',
        transform: 'translate3d(0,0,0)',
        transition: 'transform .3s ease-in',
        '&.active': {
            transform: { base: 'translate3d(100%,0,0)', xl: 'translate3d(0,0,0)' },
        }
    },
    resetBtn: {
        p: "10px 24px",
        size: "md",
        border: "10px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#012413",
        bg: "#f7fffb",
        _hover: {
            bg: "#f2f2f2",
            textDecoration: "none",
        }
    },
    closeBtn: {
        //position: 'absolute',
        // right: 4,
        // top: 4,
        p: 0,
        display: { base: 'block', xl: 'none' },
        my: 5
    }
}

interface BuFilterProps {

    updateStocks: (type: any, value: any) => void
    show: any
    showFilter: () => void
    stocks: {
        [key: string]: any;
    }
    handleResetRange:()=> void
    resetRange:any,
    model:any,
    price:any,
    loading: boolean,
    activeTab:string,
    setActiveTab:(tab:any)=> void

}

const Filter: FunctionComponent<BuFilterProps> = ({ updateStocks, show, showFilter, stocks,handleResetRange, resetRange,model,price,loading,activeTab,setActiveTab}) => {

    const [certified, setCertified] = useState<boolean>(false);
    const [flag, setFlag] = useBoolean();
    const dispatch = useAppDispatch()
    const total = useAppSelector((state: any) => state.buyFilterData.data.total);
    const [filterMake, setFilterMake] = useState<string>('Audi');
    const [filterNonAudi, setFilterNonAudi] = useState<boolean>(false);
  
    const handleTabClick = (tab: string) => {
        setActiveTab(tab);
        switch (tab) {
            case 'allAudiCars':
                setFilterMake('AUDI');
                setFilterNonAudi(false);
                setCertified(false);
                dispatch(fetchModelList('AUDI'));
                updateStocks('tabChange', ['AUDI', false, false,'']);
                dispatch(updateModalValue(''));
                break;
            case 'otherBrandCars':
                setFilterMake('');
                setFilterNonAudi(true);
                setCertified(false);
                dispatch(fetchModelList(""));
                updateStocks('tabChange', ['', true, false,'']); 
                dispatch(updateModalValue(''));
                break;
            case 'certifiedAudiCars':
                setFilterMake('AUDI');
                setFilterNonAudi(false);
                setCertified(true);
                dispatch(fetchModelList('AUDI'));
                updateStocks('tabChange', ['AUDI', false, true,'']); 
                dispatch(updateModalValue(''));
                break;
            // Add additional cases if needed
            default:
                break;
        }
    };

    const handleToggle = () => {
        setFlag.toggle();
        setCertified(prevCheck => !prevCheck);

    }
    // const resetForm = (e: any) => {
    //     e.preventDefault()
    //     dispatch(updatePriceRange([]))
    //     dispatch(updateStates([]))
    //     dispatch(updateYearRange([]))
    //     dispatch(updateMakes([]))
    //     dispatch(updateModels([]))
    // }
    const resetFilter = () => {
        // dispatch(updatCertifiedStatus(certified));  
        // dispatch(updateModel(""))
        //  dispatch(updateBodyType(""))
        //  dispatch(updateMinPrice(""))
        //  dispatch(updateMaxPrice(""))
        //  dispatch(updateMinYear(""))
        //  dispatch(updateMaxYear(""))
        //  dispatch(updatefuelType(""))
        //  dispatch(updateTransmissionType(""))
        //  dispatch(updateMaxKm(""))
        //  dispatch(updateMinKm(""))
        updateStocks("reset", "from filter"); 
        handleResetRange();
        dispatch(updateModalValue(""));  
        dispatch(updatePriceValue("50000000"));           
    }

    // useEffect(() => {
    //     // dispatch(updatCertifiedStatus(certified));  
    //     // dispatch(updatePage(1));
    //     updateStocks("certified", certified);
    // }, [certified]);

  

    return (
        <Box as="aside" sx={styles.aside} className={show ? 'active' : ''}>
            <form key={resetRange}>
                <Box>
                <Flex pb={6}>
                        <Box
                            w="full"
                            onClick={() => handleTabClick('allAudiCars')}
                            borderBottom={activeTab === 'allAudiCars' ? '4px solid #F50537' : '2px solid transparent'}
                            color={activeTab === 'allAudiCars' ? '#F50537' : '2px solid transparent'}
                            px={5}
                            py={3}
                            cursor={loading ? 'not-allowed' : 'pointer'}
                            fontWeight={"bold"}
                            pointerEvents={loading ? 'none' : 'auto'}
                        >
                            All Audi Cars
                        </Box>
                        <Box
                            w="full"
                            onClick={() => handleTabClick('otherBrandCars')}
                            borderBottom={activeTab === 'otherBrandCars' ? '4px solid #F50537' : '2px solid transparent'}
                            color={activeTab === 'otherBrandCars' ?  '#F50537' : '2px solid transparent'}
                            px={5}
                            py={3}
                            cursor={loading ? 'not-allowed' : 'pointer'}
                            fontWeight={"bold"}
                            pointerEvents={loading ? 'none' : 'auto'}
                        >
                            Other Brand Cars
                        </Box>
                        <Box
                            w="full"
                            onClick={() => handleTabClick('certifiedAudiCars')}
                            borderBottom={activeTab === 'certifiedAudiCars' ? '4px solid #F50537' : '2px solid transparent'}
                            color={activeTab === 'certifiedAudiCars' ? '#F50537' : '2px solid transparent'}
                            px={5}
                            py={3}
                            cursor={loading ? 'not-allowed' : 'pointer'}
                            fontWeight={"bold"}
                            pointerEvents={loading ? 'none' : 'auto'}
                        >
                            Certified Audi Cars
                        </Box>
                    </Flex>
                    {/* <Flex pb={6} onClick={handleToggle} cursor={"pointer"}>
                        <Box
                            w="full"
                            bg={flag ? "lightgray" : 'primary'}
                            color={flag ? "black" : 'white'}
                            px={5}
                            py={3}
                            boxShadow={'0 3px 6px 0 rgb(0 0 0 / 16%)'}
                            fontWeight={"bold"}
                            >
                            All Audi Cars
                        </Box>
                        <Box
                            w="full"
                            bg={flag ? 'primary' : "lightgray"}
                            color={flag ? "white" : 'black'}
                            px={5}
                            py={3}
                            boxShadow={'0 3px 6px 0 rgb(0 0 0 / 16%)'}
                            fontWeight={"bold"}>
                            Certified Audi Cars
                        </Box>
                    </Flex> */}
                </Box>
                <FCity updateStocks={updateStocks} />
               {activeTab=="otherBrandCars" && <FMake updateStocks={updateStocks} filterMake={filterMake} />}
                <FModel updateStocks={updateStocks} model={model} />
                {/* <FBodyType updateStocks={updateStocks} /> */}
                <FPriceRange updateStocks={updateStocks} price={price}/>
                <FYear updateStocks={updateStocks} />
                <FFuelType updateStocks={updateStocks} />
                {/* <FTransmissionType updateStocks={updateStocks} /> */}
                <FKMRange updateStocks={updateStocks} />
                <Button variant='primary' sx={styles.closeBtn} onClick={showFilter} w="full">{`Show Results (${stocks.total})`}</Button>
                <Button variant='primary' type="reset" onClick={resetFilter} w="full">Reset Filter</Button>
            </form>
        </Box>
    );
}

export default Filter;
