import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { SAVE_CUSTOMER_INTEREST } from "src/constants";
import Cookies from 'js-cookie';

export const customerInfo = createAsyncThunk(
    "customer",
    (payload: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                SAVE_CUSTOMER_INTEREST,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => response )
            .catch((error) => error)
        return res
    }
)

const LSCust = Cookies.get('cust');
const LSCity = Cookies.get('cityId');

const initialState={
    openCustomerModal:LSCity && !LSCust
}
const customerSlice = createSlice({
    name: 'custInfo',
    initialState,
    reducers: {
      openCustomerModal: (state, action) => {
            state.openCustomerModal = action.payload;
        },

    },
  })
  
  export const { openCustomerModal } = customerSlice.actions
  export default customerSlice.reducer