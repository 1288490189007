import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ibbApi from "src/api/ibbApi";


export const fetchMakeList = createAsyncThunk(
    "makes/fetchMakeList",
    (token: string) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            "for": "make",
           // "year": new Date().getFullYear(),
            "access_token": token
        }
        return ibbApi
            .get(
                'get-ibb-make',
               
                {
                    headers: headers
                }
            )
            .then((response) => response.data.data)
            .catch((error) => error)
    }
);

const makesInitialState = {
    status: "idle",
    popularMakes: [
        'mahindra',
        'swaraj',
        'massey ferguson',
        'sonalika',
        'farmtrac',
        'e-tractor',
        'john deere',
        'escorts',
        'ace',
        'ford',
        'preet'
    ],
    data: [],
    error: {},
};

const makesSlice = createSlice({
    name: "makes",
    initialState: makesInitialState,
    reducers: {},
    extraReducers: {
        [fetchMakeList.pending.type]: (state) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchMakeList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchMakeList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export default makesSlice.reducer;