import oemApi from "./oemApi"
import { USER_LOGINCHECK_API, BROKER_LOGINCHECK_API } from "src/constants"


const UserLoginCheck = (mobileNo: number | string, userType: string): any => {
    const params = {
        mobile: mobileNo,
        tag: "dms",
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`
    };
    const url = (userType === "user") ? USER_LOGINCHECK_API : BROKER_LOGINCHECK_API
    const data = oemApi
        .post(`${url}`, params)
        .then((res) => res.data)
        .catch((err) => err);
    return data;
};
export default UserLoginCheck
