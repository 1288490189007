import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ibbApi from "src/api/ibbApi";

export const fetchModelList = createAsyncThunk(
    "makes/fetchModelList",
    (make: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            // "for": "model",
            "make": make
           // "year": new Date().getFullYear(),
            //"access_token": payload.token
        }
        return ibbApi
            .get(
                `/get-ibb-model?make=${make}`,
                // params,
                {
                    headers: headers
                }
            )
            .then((response) => {
                
                // const arr = { make: payload.make, models: response.data.data }
                const arr=response.data.data ;
                return response.data.data;
                // return [arr];
            })
            .catch((error) => error)
    }
);

interface IinitialState {
    status: string
    data: any[]
    error: object
}

const initialState: IinitialState = {
    status: "idle",
    data: [],
    error: {},
};

const modelsSlice = createSlice({
    name: "models",
    initialState: initialState,
    reducers: {
        clearModeList: (state) => {
            state.data = []
        }
    },
    extraReducers: {
        [fetchModelList.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchModelList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success";
            if (action.payload) {
                // Assuming action.payload is an array
                state.data = Object.values(action.payload);
            } else {
                state.data = [];
            }
            // state.data = [...state.data, ...action.payload]
            state.error = {}
        },
        [fetchModelList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export const { clearModeList } = modelsSlice.actions

export default modelsSlice.reducer;