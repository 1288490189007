import React, { FunctionComponent } from 'react'

import {
    Box,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    Portal,
    Icon,
    useToast,
} from '@chakra-ui/react';
import UserIcon from '../icons/User';
import { useAppSelector, useAppDispatch } from 'src/redux/hooks';
import { FiLogOut } from "react-icons/fi";
import { updateLoginStatus } from 'src/redux/reducers/userReducer';
import RegisterModal from './RegisterModal';
const styles = {
    menuBtn: {
        fontSize: 'sm',
        fontWeight: 'normal',
        pr: '0'
    },
    menuBtnIcon: {
        bg: 'white',
        borderRadius: '50%',
        boxShadow: '0 8px 12px 0 rgba(1, 36, 19, 0.12)',
        p: 2
    },
    menuItem: {
        '.chakra-menu__icon-wrapper': {
            lineHeight: '1'
        },
        _hover: {
            color: 'primary'
        }
    },
    icon: {
        fontSize: '16px',
    },
    regText: {
        p: "0.4rem 0.8rem",
        fontSize: "12px",
        fontWeight: 'medium'
    },
}
const ProfileModal: FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const toast = useToast()
    const data = useAppSelector((state: any) => state.userData.data)

    const handleLogout = () => {
        localStorage.removeItem('userData')
        dispatch(updateLoginStatus(false))
        toast({
            title: "You've been successfully logged out!",
            status: 'success',
            duration: 3000,
            isClosable: true,
        })
    }

    const name = (data.user_type === 1) ? data.user_name : data.cus_name
    const mobileNo = (data.user_type === 1) ? data.mobile : data.cus_mobile

    return (<Menu isLazy>
        <MenuButton
            as={Button}
            variant="blank"
            sx={styles.menuBtn}
            rightIcon={<Box as='span' sx={styles.menuBtnIcon}><UserIcon color={(data.user_type === 1) ? 'primary' : 'red'} /></Box>}
        >
            <span>{name}</span>
        </MenuButton>
        <Portal>
            <MenuList>
                <Box sx={styles.regText}>
                    <Box>Name: {name}</Box>
                    <Box>Mobile: {mobileNo}</Box>
                </Box>
                <Box hidden={data.user_type === 2}>
                    <RegisterModal user={"exchange partners"} from={"profile"} />
                </Box>
                <MenuDivider />
                <MenuItem onClick={handleLogout} sx={styles.menuItem} icon={<Icon as={FiLogOut} sx={styles.icon} />}>Logout</MenuItem>
            </MenuList>
        </Portal>
    </Menu>);
}

export default ProfileModal;