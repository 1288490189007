import React from 'react';
import { useState } from "react";
import {
    Box,
    Text,
    Grid,
    GridItem,
    Input,
    Flex,
    FormControl,
    FormLabel,
    Button,
} from "@chakra-ui/react";

import phoneNumberFormatter from "../../lib/phoneNumberFormatter";

export default function SliderSubmit({ vehicle_details }: any) {
    const [mask] = useState<Boolean>(true)

    const styles = {
        cardTitle: {
            fontSize: "md",
            fontWeight: "medium",
            color: "secondary",
            mt: "5px",
            mb: "20px",
            textTransform: 'uppercase'
        },
        maskedPrice: {
            textAlign: 'center',
            mb: '35px',
            bg: '#f9f9f9',
            border: '1px',
            borderColor: '#e5e5e5',
            borderRadius: '10px',
            boxShadow: '0 10px 40px 0 rgb(0 0 0 / 2%)',
            p: '20px',
            userSelect: 'none',
            filter: mask ? 'blur(8px)' : 'none'
        },
        maskedPriceTitle: {
            color: '#343a40',
            fontSize: '26px',
            fontWeight: 'medium',
            mb: '8px'
        },
        maskedPriceDesc: {
            color: '#343a40',
            fontSize: '14px',
            lineHeight: '1.4'
        }
    }

    // const handleSubmit = (values: any) => {

    // }

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                sx={styles.cardTitle}
            >EXPECTED SELLING PRICE RANGE FOR  {vehicle_details.year}  {vehicle_details.make}  {vehicle_details.model}  {vehicle_details.variant}</Text>
            <Box overflowY={"scroll"}>
                <Box sx={styles.maskedPrice}>
                    <Box sx={styles.maskedPriceTitle}>₹ XXXXXXX - ₹ XXXXXXX</Box>
                    <Box as={'p'} sx={styles.maskedPriceDesc}>Note : These are indicative prices and depend on the condition of the tractor. Excellent condition tractors may more and tractors not in good condition may cost less.</Box>
                </Box>
            </Box>
            <Text
                as="h4"
                sx={styles.cardTitle}>{`Enter Name &amp; Mobile Number to See Your Tractor's Valuation`}</Text>
            <form>
                <Grid templateColumns='repeat(2, 1fr)' gap={10} mb={9}>
                    <GridItem>
                        <FormControl id='Name' isRequired>
                            <Input placeholder=' ' />
                            <FormLabel>Your Name</FormLabel>
                        </FormControl>
                    </GridItem>
                    <GridItem>
                        <FormControl id='Phone' isRequired>
                            <Input placeholder=' ' type='tel' onKeyPress={(e) => phoneNumberFormatter(e)} />
                            <FormLabel>Your Phone No.</FormLabel>
                        </FormControl>
                    </GridItem>
                </Grid>
                <Flex justifyContent={'flex-end'}>
                    <Button size={'lg'} type="submit">Submit</Button>
                </Flex>

            </form>
        </Flex>
    );
}