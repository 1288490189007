import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import { USER_LOGIN_API, BROKER_LOGIN_API } from "src/constants"

interface IParams {
    mobileNo: string | number
    active: string
}

export const fetchUserData = createAsyncThunk(
    "user/fetchUserData",
    (params: IParams) => {
        const payload = {
            "mobile": params.mobileNo,
            "tag": "dms",
            "platform_id": `${process.env.REACT_APP_PLATFORM_ID}`
        }
        const url = (params.active === 'user') ? USER_LOGIN_API : BROKER_LOGIN_API
        const res = oemApi
            .post(
                `${url}`,
                payload
            )
            .then((response) => response.data)
            .catch((error) => error)

        return res
    }
);

const LSUserData = JSON.parse(`${localStorage.getItem('userData')}`)

const userSlice = createSlice({
    name: "user",
    initialState: {
        status: "idle",
        isLogged: LSUserData ? true : false,
        data: LSUserData ? LSUserData : {},
        error: {},
    },
    reducers: {
        updateLoginStatus: (state, action) => {
            state.status = "idle"
            state.data = {}
            state.isLogged = action.payload
        }
    },
    extraReducers: {
        [fetchUserData.pending.type]: (state) => {
            state.status = "loading"
            state.data = {}
            state.isLogged = false
            state.error = {}
        },
        [fetchUserData.fulfilled.type]: (state, action: any) => {
            const new_Obj = { ...action.payload.login_userdetails[0], user_type: action.payload.user_type }
            state.status = "success"
            state.data = new_Obj
            state.isLogged = true
            state.error = {}
            localStorage.setItem('userData', JSON.stringify(new_Obj))
        },
        [fetchUserData.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = {}
            state.isLogged = false
            state.error = action.payload
        },
    },
});
export const { updateLoginStatus } = userSlice.actions;
export default userSlice.reducer
export const selectUserLogged = (state: any) => state.userData.isLogged
