import { useEffect, useState, } from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import {
    Box,
    Grid,
    Text, Link, Image, Skeleton, VStack, Spinner, Heading, Button, HStack, Stack, useDisclosure,
    // Table,
    // Thead,
    // Tbody,
    // Tfoot,
    // Tr,
    // Th,
    // Td,

    TableContainer,
    AspectRatio,
    Hide,
    GridItem,

} from "@chakra-ui/react";
import { fetchProductDetails } from 'src/redux/reducers/productDetailsReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { ChevronLeftIcon } from '@chakra-ui/icons';
import { currencyFormatter } from 'src/lib/currencyFormatter';
import { FaPhoneAlt } from "react-icons/fa";
import { HiOutlineMail } from "react-icons/hi";
import { compareProduct } from 'src/redux/reducers/compareReducer';
import { Table, Thead, Tbody, Tr, Th, Td } from 'src/components/table';
import '../theme/table.css';


const styles = {
    section: {
        mt: { base: 15, xl: 30 },
    },

    subTitle: {
        fontSize: { base: 'xl', xl: '20px' },
        fontWeight: 'extendedBold',
    },
    text: {
        fontSize: { base: 'tiny', xl: 'base' },
        mb: { base: 6, xl: 8 },
    },

}
export const Compare = () => {
    const params = useParams();
    const dispatch = useAppDispatch()
    const compare = useAppSelector((state: any) => state.compare.data);
    const status = useAppSelector((state: any) => state.compare.status);
    const [value,setValue]= useState(3);

    useEffect(() => {
        const id = params['*']?.split("/")
        dispatch(compareProduct(id));
    }, [params]);

  useEffect(()=>{
   if(compare &&  compare.length<3){
   setValue(3)
   }
   else{
    setValue(4)
   }

  },[compare])

    return (
        <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb={{ base: 10, xl: 25 }}>
            <Box my={4}>
                <Link
                    as={RouterLink}
                    to={`/buy`}>
                    <ChevronLeftIcon mx={2} boxSize={6} />
                    Back
                </Link>
            </Box>
            {(status === "success") ?
                <>
                    <Box>
                        <Heading color="gray" mb={4} fontSize={{ base: 'xl', xl: '40px' }}>Vehicle Comparison</Heading>
                        <TableContainer>
                            <Table variant="unstyled" overflowX={"hidden"}>
                                <Tr>
                                    <Hide below='md'>
                                        <Th >
                                            <Box minW={"200"}></Box>
                                        </Th>
                                    </Hide>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            {/* <AspectRatio 
                                                ratio={1.618 / 1}
                                        borderRadius={'10px 10px 0 0'}>*/}
                                            <Box width={"280px"} height={"200px"}  >
                                                <Image
                                                    objectFit={'cover'}
                                                    src={item.stock_images[0].image_url}
                                                    alt={"image"}
                                                    width={"280px"} height={"200px"}
                                                    margin={"auto"}
                                                />
                                            </Box>
                                            {/* </AspectRatio> */}
                                            <Box p={'14px 20px'} mt={3}>

                                                <Text
                                                    as={'h3'}
                                                    textTransform="capitalize"
                                                    fontSize="md"
                                                    letterSpacing="wide"
                                                    color="secondary"
                                                    height={'20px'}
                                                    mb={'10px'}
                                                    noOfLines={1}
                                                >
                                                    {`${item.vehicle_details.make} ${item.vehicle_details.model}`}
                                                </Text>
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="gray"
                                                >
                                                    {`${item.vehicle_details.year_of_manufacture} ${item.vehicle_details.variant}`}
                                                </Text>
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="gray"
                                                >
                                                    {`${item.vehicle_details.odo_reading} Km ${item.dealer.name}`}
                                                </Text>
                                                <Text
                                                    fontSize="18px"
                                                    fontWeight={'bold'}
                                                    color="secondary"
                                                    my={'10px'}
                                                >{currencyFormatter(item.stock_details.selling_price)}</Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                            </Table>
                        </TableContainer>

                        <Box bg="lightgray">
                            <Text as={'h3'}
                                fontSize="20px"
                                fontWeight={'bold'}
                                letterSpacing="wide"
                                color="secondary"
                                my={10}
                                py={5}
                                pl={4}>
                                Vehicle Data
                            </Text>
                        </Box>

                        <TableContainer>
                            <Table variant="unstyled" overflowX={"hidden"}>
                                {/* <Tr>
                                    <Th >
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Registration Number
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'10px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.registration_no}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr> */}
                                <Tr>
                                    <Th>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary"
                                            >
                                                Date of first registration
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.date_of_first_registration}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Year of manufacture
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.year_of_manufacture}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                City
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.city_name}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Make
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.make}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Model
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.model}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Variant
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.variant}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Color
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.colour}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Odometer Reading
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.odo_reading}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Fuel Type
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.fuel_type}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                No. of Vehicle Owners
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.no_of_veh_owner}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                                {/* <Tr>
                                    <Th maxW={{ xl: "200px" }}>
                                        <Box >
                                            <Text as={'h3'}
                                                fontSize="15px"
                                                fontWeight={'bold'}
                                                color="secondary">
                                                Accident free
                                            </Text>
                                        </Box>
                                    </Th>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} bg="lightgray">
                                                <Text
                                                    as='span'
                                                    display={'block'}
                                                    fontSize="14px"
                                                    color="secondary">
                                                    {item.vehicle_details.accifree}
                                                </Text>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr> */}

                            </Table>
                        </TableContainer>
                        {/* <Box bg="lightgray">
                            <Text as={'h3'}
                                fontSize="20px"
                                fontWeight={'bold'}
                                letterSpacing="wide"
                                color="secondary"
                                my={10}
                                py={5}
                                pl={4}>
                                Dealer
                            </Text>
                        </Box> */}
                        {/* <TableContainer display={{ base: "block", xl: "none" }}>
                            <Table variant="unstyled" overflowX={"hidden"}>
                                <Tr>
                                    <Hide below='md'>
                                        <Th maxWidth={{ xl: "30px" }}>
                                            <Box minW={"200"}></Box>
                                        </Th>
                                    </Hide>
                                    {compare && compare.map((item: any, k: any) => {
                                        return (<Td>
                                            <Box p={'14px 20px'} >
                                                <Text as="h1" fontSize={"1em"} fontWeight={"bold"} mb={"10px"} color="gray">
                                                    {item.dealer.name}
                                                </Text>
                                                <p style={{ color: "RGBA(0, 0, 0, 0.48)" }}>{item.dealer.address}</p>                                             
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <HiOutlineMail size={15} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.email}
                                                    </Text>
                                                </HStack>
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <FaPhoneAlt size={12} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.mobile}
                                                    </Text>
                                                </HStack>
                                            </Box>
                                        </Td>)
                                    })}
                                </Tr>
                            </Table>
                        </TableContainer> */}

                        {/* <Grid templateColumns={`repeat(4, 1fr)`} gap={6} display={{ base: "none", xl: "block" }}>
                            <GridItem></GridItem>
                        {compare && compare.map((item: any, k: any) => {
                                return (
                                    <GridItem w='100%'  bg='blue.500'>
                                       <Box p={'14px 20px'} >
                                                <Text as="h1" fontSize={"1em"} fontWeight={"bold"} mb={"10px"} color="gray">
                                                    {item.dealer.name}
                                                </Text>
                                                <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)"> 
                                               {item.dealer.address}
                                                </Text>
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <HiOutlineMail size={15} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.email}
                                                    </Text>
                                                </HStack>
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <FaPhoneAlt size={12} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.mobile}
                                                    </Text>
                                                </HStack>
                                            </Box>

                                    </GridItem>
                                )
                            })}
                        </Grid> */}
                        {/* {value} */}
                     {/* {compare &&  <Hide below='md'>
                            <Grid templateColumns={{ base: "repeat(1, 1fr)", xl: `repeat(${value}, 1fr)` }} gap={6}>
                            <GridItem></GridItem>
                        {compare && compare.map((item: any, k: any) => {
                                return (
                                    <GridItem w='100%'  bg="lightgray">
                                       <Box p={'14px 20px'} >
                                                <Text as="h1" fontSize={"1em"} fontWeight={"bold"} mb={"10px"} color="gray">
                                                    {item.dealer.name}
                                                </Text>
                                                <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)"> 
                                               {item.dealer.address}
                                                </Text>
                                                <Link href={`mailto:${item.dealer.email}`}>
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <HiOutlineMail size={15} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.email}
                                                    </Text>
                                                </HStack>
                                                </Link>
                                                <Link href={`tel:${item.dealer.mobile}`}>
                                                <HStack mb={"10px"} spacing='8px'>
                                                    <FaPhoneAlt size={12} />
                                                    <Text as="p" mb={"10px"} fontSize={"14px"} color="RGBA(0, 0, 0, 0.48)">
                                                        {item.dealer.mobile}
                                                    </Text>
                                                </HStack>
                                                </Link>
                                            </Box>

                                    </GridItem>
                                )
                            })}
                            </Grid>
                        </Hide>} */}
                    </Box>
                </>
                :
                <Box alignItems={"center"} m="auto">
                    <Spinner
                        thickness='4px'
                        emptyColor='gray.200'
                        color='blue.500'
                        size='xl'
                    />
                </Box>
            }

        </Box>
    )
}
