import { IProductPayload } from './../../commonTypes';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import { STOCK_LISTING_API, IMAGE_LIST_API } from "src/constants"

export const fetchProduct = createAsyncThunk(
    "product/fetchProduct",
    (payload: IProductPayload) =>
        oemApi
            .post(
                STOCK_LISTING_API,
                payload
            )
            .then((res) => res.data.data[0])
            .catch((error) => error)
)

export const fetchImages = createAsyncThunk(
    "product/fetchImages",
    (leadId: number) => {
        const params = {
            "lead_id": leadId,
            "tag": "web"
        }
        const res = oemApi
            .post(
                IMAGE_LIST_API,
                params
            )
            .then((res) => res.data.data)
            .catch((error) => error)
        return res
    }
)


const productSlice = createSlice({
    name: "product",
    initialState: {
        stock: {
            status: "idle",
            data: [],
            error: {},
        },
        images: {
            status: "idle",
            data: [],
            error: {},
        }
    },
    reducers: {
    },
    extraReducers: {
        [fetchProduct.pending.type]: (state) => {
            state.stock.status = "loading"
            state.stock.data = []
            state.stock.error = {}
        },
        [fetchProduct.fulfilled.type]: (state, action: any) => {
            state.stock.status = "success"
            state.stock.data = action.payload
            state.stock.error = {}
        },
        [fetchProduct.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.stock.status = "error"
            state.stock.data = []
            state.stock.error = action.payload
        },
        [fetchImages.pending.type]: (state) => {
            state.images.status = "loading"
            state.images.data = []
            state.images.error = {}
        },
        [fetchImages.fulfilled.type]: (state, action: any) => {
            state.images.status = "success"
            state.images.data = action.payload
            state.images.error = {}
        },
        [fetchImages.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.images.status = "error"
            state.images.data = []
            state.images.error = action.payload
        },
    },
});

export default productSlice.reducer;