import React, { } from 'react';
import {
    Box,
    Text,
    Grid,
    Button,
} from "@chakra-ui/react";
import { useAppSelector } from 'src/redux/hooks';
interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    setHours: (values: any) => void;

}
export default function SliderHours({ sliderStep, nextSliderStep, setHours }: IData) {
    const hours = useAppSelector((state: any) => state.commonData.hours);
    const handleSubmit = (values: any) => {
        setHours(values)
        nextSliderStep();
    };

    return (
        <Box>
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                mt="5px"
                mb="20px"
            >
                TRACTOR HOURS
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap="18px">
                {hours.map((item: string, index: number) => (
                    <Button
                        variant={"secondary"}
                        key={index}
                        onClick={() => handleSubmit(item)}
                    >{item}
                    </Button>
                ))}
            </Grid>
        </Box>
    );
}