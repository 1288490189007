import {
    FormControl, FormLabel, 
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Box, Flex, Spacer, 
} from '@chakra-ui/react';
import React, { useEffect, useState } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { BiChevronLeftCircle } from "react-icons/bi";
import { BiChevronRightCircle } from "react-icons/bi";
import { updatePriceValue } from 'src/redux/reducers/commonReducer';
import { currencyFormat } from 'src/lib/currencyFormat';

const styles = {
    formControl: {
        mb: 5
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        maxHeight: 200,
        overflowY: "scroll",
        mb: 4
    }
}

interface IFPriceRange {
    updateStocks?: (type: any, value: any) => void;
    price:any 
}

const FPriceRange = ({ updateStocks,price }: IFPriceRange) => {
    const dispatch = useAppDispatch()
    const priceList = useAppSelector((state: any) => state.priceList.data)
    const [minPrice, setMinPrice] = useState(priceList?.[0]);
    //const [maxPrice, setMaxPrice] = useState(priceList?.[priceList.length - 1]);
    const [maxPrice, setMaxPrice] = useState(priceList?.[priceList.length - 1]
        ? currencyFormat(priceList?.[priceList.length - 1])
        : currencyFormat(50000000)
      );
    const priceValue = useAppSelector((state: any) => state.commonData.priceValue);

    const handleChangePrice = (val: any) => {
        setMinPrice(val[0]);
        setMaxPrice(val[1]);
        updateStocks && updateStocks("priceRange", val);
        dispatch(updatePriceValue(val[1]));

    }

    useEffect(() => {
        setMinPrice(priceList?.[0]);
        if(priceValue)
        {
            setMaxPrice(priceValue);
        }
        else
        {
            setMaxPrice(priceList?.[priceList.length - 1]);
        }
    }, [priceList,priceValue])

   
    return (
        <>
            <FormControl sx={styles.formControl}>
                <FormLabel sx={styles.legend}>Price</FormLabel>
                <Box px={6}>
                    <RangeSlider aria-label={['min', 'max']} defaultValue={[0, priceValue || 50000000]} min={0} max={50000000} step={1000000} onChangeEnd={(val) => handleChangePrice(val)}>
                        <RangeSliderTrack bg='lightgray'>
                            <RangeSliderFilledTrack bg='gray5' />
                        </RangeSliderTrack>
                        <RangeSliderThumb boxSize={10} index={0}>
                            <Box color='gray5' as={BiChevronLeftCircle} boxSize={10} />
                        </RangeSliderThumb>
                        <RangeSliderThumb boxSize={10} index={1}>
                            <Box color='gray5' as={BiChevronRightCircle} boxSize={10} />
                        </RangeSliderThumb>
                    </RangeSlider>
                </Box>
                <Flex>
                    <Box px='4'>
                {currencyFormat(minPrice)}
                    </Box>
                    <Spacer />
                    <Box px='4'>
                 {currencyFormat(Number(maxPrice))}
                    </Box>
                </Flex>
            </FormControl>
        </>
    );
}

export default FPriceRange;