import { createSlice } from "@reduxjs/toolkit";

const initialState = {
 banner: {
    image: {
        //sm: `/images/${process.env.REACT_APP_THEME}/banner-sm@2x.jpg`,
        sm: [`/images/${process.env.REACT_APP_THEME}/banner4.png`,`/images/${process.env.REACT_APP_THEME}/banner5.png`,`/images/${process.env.REACT_APP_THEME}/banner6.png`],
        // md: `/images/${process.env.REACT_APP_THEME}/banner.jpg`,
        md: [`/images/${process.env.REACT_APP_THEME}/banner4.png`,`/images/${process.env.REACT_APP_THEME}/banner5.png`,`/images/${process.env.REACT_APP_THEME}/banner6.png`],
        xl: [`/images/${process.env.REACT_APP_THEME}/banner4.png`,`/images/${process.env.REACT_APP_THEME}/banner5.png`,`/images/${process.env.REACT_APP_THEME}/banner6.png`],
    },
    title: 'Enjoy Peace Of Mind<br> With Audi Approved <span class="em">:plus</span>',
    link: {
        text: 'Explore pre-owned Audi Cars',
        href: '/buy'
    }
 }
};

const homeSlice = createSlice({
  name: "homeData",
  initialState: initialState,
  reducers: {},
  extraReducers: {},
});

export default homeSlice.reducer;
