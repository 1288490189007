import oemApi from "./oemApi";
import {VERIFY_SMS } from "src/constants"

const FetchVerifySMS = (mobileNo: number | string, otp: number | string): any => {
    const params = {
        cus_mobile: mobileNo,
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`,
        otp: otp,
        tag: "dms",
        access_token: `${process.env.REACT_APP_API_URL}/get-access-token`
    };
    const data = oemApi
        .post(VERIFY_SMS, params)
        .then((res) => res.data)
        .catch((err) => err);
    return data;
};
export default FetchVerifySMS
