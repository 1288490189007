import React from 'react';
import { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import {
    Button,
    Box,
    Text,
    Grid,
    Image,
    Flex
} from "@chakra-ui/react";

import AutoComplete from '../AutoComplete';
// type makeListProps = {
//     for: string;
//     year: number | string;
//     access_token: string;
// }
// type makeList = {}

// for dummy purpose
// const makeList = data.data
// for dummy purpose



interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    setMake: (values: any) => void;
    year?: number | null;
}
export default function SliderMake({ sliderStep, nextSliderStep, setMake, year }: IData) {
    const [makeList, setmakeList] = useState<string[]>([]);
    const data = useAppSelector((state: any) => state.makes.data)
    const dataMakes: object[] = []
    makeList.filter((item: any, i:number) => dataMakes.push({ id: i, name: item }));

    useEffect(() => {
        setmakeList(data)
    }, [data])

    const handleSubmit = (values: any) => {
        setMake(values)
        nextSliderStep()
    };

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT BRAND
            </Text>
            <AutoComplete
                data={dataMakes}
                cb={handleSubmit}
            />
            <Text as="h2" fontSize={"1em"} fontWeight={"semi-bold"} mb={3}>
                Popular Brands
            </Text>
            <Box overflowY={"scroll"}>
                <Grid
                    gap={{ base: "20px", xl: "30px" }}
                    templateColumns={{ base: "repeat(2, 1fr)", xl: "repeat(4, 1fr)" }}
                >
                    {makeList.map((brand: any, index: number) => (
                        <Button
                            variant="secondary"
                            key={index}
                            display={'flex'}

                            justifyContent={'center'}
                            p={'0'}
                            height={'64px'}
                            onClick={() => handleSubmit(brand)}
                        >
                            <Image

                                objectFit='contain'
                                borderRadius={'6px'}
                                p="6px"
                                height={'64px'}
                                width={'100%'}
                                src={`/images/swaraj/brands/${brand.replace(/\s+/g, '_')}.png`}
                                alt={brand}
                            />

                        </Button>

                    ))}
                </Grid>
            </Box>
        </Flex>
    );
}