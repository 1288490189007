
import React from 'react'
import { useEffect, useState, useMemo, useRef, FunctionComponent } from 'react';
import {
    Box,
    Grid,
    useDisclosure,
    useToast
} from "@chakra-ui/react";
import { fetchStocks } from 'src/api/fetchStocks';
import BuyHeader from 'src/components/Buy/buyHeader';
import ProductsList from '../components/Buy/ProductList';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
// import Filter from '../components/Buy/Filter';
import Filter from '../components/Buy/filterStocks';
import { CompareDrawer } from 'src/components/Product/compareDrawer';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';
import { useLocation, useNavigate } from 'react-router-dom';
import { fetchDealersByCity } from 'src/redux/reducers/dealerReducer';
import dealerByCity from 'src/api/dealersbyCity';
import { updateDealer } from 'src/redux/reducers/cityListReducer';
import Cookies from 'js-cookie';

interface LocationState {
    from: {
        pathname: string;
    };
}

export const Buy = (props: any) => {
    const location = useLocation();
    const navigate = useNavigate();
    let model = "";
    let price = "";

    if (location?.state && typeof location.state === 'object' && 'model' in location.state && 'price' in location.state) {
        model = (location.state as { model: string }).model;
        price = (location.state as { price: string }).price;
        //  navigate('', { replace: true });
    }
    const scrollRef = useRef<HTMLDivElement>(null);
    const token = localStorage.getItem("token")!;
    const compareDrawer = useDisclosure();
    const toast = useToast();
    const maxLimit = useAppSelector((state: any) => state.commonData.maxLimit);
    const defaultcity = useAppSelector((state: any) => state.cities.cityId);
    // const defaultcity = Cookies.get('cityId');

    //const dealer = useAppSelector((state: any) => state.cities.dealer);
    const dealer = Cookies.get('dealer');
    const dispatch = useAppDispatch();
    //const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    const includesSubDomain = localStorage.getItem('includesSubDomain');
    const modelValue = useAppSelector((state: any) => state.commonData.modelValue);
    const priceValue = useAppSelector((state: any) => state.commonData.priceValue);
    const sortValue = useAppSelector((state: any) => state.commonData.sortOrder);
    const [activeTab, setActiveTab] = useState<string>('allAudiCars');
    const dealerList = useAppSelector((state: any) => state.dealer.listByCity.data);
    const [multipleDealers, setMultipleDealer] = useState<boolean>(false);
    const [show, setShow] = useState<Boolean>(false);
    const [loading, setLoading] = useState<boolean>(false)
    const [payload, setPayload] = useState<any>({
        per_page: 6,
        page: 1,
        sort_order: sortValue ? sortValue : "",
        filter_certified_status: false,
        filter_city: defaultcity ? defaultcity : "",
        filter_model: modelValue ? modelValue : "",
        filter_make: "AUDI",
        filter_non_audi: false,
        filter_body_type: "",
        filter_min_price: "",
        filter_max_price: priceValue ? priceValue : "",
        filter_min_year: "",
        filter_max_year: "",
        filter_min_km: "",
        filter_max_km: "",
        filter_fuel_type: "",
        filter_transmission_type: "",
        lead_status: "13",
        platform: process.env.REACT_APP_PLATFORM_ID,
        dealer_id: includesSubDomain ? dealer : "",
        is_subdomain: includesSubDomain == "true" ? true : false
    });
    const [product, setProduct] = useState<object[]>([]);
    const [stocks, setStocks] = useState<{
        [key: string]: any;
    }>({});
    const [compareText, setCompareText] = useState<string>("Add to compare");
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
    const [resetRange, setResetRange] = useState<any>(false);
    const [comparePayload, setComparePayload] = useState<object[]>([]);

    // <
    //     Array<{
    //         lead_id: any,
    //         imageUrl: string,
    //         yearOfManufacture: any,
    //         make: string,
    //         model: string,
    //         variant: string
    //     }>
    // >([]);

    const defaultPayload = {
        per_page: 6,
        page: 1,
        sort_order: "",
        filter_certified_status: activeTab && activeTab==="certifiedAudiCars"? true:false,
        filter_city: defaultcity ? defaultcity : "",
        filter_model: "",
        filter_make: activeTab && activeTab==="otherBrandCars" ? "":"AUDI",
        filter_non_audi: activeTab && activeTab==="otherBrandCars" ? true:false,
        filter_body_type: "",
        filter_min_price: "",
        filter_max_price: "",
        filter_min_year: "",
        filter_max_year: "",
        filter_min_km: "",
        filter_max_km: "",
        filter_fuel_type: "",
        filter_transmission_type: "",
        lead_status: "13",
        platform: process.env.REACT_APP_PLATFORM_ID,
        dealer_id: includesSubDomain ? dealer : "",
        is_subdomain: includesSubDomain ? true : false

    }
    const lsDealerId = localStorage.getItem('dealerId');
    let count = useMemo(() => {
        return comparePayload.length
    }, [comparePayload]);

    useEffect(() => {
        if (scrollRef.current) {
            scrollRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, []);



    //   useEffect(()=>{

    //     let newPayload = { ...payload };
    //     newPayload.dealer_id = lsDealerId;
    //     setPayload(newPayload);
    // },[lsDealerId]);

    useEffect(() => {
        if (includesSubDomain == "true" && dealer) {
            let newPayload = { ...payload };
            newPayload.dealer_id = dealer;
            setPayload(newPayload);
        }
    }, [includesSubDomain]);

    useEffect(() => {
        if (token) {
            setLoading(true);
            let res = fetchStocks(payload);
            res.then((data: any) => {
                if (payload.page > 1) {
                    setProduct(product.concat(data.data));
                    setStocks(data);
                }
                else {
                    setStocks(data);
                    setProduct(data.data);
                }
                setLoading(false);
            })
        }
    }, [payload]);

    useEffect(() => {
        (async () => {
            if (defaultcity) {
                let newPayload = { ...payload };
                newPayload.filter_city = defaultcity;
                newPayload.page = 1;
                if (includesSubDomain == "true") {
                    newPayload.dealer_id = dealer;
                }
                else {
                    await dispatch(fetchDealersByCity(defaultcity));
                    const res = await dealerByCity(defaultcity);
                    const newDealers = res.data;
                    if (newDealers && newDealers.length > 1) {
                        // if(dealer){
                        //     console.log("if inside",dealer ) ;  
                        //     newPayload.dealer_id = dealer
                        //     dispatch(updateDealer(dealer));
                        // }               
                        // else{
                        newPayload.dealer_id = "";
                        dispatch(updateDealer(""));
                        // }
                    }
                    else if (newDealers && newDealers.length === 1) {
                        const [singleDealer] = newDealers;
                        newPayload.dealer_id = "";
                        dispatch(updateDealer(singleDealer.id));
                    }
                }
                setPayload(newPayload);
            }
        })();

    }, [defaultcity]);

    useEffect(() => {
        token && dispatch(fetchModelList("Audi"));
    }, [token]);

    const showFilter = () => {
        setShow(!show)
    }

    const handleResetRange = () => {
        setResetRange(!resetRange);
    }

    // useEffect(() => {
    //   updateStocks("buyBar", [model, price]); 
    // }, [model,price]);


    const updateStocks = (type: any, value: any) => {
        let newPayload = { ...payload };
        newPayload.page = 1;
        switch (type) {
            case 'certified':
                newPayload.filter_certified_status = value
                setPayload(newPayload);
                break;
            case 'city':
                newPayload.filter_city = value;
                newPayload.dealer_id = "";
                setPayload(newPayload);
                break;
            case 'make':
                newPayload.filter_make = value
                setPayload(newPayload);
                break;
            case 'model':
                newPayload.filter_model = value
                setPayload(newPayload);
                break;
            case 'bodyType':
                newPayload.filter_body_type = value
                setPayload(newPayload);
                break;
            case 'priceRange':
                newPayload.filter_min_price = value[0]
                newPayload.filter_max_price = value[1]
                setPayload(newPayload);
                break;
            case 'year':
                newPayload.filter_min_year = value[0]
                newPayload.filter_max_year = value[1]
                setPayload(newPayload);
                break;
            case 'fuelType':
                newPayload.filter_fuel_type = value
                setPayload(newPayload);
                break;
            case 'transmissionType':
                newPayload.filter_transmission_type = value
                setPayload(newPayload);
                break;
            case 'kmRange':
                newPayload.filter_min_km = value[0]
                newPayload.filter_max_km = value[1]
                setPayload(newPayload);
                break;
            case 'sortOrder':
                newPayload.sort_order = value
                setPayload(newPayload);
                break;
            case 'buyBar':
                newPayload.filter_model = value[0]
                newPayload.filter_max_price = value[1]
                setPayload(newPayload);
                break;
            case 'dealer':
                newPayload.dealer_id = Number(value);
                setPayload(newPayload);
                break;
            case 'tabChange':
                newPayload.filter_make = value[0];
                newPayload.filter_non_audi = value[1];
                newPayload.filter_certified_status = value[2];
                newPayload.filter_model = value[3]
                setPayload(newPayload);
                break;
            case 'reset':
                setPayload(defaultPayload);

                break;

            default:
                return setPayload(defaultPayload);
        }

    }

    const handleRemoveCar = (payload: any) => {

        //@ts-ignore
        const newComparePayload = comparePayload.filter((item, i) => item.lead_id !== payload.lead_id);
        setComparePayload(newComparePayload);
        product.map((item: any) => {
            if (item.lead_id === payload.lead_id) {
                item.btnDisabled = false;
                item.compareText = "Add to Compare";
            }
        })

        //  setBtnDisabled(false);
        //  setCompareText("Add to Compare");
    }
    const handleCompare = (item: any) => {

        if (count && count >= maxLimit) {
            toast({
                title: `Only ${maxLimit} Cars are allowed to Compare.`,
                variant: 'left-accent',
                isClosable: true,
                position: 'bottom-right'
            })
            compareDrawer.onOpen();
        }
        else {

            if (!item.btnDisabled) {

                item.btnDisabled = true;
                item.compareText = "Added";
            }
            // let newPayload: any = {
            //     leadId: "",
            //     imageUrl: "",
            //     yearOfManufacture: "",
            //     make: "",
            //     model: "",
            //     variant: ""
            // };
            // newPayload.leadId = item.lead_id;
            // newPayload.imageUrl = item.image_url
            // newPayload.yearOfManufacture = item.year_of_manufacture
            // newPayload.make = item.make
            // newPayload.model = item.model
            // newPayload.variant = item.variant
            setComparePayload([...comparePayload, { ...item }]);
            compareDrawer.onOpen();
        }
    }

    const handloadMore = () => {

        if (stocks.currentPage < stocks.lastPage) {
            let newPayload = { ...payload };
            newPayload.page = newPayload.page + 1;
            setPayload(newPayload);
        }
        else {
            toast({
                title: `you have reached to the end of results!`,
                variant: 'left-accent',
                isClosable: true,
                position: 'bottom-right'
            })
        }

    }
    return (
        <>
            <Box ref={scrollRef} bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb={{ base: 10, xl: 25 }}>
                <BuyHeader showFilter={showFilter} stocks={stocks} updateStocks={updateStocks} />
                <Grid templateColumns={{ xl: "344px 1fr" }} gap={15}>
                    <Filter updateStocks={updateStocks} show={show} showFilter={showFilter} stocks={stocks} resetRange={resetRange} handleResetRange={handleResetRange} model={model} price={price} loading={loading} activeTab={activeTab} setActiveTab={setActiveTab} />
                    <ProductsList product={product} handleCompare={handleCompare} handloadMore={handloadMore} updateStocks={updateStocks} loading={loading} handleResetRange={handleResetRange} />
                </Grid>
            </Box>
            <CompareDrawer open={compareDrawer.isOpen} close={compareDrawer.onClose} payload={comparePayload} handleRemoveCar={handleRemoveCar} maxLimit={maxLimit} />
        </>
    )
}

