import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { CITY_API } from "src/constants";
import Cookies from 'js-cookie';


const params = {
    "for": "city",
    "platform": process.env.REACT_APP_PLATFORM_ID
}

export const fetchCityList = createAsyncThunk(
    "city/fetchCityList",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .get(
                `${CITY_API}/${process.env.REACT_APP_PLATFORM_ID}`,
               // params,
                {
                    headers: headers
                }
            )
            .then((response) => response.data.data)
            .catch((error) => error)
        return res
    }
);


const LSCityId = Number(Cookies.get('cityId'));
const LSCityName = Cookies.get('city');
const LSdealer = Number(Cookies.get('dealer'));

const cityInitialState = {
    status: "idle",
    data: [],
    popularCities: [],
    error: {},
    openModal: LSCityId ? false : true,
    cityId: LSCityId ? LSCityId : null,
    city: LSCityName? LSCityName :null,
    dealer:LSdealer?LSdealer:null
};

const cityListSlice = createSlice({
    name: "cityList",
    initialState: cityInitialState,
    reducers: {
        openCityModal: (state, action) => {
            state.openModal = action.payload;
        },
        updateCityId: (state, action) => {
            state.cityId = action.payload;
            Cookies.set('cityId', action.payload, { expires: 1 });
        },
        updateCity: (state, action) => {
            state.city = action.payload;
            Cookies.set('city', action.payload, { expires: 1 });
        },
        updateDealer: (state, action) => {
            state.dealer = action.payload;
            Cookies.set('dealer', action.payload, { expires: 1 }); 
        },
    },
    extraReducers: {
        [fetchCityList.pending.type]: (state) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchCityList.fulfilled.type]: (state, action: any) => {
            state.status = "success"
            state.data = action.payload
            const popular = action.payload.filter((city:any) => city.popular_cities === 1)
            .sort((a:any, b:any) => a.city_name.localeCompare(b.city_name)); 
            //const popular = action.payload.slice(0, 6)
            state.popularCities = popular;
            state.error = {}
        },
        [fetchCityList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export const { openCityModal, updateCityId,updateCity ,updateDealer} = cityListSlice.actions;
export default cityListSlice.reducer;