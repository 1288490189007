import { FunctionComponent, FormEvent, useState, useEffect } from 'react'
import { FormControl, FormLabel, Select } from '@chakra-ui/react';
import {
    useAppDispatch,
    useAppSelector
} from 'src/redux/hooks';
import AutoComplete from '../AutoComplete';
import { updateCity, updateCityId, updateDealer } from 'src/redux/reducers/cityListReducer';
import { AnyMap } from 'immer/dist/internal';
import { updateFCity, updatePage, updatePreviousData } from 'src/redux/reducers/filterBuyReducer';
import { fetchDealersByCity } from 'src/redux/reducers/dealerReducer';

const styles = {
    formControl: {
        mb: 5
    },
    legend: {
        color: 'red',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        maxHeight: 200,
        overflowY: "scroll",
        mb: 4
    }
}

interface IFcity {
    updateStocks?: (type: any, value: any) => void
}
const FCity = ({ updateStocks }: IFcity) => {
    const dispatch = useAppDispatch()
    const cityList = useAppSelector((state: any) => state.cities.data)
    const defaultcity = useAppSelector((state: any) => state.cities.cityId);
    const cityName = useAppSelector((state: any) => state.cities.city);
    const fcity = useAppSelector((state: any) => state.buyFilterData.city);
    const dealer = useAppSelector((state: any) => state.cities.dealer);
    // const [city, setCity] = useState<any>();
    const dataCities: object[] = []
    cityList.filter((item: any) => dataCities.push({ id: item.city_id, name: item.city_name }));
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    const dealerList = useAppSelector((state: any) => state.dealer.listByCity.data);
    const [multipleDealers, setMultipleDealer] = useState<boolean>(false);

    useEffect(() => {
        if (dealerList && dealerList.length > 1) {
            setMultipleDealer(true);
            // dispatch(updateDealer(""));

        }
        else if (dealerList && dealerList.length === 1) {
            // const [singleDealer] = dealerList;
            // dispatch(updateDealer(singleDealer.id));
            setMultipleDealer(false);
        }
    }, [dealerList]);

    const handleSubmit = (e: any) => {
        // dispatch(updatePriceRange(id))
        // setCity(e.target.value);

        dispatch(updateCityId(e.target.value));
        dispatch(updateCity(e.target.selectedOptions[0].text));
        // dispatch(updateDealer(""));
        dispatch(fetchDealersByCity(e.target.value));

        // dispatch(updateFCity(e.target.value));
        // dispatch(updatePage(1));
        updateStocks && updateStocks("city", e.target.value);
    }

    const handleSubmitDealer = (e: any) => {
        dispatch(updateDealer(e.target.value));
        updateStocks && updateStocks("dealer", e.target.value);
    }

    return (
        <>
            <FormControl sx={styles.formControl}>
                <FormLabel sx={styles.legend}>City</FormLabel>
                {includesSubDomain ?
                    <Select placeholder='Select City' variant='mySelect' >
                        {
                            cityList.length !== 0 ? cityList.filter((item: any) => item.city_id === defaultcity).map((item: any) => (<option
                                selected={item.city_id == defaultcity}
                                key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                        }
                    </Select>
                    :
                    <Select placeholder='Select City' onChange={handleSubmit} variant='mySelect'>
                        {
                            cityList.length !== 0 ? cityList.map((item: any) => (<option
                                selected={item.city_id == defaultcity}
                                key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                        }
                    </Select>
                }
            </FormControl>
            {multipleDealers && !includesSubDomain && <FormControl sx={styles.formControl}>
                <FormLabel sx={styles.legend}>Dealer</FormLabel>
                <Select placeholder='Select Dealer' onChange={handleSubmitDealer} variant='mySelect'>
                    {
                        dealerList.length !== 0 ? dealerList.map((item: any) => (<option
                            selected={item.id == dealer}
                            key={item.id} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                    }
                </Select>
            </FormControl>}
        </>
    );
}

export default FCity;