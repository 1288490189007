import React from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useAppSelector, useAppDispatch } from 'src/redux/hooks';
import {
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Button,
    useDisclosure,
    List,
    ListItem,
    Link,
    useMultiStyleConfig,
    Box,
    DrawerHeader,
    Flex,
    Spacer, Image,
    ListIcon, Text
} from "@chakra-ui/react";
import { IoClose } from "react-icons/io5";
import { CiLocationOn } from "react-icons/ci";
import { ChevronDownIcon } from '@chakra-ui/icons';
import { openCityModal } from 'src/redux/reducers/cityListReducer';
import smoothscroll from 'smoothscroll-polyfill';


import {
    ChevronRightIcon,
    HamburgerIcon
} from "@chakra-ui/icons"

const MNav = () => {
    const styles = useMultiStyleConfig('Nav')
    const styless = useMultiStyleConfig(`Header`)
    const nav = useAppSelector((state: any) => state.commonData.nav)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const city = useAppSelector((state: any) => state.cities.city);
    const dispatch = useAppDispatch();
    const location = useLocation();
    const openModal = () => {
        onClose();
        dispatch(openCityModal(true));
    }

    const handleClickLink = () => {
        onClose();
        window.scroll({
            top: 0,
            left: 0,
            behavior: "smooth"
        })
        return false
    }
    return (
        <>
            <Button onClick={onOpen}
                sx={styles.hamBtn}
                variant='blank'>
                <HamburgerIcon w={6} h={6} sx={styles.hamBtnIcon} />
                <Box sx={styles.hamBtnText}>Menu</Box>
            </Button>
            <Drawer
                isOpen={isOpen}
                placement="left"
                onClose={onClose}
                size={'xl'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerHeader>
                        <Flex>
                            <Box >
                                <Link
                                    as={RouterLink}
                                    to="/"
                                    title={`${process.env.REACT_APP_NAME}`}
                                    sx={{ ...styless.logoLink }}
                                    onClick={onClose}
                                >
                                    <Image
                                        src={`/images/${process.env.REACT_APP_THEME}/${process.env.REACT_APP_LOGO_FILE}`}
                                        alt={`${process.env.REACT_APP_NAME}`}
                                        sx={{ ...styless.logo }}
                                        pt={"5px"}
                                    />
                                </Link>
                            </Box>
                            <Spacer />
                            <Box >
                                <Button leftIcon={<IoClose color="white" size={20} />} size="sm" bg='red' borderRadius={"5px"} onClick={onClose}>
                                    Menu
                                </Button>
                            </Box>
                        </Flex>
                    </DrawerHeader>
                    <DrawerBody>
                        <List display={'flex'} flexDirection='column' spacing={3}>
                            {nav.map((item: any) => (
                                <ListItem key={item.id} p={1}>
                                    <Link as={RouterLink} to={item.href} onClick={handleClickLink} className={location.pathname === item.href ? 'active' : ''}>
                                        <Flex>
                                            <Box>
                                                {item.title}
                                            </Box>
                                            <Spacer />
                                            <Box >
                                                <ListIcon as={ChevronRightIcon} boxSize={5} />
                                            </Box>
                                        </Flex>
                                    </Link>
                                </ListItem>
                            ))}
                            <ListItem onClick={() => openModal()} pt={10}>
                                <Flex>
                                    <CiLocationOn size={20} />
                                    <Box>
                                        {city ? <Text as="p" mx="2">{city}</Text> : <Text as="p" mx={2}>Select City</Text>}
                                    </Box>
                                    <ChevronDownIcon boxSize={6} />
                                </Flex>
                            </ListItem>
                        </List>

                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    );
};
export default MNav;
