import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Grid,
    Flex,
    Button,
} from "@chakra-ui/react";
import FetchVarientList from 'src/api/getVariant';
import { useAppSelector } from 'src/redux/hooks';

export default function SliderVariant({ setVariant, sliderStep, nextSliderStep, year, make, model }: any) {
    const token = useAppSelector((state: any) => state.ibbToken.token)
    const [varientList, setVarientList] = useState<object[]>([]);
    useEffect(() => {
        const res = FetchVarientList(token, make, model, year);
        res.then((data: any) => setVarientList(data))
    }, []) // eslint-disable-line

    const handleSubmit = (values: any) => {
        setVariant(values)
        nextSliderStep();
    };

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT VARIANT
            </Text>
            <Box overflowY={"scroll"}>
                <Grid templateColumns="repeat( 1fr)" gap="8px">
                    {varientList.map((item:any, index): any => (
                        <Button
                            variant={"listBtn"}
                            key={index}
                            onClick={() => handleSubmit(item)}
                        >
                            {item}
                        </Button>
                    ))}
                </Grid>
            </Box>
        </Flex>
    );
}