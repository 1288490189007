import ibbApi from "src/api/ibbApi";
import { MAKE_ENQUIRY } from "src/constants";

export const customerInterest = (payload: any)=>{
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                MAKE_ENQUIRY,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => response.data)
            .catch((error) => error)
        return res
    
}