import React from 'react'
import {Icon}  from '@chakra-ui/react'
const UserIcon = (props:any) => (
    <Icon viewBox='0 0 16 16' {...props}>
      <path
        fill='currentColor'
        d='M2.7,15c0-2.9,2.4-5.3,5.3-5.3c2.9,0,5.3,2.4,5.3,5.3H2.7z M8,9C5.8,9,4,7.2,4,5s1.8-4,4-4s4,1.8,4,4  S10.2,9,8,9z'
      />
    </Icon>
  )

  export default UserIcon;