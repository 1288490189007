import oemApi from "./oemApi";
import { DEALER_REQUEST_API ,  DEALERLIST_API_CITY} from "src/constants"
import ibbApi from "./ibbApi";

const dealerByCity = (cityId: any) => {
    const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
    let headers: any = {};
    if (LSToken) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LSToken.token}`
        }
    }
    const params = {
        "city": cityId,
        "platform": process.env.REACT_APP_PLATFORM_ID
    }
    
    const res= ibbApi
        .get(
            `${DEALERLIST_API_CITY}?platform=${process.env.REACT_APP_PLATFORM_ID}&city=${cityId}`,
            //params,
            {
                headers: headers
            }
        )
        .then((response) => 
        {
            return response.data}
            )
        .catch((error) => error)
        return res
};
export default dealerByCity