// import React from 'react';

import { BrowserRouter, Routes, Route } from "react-router-dom";
import App from "./App";
import BuyPage from "./routes/buy";
import HomePage from "./routes/home";
import ExchangePage from "./routes/exchange";
import EvalutionPage from "./routes/exchange";
import DealerPage from "./routes/dealers"
import ProductPage from './routes/product';
import DealerDetailsPage from './routes/dealerDetails';
import SellPage from './routes/sell'
import { About } from "./routes/about";
import { TermsAndConditions } from "./routes/termsAndConditions";
import { PrivacyPolicy } from "./routes/privacy";
import { ProductDetails } from "./routes/ProductDetails";
import { Compare } from "./routes/compare";
import { Buy } from "./routes/buyPage";


import React from 'react'
import { PageNotFound } from "./routes/pageNotFound";
import ValuationAppDownload from "./routes/valuationAppDownload";
import SalesConsultantAppDownload from "./routes/salesConsultantAppDowload";

export const AppRouter = () => {
  return (
    <BrowserRouter>
    <Routes>
      <Route element={<App />}>
        <Route index element={<HomePage />} />
        <Route path="buy" element={<Buy/>} />
        {
          process.env.REACT_APP_PLATFORM_ID && Number(process.env.REACT_APP_PLATFORM_ID) === 1 ? (
            <>
              <Route path="exchange" element={<ExchangePage />} />
              <Route path="evalution" element={<EvalutionPage />} />
            </>
          ) : < Route path="sell" element={<SellPage />} />
        }
        <Route path="dealers" element={<DealerPage />} />
        <Route path="vehicle-details/:id" element={<ProductDetails/>} />
        <Route path="dealer-details/:id" element={<DealerDetailsPage />} />
        <Route path="about" element={<About />} />
        <Route path="tnc" element={<TermsAndConditions />} />
        <Route path="privacy-policy" element={<PrivacyPolicy />} />
        <Route path="compare-cars/*" element={<Compare />} />
        <Route  element={<PageNotFound/>} />
        <Route path="valuation_app_download" element={<ValuationAppDownload />} />
        <Route path="sales_consultant_app_download" element={<SalesConsultantAppDownload />} />
      </Route>
    </Routes>
  </BrowserRouter>
  )
}



