import React, { FunctionComponent, ChangeEvent, useState } from 'react'
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Text,
    FormControl,
    Input,
    FormLabel,
    FormHelperText,
    FormErrorMessage,
    Center,
    useToast,
    HStack,
    VStack,
    PinInput,
    PinInputField
} from '@chakra-ui/react'
import UserIcon from '../icons/User';
import UserLoginCheck from 'src/api/user_logincheck'
import FetchSendSMS from 'src/api/sendSMS';
import FetchVerifySMS from 'src/api/verifySMS';
import { fetchUserData } from 'src/redux/reducers/userReducer'
import { useAppDispatch } from 'src/redux/hooks';
import ButtonGroup from '../ButtonGroup'
import RegisterModal from './RegisterModal';
const styles = {
    title: {
        color: '#343a40',
        fontSize: '20px',
        fontWeight: 'semibold',
        mb: '40px',
        textTransform: 'uppercase'
    },
    formControl: {
        mb: '60px'
    }
}
const LodginModal: FunctionComponent = () => {
    const dispatch = useAppDispatch()
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [mobileNo, setMobileNo] = useState<string>("")
    const [active, setActive] = useState<string>("user")
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false)
    const [OTPBtnLoading, setOTPBtnLoading] = useState<boolean>(false)
    const [hideOTPBtn, setHideOTPBtn] = useState<boolean>(false)
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false)
    // const [OTP, setOTP] = useState<string>("")
    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        const re = /^[0-9\b]+$/;
        if (e.target.value === '' || re.test(e.target.value)) {
            setMobileNo(e.target.value)
        }
    }

    const sendSMS = () => {
        const res = FetchSendSMS(mobileNo)
        res.then((data: any) => {
            // setOTP(data.otp)
            toast({
                title: `Send OTP to this ${mobileNo} mobile number. ${data.otp}`,
                status: 'success',
                duration: 3000,
                isClosable: true,
            })
        })
    }
    const sendOTP = () => {
        if (mobileNo.length > 10 || mobileNo.length < 10) {
            setInvalidMobile(true)
        } else {
            setInvalidMobile(false)
            setOTPBtnLoading(true)
            const fetchLoginCheck = UserLoginCheck(mobileNo, active)
            fetchLoginCheck.then((data: any) => {
                setOTPBtnLoading(false)
                if (data.status === "success") {
                    setHideOTPBtn(true)
                    sendSMS()
                } else {
                    toast({
                        title: data.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
        }
    }
    const handleSubmit = (value: string) => {
        setSubmitBtnLoading(true)

        const res = FetchVerifySMS(mobileNo, value)
        res.then((data: any) => {
            if (data.status === "success") {
                const params = { mobileNo, active }
                dispatch(fetchUserData(params))
                setSubmitBtnLoading(false)
                toast({
                    title: "Successfully logged in.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                })
                onClose()
            } else {
                toast({
                    title: data.message,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,
                })
                setSubmitBtnLoading(false)
            }
        })

    }
    const tab = (value: string) => {
        setActive(value)
    }
    const handleFormSubmit = (e: any) => {
        e.preventDefault()
        // const inputArr = e.target.otpInput
        // inputArr.reduce((total,curr)=>)
        // handleSubmit()
    }
    return (<>
        <Button
            fontSize={'sm'}
            fontWeight={'normal'}
            variant="blank"
            pr={'0'}
            rightIcon={<Box as='span'
                bg='white'
                borderRadius='50%'
                boxShadow='0 8px 12px 0 rgba(1, 36, 19, 0.12)'
                p='8px'
            ><UserIcon color='secondary' /></Box>}
            onClick={onOpen}
        >Login</Button>
        <Drawer
            isOpen={isOpen}
            placement="right"
            onClose={onClose}
            size={'lg'}
        >
            <DrawerOverlay />
            <DrawerContent>
                <DrawerCloseButton />
                <DrawerBody p={["20px", "60px"]}>

                    <Box mb={"60px"}>
                        <ButtonGroup data={['user', 'exchange partners']} active={active} callback={tab} />
                    </Box>
                    <Center><Text as="h3" sx={styles.title}>Login {active}</Text></Center>
                    <Box mb="56px">
                        <form onSubmit={handleFormSubmit}>
                            <FormControl id="mobile-no" isRequired sx={styles.formControl} isInvalid={invalidMobile}>
                                <Input placeholder="Enter Mobile No" size={'lg'} value={mobileNo} onChange={validateMobileNo} type="tel" />
                                <FormHelperText>OTP will send to this mobile number</FormHelperText>
                                <FormErrorMessage>Your Mobile No. is invalid</FormErrorMessage>
                            </FormControl>

                            <VStack hidden={!hideOTPBtn} mb="50px">
                                <FormLabel>Enter OTP</FormLabel>
                                <HStack>
                                    <PinInput onComplete={handleSubmit}>
                                        <PinInputField name="otpInput" />
                                        <PinInputField name="otpInput" />
                                        <PinInputField name="otpInput" />
                                        <PinInputField name="otpInput" />
                                        <PinInputField name="otpInput" />
                                        <PinInputField name="otpInput" />
                                    </PinInput>
                                </HStack>
                                <Button variant={"blank"} onClick={sendSMS}>Re-send OTP</Button>
                            </VStack>
                            <Center>
                                <Button
                                    type='submit'
                                    variant={'primary'}
                                    size="lg"
                                    isLoading={OTPBtnLoading}
                                    loadingText='Sending OTP'
                                    onClick={sendOTP}
                                    hidden={hideOTPBtn}
                                >Send OTP</Button>
                                <Button
                                    type='submit'
                                    variant={'primary'}
                                    size="lg"
                                    isLoading={submitBtnLoading}
                                    loadingText='Submitting'
                                    hidden={!hideOTPBtn}
                                >Submit</Button>
                            </Center>
                        </form>
                    </Box>
                    <RegisterModal user={active} />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    </>);
}

export default LodginModal;