
// export const IBB_API_URL = "https://cors-anywhere.herokuapp.com/https://coreibbtractor.com"
// export const IBB_API_LOGIN_USERNAME = "Swaraj"
// export const IBB_API_LOGIN_PASSWORD = "SJ7Q!T7@cWs"
// export const GOOGLE_ANALYTICS = "UA-XXXXX-Y"
// export const STATE_API = "/api/swaraj/getState"
// export const CITY_API = "/api/swaraj/getCities"     
// export const HOME_DETAILS_API = "/api/swaraj/home_details"
// export const ADD_VALUTION_API = "/api/swaraj/addleads"
// export const SEND_SMS_API = "/api/oem/send_web_sms"
// export const VERIFY_SMS = "/api/oem/verify_sms"
// export const DISTRICT_API = "/api/swaraj/getDistrict"
// export const STOCK_LISTING_API = "api/swaraj/stock_listing"
// export const IMAGE_LIST_API = "api/swaraj/image_list"
// export const ADD_REGISTER_API = "api/swaraj/register"
// export const ADD_REGISTERBROKER_API = "api/swaraj/registerbroker"
// export const USER_LOGIN_API = "api/swaraj/user_login"
// export const BROKER_LOGIN_API = "api/swaraj/broker_login"
// export const USER_LOGINCHECK_API = "api/swaraj/user_logincheck"
// export const BROKER_LOGINCHECK_API = "api/swaraj/broker_logincheck"
// export const BUY_USED_CAR_API = "api/swaraj/buy_used_car"
// export const ADD_CART_API = "api/swaraj/add_cart"
// export const REMOVE_CART_API = "api/swaraj/remove_cart"
// export const CART_LIST_API = "api/swaraj/cart_list"
// export const BROKER_BUY_API = "api/swaraj/broker_buy"
// export const UPDATE_EXCHANGE_API = "api/swaraj/updateleads"
// export const CITYNAME_API = "api/swaraj/getCityname"
// export const DEALER_DISTRICT_API = "api/swaraj/getDistrict_dealer"
// export const DEALERLIST_API = "api/swaraj/dealerlist"
// export const DEALER_DETAILS_API = "api/swaraj/dealer_details"
// export const DEALER_REQUEST_API = "api/swaraj/dealer_request"
// export const DEALER_LISTING_API = "api/swaraj/dealer_listing"
// export const DEALER_BROKER_BUY_API = "api/swaraj/dealer_broker_buy"

export const NAV_DATA = Number(process.env.REACT_APP_PLATFORM_ID) === 1 ? [
    {
        id: 1,
        title: "Home",
        href: "/",
    },
    {
        id: 2,
        title: "Buy Used Tractor",
        href: "/buy",
    },
    {
        id: 3,
        title: "Exchange",
        href: "/exchange",
    },
    {
        id: 4,
        title: "Tractor Valuation",
        href: "/evalution",
    },
    {
        id: 5,
        title: "Certified Dealers",
        href: "/dealers",
    },
    {
        id: 6,
        title: "News & Updates",
        href: "#",
    },
    {
        id: 7,
        title: "Contact Us",
        href: "/contact",
    },
] : [
    {
        id: 1,
        title: "Home",
        href: "/",
    },
    {
        id: 2,
        title: "Buy",
        href: "/buy",
    },
    {
        id: 3,
        title: "sell",
        href: "/sell",
    },
    {
        id: 4,
        title: "Dealership Locator",
        href: "/dealers",
    },
]


export const ACCESS_TOKEN = 'get-access-token'
export const GET_DEALER = 'audi/dealer'
export const GET_CITY_SELL = 'audi/get_city_of_dealers'
export const GET_COLOR = 'audi/color'
export const GET_OWNER = 'audi/owner'
export const GET_MODEL = 'audi/model'
export const GET_MODEL_LOCAL = 'get_model'
export const GET_MODEL_AD_SELL_LOCAL = 'get_ad_sell_model'
export const GET_MAKE = 'audi/make'
export const GET_CITY = 'audi/get_city'
export const GET_STATE = 'audi/get_state'
export const GET_PRICE_BUY = 'audi/get_price_buy'
export const GET_KMS_MIN = 'audi/get_kms_min'
export const GET_KMS_MAX = 'audi/get_kms_max'
export const GET_VARIANT = 'audi/variant'
export const GET_VARIANT_LOCAL = 'get_variant'
export const POST_SELL = 'audi/contact_us_sell'
export const CONTACTUS_BUY = 'audi/contact_us_buy'
export const CONTACTUS_BUY_LOCAL = 'contact_us_buy'
export const BOOK_ONLINE_LOCAL = 'book_online'
export const BOOK_ONLINE = 'audi/book_online'
export const POSTIMGUPLOAD = 'audi/images_upload'
export const POSTIMGUPLOAD_LOCAL = 'sell_inquiry'
export const ADPOSTIMGUPLOAD = 'audi/images_ad_upload'
export const ADBUYPOSTIMGUPLOAD = 'audi/images_buy_ad_upload'
export const AD_POSTIMGUPLOAD = 'audi/images_upload_ad'
export const POSTIMGUPLOAD_SELL_AD_LOCAL = 'sell_adinquiry'
export const POSTIMGUPLOAD_SELL_BUY_LOCAL = 'buy_adinquiry'
export const SEARCH_BUY = 'audi/buy_search'
export const SEARCH_BUY_LOCAL = '/get-searched-cars'
export const GALLERY_BUY = 'audi/buy_gallery'
export const VIDEO_BUY = 'audi/buy_video'
export const ABOUTCAR_BUY = 'audi/about_car'
export const SEARCH_DEALER = 'search_dealer'
export const SEARCHDEALERAPI = 'audi/search_dealer'
export const GET_BANNER = 'audi/get_banner'
export const SEARCH_COMPARE = 'audi/search_compare'
export const CHECK_AUDI_SHOP_EXP_TIME = 'audi/check_stock_exp_time'
export const GET_URL_LOCATION = 'audi/get_url_location'
export const GET_DEALER_BY_CITY = 'audi/dealer_by_city'
export const BROKER_LOGIN_API = "broker_login"
export const USER_LOGIN_API = "user_login"
export const STATE_API = "/get-states"
export const IMAGE_LIST_API = "image_list"
export const STOCK_LISTING_API = "stock_listing"
export const HOME_DETAILS_API = "home_details"
export const DEALER_DETAILS_API = "dealer_details"
export const DEALER_REQUEST_API = "dealer_request"
export const DEALER_LISTING_API = "dealer_listing"
export const CITY_API = "/get-dealers-cities"
export const SEND_SMS_API = "oem/send_web_sms"
export const VERIFY_SMS = "oem/verify_sms"
export const BROKER_LOGINCHECK_API = "broker_logincheck"
export const ADD_REGISTER_API = "register"
export const ADD_REGISTERBROKER_API = "registerbroker"
export const BUY_USED_CAR_API = "buy_used_car"
export const USER_LOGINCHECK_API = "user_logincheck"
export const SAVE_CUSTOMER_INTEREST = "/save-customer-interest"
export const GET_YEAR = '/get-buy-constant'
export const DEALERLIST_API = "/get-state-dealers"
export const DEALERLIST_API_CITY = "/get-city-dealers"
export const SAVE_LEAD = "/save-procurement-lead"
export const GET_PRICE = "/get-price"
export const GET_KMS = "/get-kms"
export const GET_CONSTANT = '/get-buy-constant'
export const GET_PRODUCT = "/get-car-details"
export const SAVE_ENQUIRY = "/save-sales-enquiry"
export const COMPARE = "/get-car-comparison"
export const MAKE_ENQUIRY = "/save-customer-interest"
export const SUBDOMAIN = "/get-subdomain"
export const DEALER_DETAIL ="/get-dealer"