import SearchPanel from 'src/components/Home/SearchPanel';
import HomeBanner from 'src/components/Home/Banner';
import HomeNewsListing from 'src/components/Home/NewsListing';
import UsedCarList from 'src/components/Home/UsedCarList';
import BrandListing from 'src/components/Home/BrandListing';
import BudgetListing from 'src/components/Home/Budget';
import StateListing from 'src/components/Home/StateListing';
import CustomView from 'src/components/Home/CustomView';
import { SearchBar } from 'src/components/Home/searchBar';


export default function HomePage() {
   

    return (<>
        <HomeBanner />
        <SearchBar/>   
        {
            process.env.REACT_APP_HOME_PAGE_CUSTOMIZED_VIEW === "TRUE" && <CustomView />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_SEARCH === "TRUE" && <SearchPanel />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_LATEST === "TRUE" && <UsedCarList />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_BRANDSLIST === "TRUE" && <BrandListing />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_BUDGETLIST === "TRUE" && <BudgetListing />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_STATELIST === "TRUE" && <StateListing />
        }
        {
            process.env.REACT_APP_HOME_PAGE_WIDGET_NEWS === "TRUE" && <HomeNewsListing />
        }
    </>
    );
}