import React from 'react';
import { useState } from 'react';
import {
    Box,
    Text,
    Grid,
    GridItem,
} from "@chakra-ui/react";
import SliderYear from "../components/Exchange/SliderYear"
import SliderMake from '../components/Exchange/SliderMake'
import SliderModal from '../components/Exchange/SliderModal'
import SliderVariant from '../components/Exchange/SliderVariant'
import SliderHours from '../components/Exchange/SliderHours'
import SliderEngine from '../components/Exchange/SliderEngine'
import SliderTyre from '../components/Exchange/SliderTyre'
import SliderState from '../components/Exchange/SliderState'
import SliderCity from '../components/Exchange/SliderCity'
import SliderSubmit from '../components/Exchange/SliderSubmit'
import SliderInfo from '../components/Exchange/SliderInfo'

const ExchangePage: React.FC<{}> = (props) => {
    const [sliderStep, setSliderStep] = useState<number>(0);
    const nextSliderStep = () => setSliderStep((currentStep) => currentStep + 1);
    //const prevSliderStep = () => setSliderStep((currentStep) => currentStep - 1);



    const goToSlider = (index: number) => {
        setSliderStep(index)

        switch (index) {
            case 0:
                setYear(null)
                break
            case 1:
                setMake(null)
                break
            case 2:
                setModel(null)
                break
            case 3:
                setVariant(null)
                break
            case 4:
                setHours(null)
                break
            case 5:
                setCondition(null)
                break
            case 6:
                setTyreCondition(null)
                break
            case 7:
                setState(null)
                break
            case 8:
                setCity(null)
                break
            default:
                return
        }
    }

    const [getYear, setYear] = useState<number | null>();
    const [getMake, setMake] = useState<string | null>();
    const [getModel, setModel] = useState<string | null>();
    const [getVariant, setVariant] = useState<string | null>();

    let vehicle_details = {
        year: getYear,
        make: getMake,
        model: getModel,
        variant: getVariant,
    }

    const [getHours, setHours] = useState<string | null>();
    const [getCondition, setCondition] = useState<string | null>();
    const [getTyreCondition, setTyreCondition] = useState<string | null>();

    let vehicle_conditions = {
        hours: getHours,
        condition: getCondition,
        tyreCondition: getTyreCondition,
    }

    const [getState, setState] = useState<string | null>();
    const [getCity, setCity] = useState<string | null>();

    const vehicle_location = {
        state: getState,
        city: getCity,
    }

    return (
        <main style={{ padding: "1rem 0" }}>
            <title>Exchange - {process.env.title}</title>
            <Box
                as="section"
                bg={"sectionGray"}
                px={{ base: "bleed", xl: "bleed-xl" }}
                py={6}
                pt="20px"
                pb="60px"
            >
                <Text as="h1" fontSize={"1.6rem"} fontWeight={"bold"} mb="20px">
                    Exchange Valuation{" "}
                    <Text
                        as="span"
                        fontSize={"1rem"}
                        fontWeight={"semi-bold"}
                        color={"headerLinkClr"}
                    >
                        ( Get True Price of Your Used Tractor )
                    </Text>
                </Text>

                <Grid templateColumns={{ xl: "2fr 1fr" }} gap={"32px"}>
                    <GridItem>
                        <Box
                            as="main"
                            h="560px"
                            alignItems="center"
                            justifyContent="space-between"
                            borderWidth="1px"
                            borderRadius="xl"
                            overflow="hidden"
                            bg={"white"}
                            boxShadow={"0 10px 40px 0 rgb(0 0 0 / 2%)"}
                            transition={"box-shadow .2s ease-in"}
                            textDecoration={"none"}
                            p="20px"
                            pb="40px">
                            {sliderStep === 0 && (
                                <SliderYear sliderStep={sliderStep} nextSliderStep={nextSliderStep} setYear={setYear} />
                            )}
                            {sliderStep === 1 && (
                                <SliderMake sliderStep={sliderStep} nextSliderStep={nextSliderStep} setMake={setMake} year={vehicle_details.year} />
                            )}
                            {sliderStep === 2 && (
                                <SliderModal sliderStep={sliderStep} nextSliderStep={nextSliderStep} setModel={setModel} year={vehicle_details.year} make={vehicle_details.make} />
                            )}
                            {sliderStep === 3 && (
                                <SliderVariant sliderStep={sliderStep} nextSliderStep={nextSliderStep} setVariant={setVariant} year={vehicle_details.year} make={vehicle_details.make} model={vehicle_details.model} />
                            )}
                            {sliderStep === 4 && (
                                <SliderHours sliderStep={sliderStep} nextSliderStep={nextSliderStep} setHours={setHours} />
                            )}
                            {sliderStep === 5 && (
                                <SliderEngine sliderStep={sliderStep} nextSliderStep={nextSliderStep} setCondition={setCondition} />
                            )}
                            {sliderStep === 6 && (
                                <SliderTyre sliderStep={sliderStep} nextSliderStep={nextSliderStep} setTyreCondition={setTyreCondition} />
                            )}
                            {sliderStep === 7 && (
                                <SliderState sliderStep={sliderStep} nextSliderStep={nextSliderStep} setState={setState} />
                            )}
                            {sliderStep === 8 && (
                                <SliderCity sliderStep={sliderStep} nextSliderStep={nextSliderStep} setCity={setCity} state={vehicle_location.state} />
                            )}
                            {sliderStep === 9 && (
                                <SliderSubmit vehicle_details={vehicle_details} />
                            )}
                        </Box>
                    </GridItem>

                    <GridItem>
                        <SliderInfo sliderStep={sliderStep} vehicle_details={vehicle_details} vehicle_conditions={vehicle_conditions} vehicle_location={vehicle_location} goToSlider={goToSlider} />
                    </GridItem>
                </Grid>
            </Box>
        </main>
    );
}

export default ExchangePage;