import React, { FunctionComponent } from 'react'
import {
    VStack,
    Skeleton,
    Grid,
    HStack
} from '@chakra-ui/react';

const HeroLoader: FunctionComponent = () => {
    return (<>
        <Grid templateColumns={'1fr 568px'} gap={"40px"} p={'30px'} bg="white">
            <Skeleton h="608px" borderRadius={'base'} />
            <VStack align={'left'} gap="30px">
                <Skeleton h="28px" mb={"5px"} w={"fill"} />
                <VStack align={'left'} gap="20px">
                    <HStack>
                        <Skeleton h="30px" w={"192px"} /> :   <Skeleton h="30px" w={"200px"} />
                    </HStack>
                    <HStack>
                        <Skeleton h="30px" w={"192px"} /> :   <Skeleton h="30px" w={"200px"} />
                    </HStack>
                    <HStack>
                        <Skeleton h="30px" w={"192px"} /> :   <Skeleton h="30px" w={"200px"} />
                    </HStack>
                    <HStack>
                        <Skeleton h="30px" w={"192px"} /> :   <Skeleton h="30px" w={"200px"} />
                    </HStack>
                </VStack>
            </VStack>
        </Grid>
    </>);
}

export default HeroLoader;