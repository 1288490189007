import React from 'react'
import {Icon}  from '@chakra-ui/react'

const SearchIcon = (props:any) => (
    <Icon viewBox='0 0 16 16' {...props}>
      <path
        fill='currentColor'
        d='M15.4,14l-2.6-2.6c0.9-1.2,1.4-2.6,1.4-4.2l0,0c0-1.9-0.7-3.6-2-4.9s-3.1-2-4.9-2l0,0c-3.9,0-7,3.1-7,7
        s3.1,7,7,7c1.6,0,3-0.5,4.2-1.4l2.6,2.6c0.2,0.2,0.5,0.3,0.7,0.3s0.5-0.1,0.7-0.3C15.8,15.1,15.8,14.4,15.4,14z M2.3,7.3
        c0-2.7,2.2-5,5-5l0,0c1.3,0,2.6,0.5,3.5,1.5c0.9,0.9,1.5,2.2,1.5,3.5c0,1.4-0.6,2.6-1.5,3.5l0,0l0,0c-0.9,0.9-2.1,1.5-3.5,1.5
        C4.5,12.2,2.3,10,2.3,7.3z'
      />
    </Icon>
  )

  export default SearchIcon