import React from 'react';
import {
    Box,
    Text,
    Button,
    Flex,
    Grid,
    GridItem,
} from "@chakra-ui/react";

const styles = {
    nav: {
        overflowY: "scroll",
        gridGap: "20px",
        pl: "14px",
        pt: "2px",
        countReset: "steps"
    },
    navItem: {
        counterIncrement: "steps",
    },
    navTitle: {
        textTransform: 'uppercase',
        color: '#343a40',
        fontSize: '18px',
        fontWeight: 'medium',
        pl: '24px',
        position: 'relative',
        zIndex: '1',
        _before: {
            alignItems: 'center',
            bg: 'white',
            border: '1px',
            borderColor: '#ccc',
            borderRadius: '50%',
            content: 'counter(steps)',
            display: 'flex',
            fontSize: '13px',
            h: '25px',
            justifyContent: 'center',
            left: 0,
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            width: '25px'
        }
    },
    navSub: {
        pl: '26px',
        pt: '8px',
        position: 'relative',
        _before: {
            content: '""',
            position: 'absolute',
            h: '100%',
            left: '0',
            top: '-18px',
            borderLeft: '1px',
            borderLeftColor: 'borderClr'
        }
    },
    navSubItem: {
        borderBottom: '1px',
        borderBottomColor: 'borderClr',
        color: '#888',
        pb: '10px',
        pt: '10px',
        position: 'relative',
        _before: {
            content: '""',
            bg: 'currentColor',
            borderRadius: '50%',
            display: 'block',
            h: '7px',
            left: '-26px',
            position: 'absolute',
            top: '50%',
            transform: 'translate(-50%,-50%)',
            w: '7px',
            zIndex: '2'
        }
    },
    navSubValue: {
        fontWeight: 'bold'
    },
    navSubPlaceHolder: {

    }
}


export default function SliderInfo({ sliderStep, vehicle_details, vehicle_conditions, vehicle_location, goToSlider }:any) {
    const hanldeEdit = (index: number) => {
        goToSlider(index)
    }
    return (
        <Box
            as="aside"
            h="560px"
            alignItems="center"
            justifyContent="space-between"
            borderWidth="1px"
            borderRadius="xl"
            overflow="hidden"
            bg={"white"}
            boxShadow={"0 10px 40px 0 rgb(0 0 0 / 2%)"}
            transition={"box-shadow .2s ease-in"}
            textDecoration={"none"}
            p="20px"
            pb="40px">
            <Flex
                h={"100%"}
                flexDirection="column"
            >
                <Text>REVIEW INFORMATION</Text>
                <Grid sx={styles.nav}>
                    <GridItem sx={styles.navItem}>
                        <Text sx={styles.navTitle}>VEHICLE DETAILS</Text>
                        <Box sx={styles.navSub}>
                            <Box sx={styles.navSubItem}>
                                {vehicle_details.year ? (
                                    <Flex justifyContent={"space-between"}>
                                        <Box sx={styles.navSubValue}>{vehicle_details.year}</Box>
                                        <Button onClick={() => hanldeEdit(0)} variant={"editBtn"}>edit</Button>
                                    </Flex>
                                ) : (
                                    <Box sx={styles.navSubPlaceHolder}>Select Year</Box>
                                )}
                            </Box>
                            {sliderStep >= 1 && (
                                <Box sx={styles.navSubItem}>
                                    {vehicle_details.make ? (
                                        <Flex justifyContent={"space-between"}>
                                            <Box sx={styles.navSubValue}>{vehicle_details.make}</Box>
                                            <Button onClick={() => hanldeEdit(1)} variant={"editBtn"}>edit</Button>
                                        </Flex>
                                    ) : (
                                        <Box sx={styles.navSubPlaceHolder}>Select Make</Box>
                                    )}
                                </Box>
                            )}
                            {sliderStep >= 2 && (
                                <Box sx={styles.navSubItem}>
                                    {vehicle_details.model ? (
                                        <Flex justifyContent={"space-between"}>
                                            <Box sx={styles.navSubValue}>{vehicle_details.model}</Box>
                                            <Button onClick={() => hanldeEdit(2)} variant={"editBtn"}>edit</Button>
                                        </Flex>
                                    ) : (
                                        <Box sx={styles.navSubPlaceHolder}>Select Model</Box>
                                    )}
                                </Box>
                            )}
                            {sliderStep >= 3 && (
                                <Box sx={styles.navSubItem}>
                                    {vehicle_details.variant ? (
                                        <Flex justifyContent={"space-between"}>
                                            <Box sx={styles.navSubValue}>{vehicle_details.variant}</Box>
                                            <Button onClick={() => hanldeEdit(3)} variant={"editBtn"}>edit</Button>
                                        </Flex>
                                    ) : (
                                        <Box sx={styles.navSubPlaceHolder}>Select Variant</Box>
                                    )}
                                </Box>
                            )}
                        </Box>
                    </GridItem>
                    {sliderStep >= 4 && (
                        <GridItem sx={styles.navItem}>
                            <Text sx={styles.navTitle}>VEHICLE DETAILS</Text>
                            <Box sx={styles.navSub}>
                                {sliderStep >= 4 && (
                                    <Box sx={styles.navSubItem}>
                                        {vehicle_conditions.hours ? (
                                            <Flex justifyContent={"space-between"}>
                                                <Box sx={styles.navSubValue}>{vehicle_conditions.hours}</Box>
                                                <Button onClick={() => hanldeEdit(4)} variant={"editBtn"}>edit</Button>
                                            </Flex>
                                        ) : (
                                            <Box sx={styles.navSubPlaceHolder}>Select Hours</Box>
                                        )}
                                    </Box>
                                )}
                                {sliderStep >= 5 && (
                                    <Box sx={styles.navSubItem}>
                                        {vehicle_conditions.condition ? (
                                            <Flex justifyContent={"space-between"}>
                                                <Box sx={styles.navSubValue}>Engine Condition: {vehicle_conditions.condition}</Box>
                                                <Button onClick={() => hanldeEdit(5)} variant={"editBtn"}>edit</Button>
                                            </Flex>
                                        ) : (
                                            <Box sx={styles.navSubPlaceHolder}>Select Engine Condition</Box>
                                        )}
                                    </Box>
                                )}
                                {sliderStep >= 6 && (
                                    <Box sx={styles.navSubItem}>
                                        {vehicle_conditions.tyreCondition ? (
                                            <Flex justifyContent={"space-between"}>
                                                <Box sx={styles.navSubValue}>Tyre Condition: {vehicle_conditions.tyreCondition}</Box>
                                                <Button onClick={() => hanldeEdit(6)} variant={"editBtn"}>edit</Button>
                                            </Flex>
                                        ) : (
                                            <Box sx={styles.navSubPlaceHolder}>Select Tyre Condition</Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </GridItem>
                    )}
                    {sliderStep >= 7 && (
                        <GridItem sx={styles.navItem}>
                            <Text sx={styles.navTitle}>VEHICLE DETAILS</Text>
                            <Box sx={styles.navSub}>
                                {sliderStep >= 7 && (
                                    <Box sx={styles.navSubItem}>
                                        {vehicle_location.state ? (
                                            <Flex justifyContent={"space-between"}>
                                                <Box sx={styles.navSubValue}>{vehicle_location.state}</Box>
                                                <Button onClick={() => hanldeEdit(7)} variant={"editBtn"}>edit</Button>
                                            </Flex>
                                        ) : (
                                            <Box sx={styles.navSubPlaceHolder}>Select State</Box>
                                        )}
                                    </Box>
                                )}
                                {sliderStep >= 8 && (
                                    <Box sx={styles.navSubItem}>
                                        {vehicle_location.city ? (
                                            <Flex justifyContent={"space-between"}>
                                                <Box sx={styles.navSubValue}>{vehicle_location.city}</Box>
                                                <Button onClick={() => hanldeEdit(8)} variant={"editBtn"}>edit</Button>
                                            </Flex>
                                        ) : (
                                            <Box sx={styles.navSubPlaceHolder}>Select City</Box>
                                        )}
                                    </Box>
                                )}
                            </Box>
                        </GridItem>
                    )}
                </Grid>
            </Flex>
        </Box>
    )
}