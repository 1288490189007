import axios from "axios"
 //import { API_URL } from 'src/constants';


export default axios.create({
    baseURL: `${process.env.REACT_APP_OEM_API_URL}`,
    headers: {
        Accept: "application/json",
        'Access-Control-Allow-Origin': '*'
    }
})