import oemApi from "./oemApi";
import { DEALER_REQUEST_API ,  DEALERLIST_API,} from "src/constants"
import ibbApi from "./ibbApi";

const dealerRequest = (stateId: any) => {
    // const payload = {
    //     name: params.name,
    //     mobileno: params.mobileNo,
    //     dealer_id: params.dealerId,
    //     platform_id: `${process.env.REACT_APP_PLATFORM_ID}`
    // }
    // const data = oemApi
    //     .post(`${DEALER_REQUEST_API}`, payload)
    //     .then((res) => res.data)
    //     .catch((err) => err);
    // return data;


    const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
    let headers: any = {};
    if (LSToken) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LSToken.token}`
        }
    }
    const params = {
        "state": stateId,
        "platform": process.env.REACT_APP_PLATFORM_ID
    }
    
    const res= ibbApi
        .get(
            `${DEALERLIST_API}?platform=${process.env.REACT_APP_PLATFORM_ID}&state=${stateId}&is_subdomain=false`,
           // params,
            {
                headers: headers
            }
        )
        .then((response) => 
        {
            return response.data}
            )
        .catch((error) => error)
        return res
};
export default dealerRequest
