import ibbApi from "./ibbApi";

const FetchModelList = (token: string, make: string, year?: any): any => {
    const params = {
        // "for": "model",
        "make": make,
        // "year": year ? year : new Date().getFullYear(),
        "access_token": token
    }
    const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
    let headers: any = {};
    if (LSToken) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LSToken.token}`
        }
    }
    const data = ibbApi.get(
        `/get-ibb-model?make=${make}`,
        //  params
        {
            headers: headers
        }
    )
        .then((res) => res.data.data)
        .catch((err) => err)
    return data
}
export default FetchModelList