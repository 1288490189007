import React, { useState, useEffect } from 'react';
import {
    Box,
    Text,
    Grid,
    Flex,
    Button,
} from "@chakra-ui/react";
import FetchModelList from 'src/api/getModel';
import { useAppSelector } from 'src/redux/hooks';
interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    make: any;
    setModel: (values: any) => void;
    year?: number | null;
}

export default function SliderModal({ sliderStep, nextSliderStep, setModel, year, make }: IData) {
    const token = useAppSelector((state: any) => state.ibbToken.token)
    const [modelList, setModelList] = useState<object[]>([]);

    useEffect(() => {
        const res = FetchModelList(token, make, year)
        res.then((data: any) => setModelList(data))
    }, []) // eslint-disable-line

    const handleSubmit = (values: any) => {
        setModel(values)
        nextSliderStep()
    }
    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT MODEL
            </Text>
            <Box overflowY={"scroll"}>
                <Grid templateColumns="repeat( 1fr)" gap="8px">
                    {modelList.map((item: any, index) => (
                        <Button
                            variant={"listBtn"}
                            key={index}
                            onClick={() => handleSubmit(item)}
                        >
                            {item}
                        </Button>
                    ))}
                </Grid>
            </Box>
        </Flex>
    );
}