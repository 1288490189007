import React, { } from 'react';
import {
    Box,
    Text,
    Grid,
    Button,
} from "@chakra-ui/react";
import { useAppSelector } from 'src/redux/hooks';
interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    setTyreCondition: (values: any) => void;

}

export default function SliderTyre({ sliderStep, nextSliderStep, setTyreCondition }: IData) {
    const conditions = useAppSelector((state: any) => state.commonData.condition);

    const handleSubmit = (values: any) => {
        setTyreCondition(values)
        nextSliderStep();
    };

    return (
        <Box>
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                mt="5px"
                mb="20px"
            >
                TYRE CONDITION
            </Text>
            <Grid templateColumns="repeat(3, 1fr)" gap="18px">
                {conditions.map((item: any, index: number) => (
                    <Button
                        key={index}
                        variant="secondary"
                        onClick={() => handleSubmit(item.value)}
                    >{item.label}
                    </Button>
                ))}
            </Grid>
        </Box>
    );
}