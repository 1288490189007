import React from 'react'
import {
    Stack,
    Text,
    Image
} from '@chakra-ui/react'

const VehicleCardEmpty = () => {
    return (<>
        <Stack p={{ base: '20px', xl: '30px' }} bg={'white'} borderRadius={'14px'} boxShadow={'0 10px 40px 0 rgb(0 0 0 / 2%)'} direction={'column'} minH={'350px'} align={'center'} justifyContent={'center'}>
            <Image
                src={`${process.env.PUBLIC_URL}/images/empty-cart.svg`} 
                alt={'No Tractor Found!'}
                width={'115px'}
                height='100px'
            />
            <Text
                fontSize={{ base: '24px', xl: '16px' }}
                fontWeight={'bold'}
                color="secondary"
                textAlign={'center'}
                pt={'40px'}
            >No Tractor Found!</Text>
        </Stack>
    </>)
}

export default VehicleCardEmpty