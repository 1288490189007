import oemApi from "./oemApi";
import { SEND_SMS_API } from "src/constants"


const FetchSendSMS = (mobileNo: number | string): any => {
    const params = {
        cus_mobile: mobileNo,
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`,
        tag: "dms",
        access_token: `${process.env.REACT_APP_API_URL}/get-access-token`
    };
    const data = oemApi
        .post(`${SEND_SMS_API}`, params)
        .then((res) => res.data)
        .catch((err) => err);
    return data;
};
export default FetchSendSMS
