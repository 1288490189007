
import { SEARCH_BUY_LOCAL } from "src/constants";
import ibbApi from "src/api/ibbApi";

export const fetchStocks = (payload: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${SEARCH_BUY_LOCAL}`,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => {
                return response.data})
            .catch((error) => error)
        return res
    }
