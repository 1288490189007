import React, { FC } from 'react'
import { Link as RouteLink ,useLocation} from "react-router-dom"
import { Box, Button, Flex, Link, useMultiStyleConfig } from '@chakra-ui/react'
import { useAppSelector } from 'src/redux/hooks'
import {
    ChevronUpIcon
} from "@chakra-ui/icons";
import smoothscroll from 'smoothscroll-polyfill';

const Footer: FC = () => {
    const styles = useMultiStyleConfig('Footer')
    const ContainerStyle = useMultiStyleConfig('Container')
    const data = useAppSelector((state) => state.commonData.footer.nav);
    smoothscroll.polyfill();

    const goToTop = () => {         
            window.scroll({
                top: 0,
                left: 0,
                behavior: "smooth"
            })
        return false
    }
    return (
        <Box as="footer" __css={{ ...styles.wrapper }}>
            <Box sx={{ ...ContainerStyle.wrapper }}>
                <Flex sx={{ ...styles.jumpTop }}>
                    <Button sx={{ ...styles.jumpTopButton }} onClick={goToTop} rightIcon={<ChevronUpIcon w={6} h={6} />}>
                        Back to top
                    </Button>
                </Flex>
                <Flex sx={{ ...styles.nav }}>
                    {data.map((item: any, i: number) => (
                        <Link as={RouteLink} key={i} to={item.href} sx={{ ...styles.navLink }} color='hsla(0,0%,100%,.4)' fontSize={'13px'} _hover={{ color: 'white', textDecoration: 'none' }} onClick={goToTop}>{item.title}</Link>
                    ))}
                </Flex>
                <Box as="p" sx={{ ...styles.copyWright }}>
                    © {new Date().getFullYear()} {process.env.REACT_APP_NAME}. All rights reserved.
                </Box>
            </Box>
        </Box>
    )
}

export default Footer
