export const currencyFormat = (value: number) => {
    if (value !== undefined && value !== null) {
        const commaSeperator = value.toLocaleString('en-IN', {
            maximumFractionDigits: 0,
            style: 'currency',
            currency: 'INR'
        });
        return `${commaSeperator}`;
    }
    return '';
};