import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { GET_KMS } from 'src/constants'

const params = {
    "tag": "Min",
    "platform": process.env.REACT_APP_PLATFORM_ID
}

export const fetchKmList = createAsyncThunk(
    "km/fetchKM",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .get(
                `${GET_KMS}/Min`,
                //params,
                {
                    headers: headers
                }
        )
            .then((response) =>{ 
        return response.data.data} )
            .catch((error) => error)
        return res
    }
);

const kmInitialState = {
    status: "idle",
    data: [],
    error: {},
};

const kmListSlice = createSlice({
    name: "priceList",
    initialState: kmInitialState,
    reducers: {},
    extraReducers: {
        [fetchKmList.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchKmList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchKmList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export default kmListSlice.reducer;