import React, { SyntheticEvent, useEffect } from 'react';
import {
    Box,
    Text,
    useToast
} from "@chakra-ui/react";
import SearchDealer from 'src/components/Dealer/SearchDealer';
import DealerCard from 'src/components/Dealer/Listing';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchDealers, clearDealer } from 'src/redux/reducers/dealerReducer';
import Cookies from 'js-cookie';
import GetDealer from 'src/components/Dealer/GetDealer';


export default function DealerPage() {
    const dispatch = useAppDispatch()
    const toast = useToast();
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain)
    const LSCityName = Cookies.get('city');

    const handleSubmit = (e: SyntheticEvent) => {
        e.preventDefault()
        const target = e.target as typeof e.target & {
            state: { value: string };
            city: { value: string };
        }
        const stateId = target.state.value;
        const cityId = target.city.value;

        if (!stateId) {
            toast({
                title: 'Select State',
                status: 'error',
                duration: 3000,
                isClosable: true,
            })
        } else {
            const options = {
                stateId: stateId,
                cityId: cityId ? cityId : "all",
            }
            dispatch(fetchDealers(options))
        }
    }
    return (
        <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb="60px" pt={"40px"}>
            {includesSubDomain ?
                <Text as="h1" fontSize={"1.8em"} fontWeight={"bold"} mb={"30px"} color="primary">
                    {LSCityName}
                </Text>
                :
                <Text as="h1" fontSize={"1.8em"} fontWeight={"bold"} mb={"30px"}>
                    Find a Dealer
                </Text>
            }
            {includesSubDomain ?
             <GetDealer/>
            : <SearchDealer handleSubmit={handleSubmit} />
            }
            <Box>
            </Box>
        </Box>
    );
}