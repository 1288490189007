import oemApi from "./oemApi";
import { ADD_REGISTER_API, ADD_REGISTERBROKER_API } from "src/constants"

interface IPayload {
    mobileNo: number | string
    name: string
    stateId: number
    cityId: number
    userType: string
}
const registerUser = (params: IPayload): any => {
    const userParams = {
        user_name: params.name.trim(),
        name: params.name,
        mobile: params.mobileNo,
        state_id: params.stateId,
        city_id: params.cityId,
        user_type: "15",
        status: "1",
        permission_status: "1",
        demo_status: "0",
        tag: "dms",
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`
    };
    const brokerParams = {
        user_name: params.name.trim(),
        name: params.name,
        mobile: params.mobileNo,
        state_id: params.stateId,
        city_id: params.cityId,
        booked: "N",
        lead_source: "Dealer",
        customer: "1",
        broker_status: "N",
        tag: "dms",
        platform_id: `${process.env.REACT_APP_PLATFORM_ID}`
    };
    const url = (params.userType === "user") ? ADD_REGISTER_API : ADD_REGISTERBROKER_API
    const data = oemApi
        .post(`${url}`, (params.userType === "user") ? userParams : brokerParams)
        .then((res) => res.data)
        .catch((err) => err);
    return data;
};
export default registerUser
