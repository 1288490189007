import React, { useState, MouseEventHandler, useEffect, forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    Box,
    AspectRatio,
    Stack,
    Text,
    Link,
    Button,
    Image,
    useDisclosure,
    useToast,
    HStack,
    useUpdateEffect
} from '@chakra-ui/react'
import { currencyFormatter } from 'src/lib/currencyFormatter'
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { CiLocationOn } from "react-icons/ci";
import { MakeEnquiryModal } from './Product/makeEnquiryModal';
import { IoAddCircleOutline, IoAddCircleSharp } from "react-icons/io5";

interface IProps {
    lead_id: number,
    make: string,
    model: string,
    variant: string,
    selling_price: number,
    horse_power: number,
    year_of_manufacture: number,
    image_url: string,
    dealer: string,
    odoReading: number,
    city: any,
    dealerId: any,
    certification: any,
    handleCompare?: (payload: any) => void,
    count?: number,
    drawerOpen?: () => void

}


const VehicleCard = forwardRef((props: IProps, ref: any) => {
    const { lead_id, make, model, variant, selling_price, horse_power, year_of_manufacture, image_url, dealer, odoReading, city, dealerId, certification, count, drawerOpen } = props;
    const enqModal = useDisclosure();
    const toast = useToast()
    const name = (make + ' ' + model + ' ' + variant);
    const [compareText, setCompareText] = useState<string>("Add to compare");
    const [btnDisabled, setBtnDisabled] = useState<boolean>(false);
    const [width, setWidth] = useState<number>(window.innerWidth);

    const [payload, setPayload] = useState<any>(
        {
            make: make,
            city: city,
            variant: variant,
            model: model,
            leadId: lead_id,
            dealerId: dealerId,
        });

    const [comparePayload, setComparePayload] = React.useState<
        Array<{
            leadId: any,
            imageUrl: string,
            yearOfManufacture: any,
            make: string,
            model: string,
            variant: string
        }>
    >([])

    function handleWindowSizeChange() {
        setWidth(window.innerWidth);
    }
    useEffect(() => {
        window.addEventListener('resize', handleWindowSizeChange);
        return () => {
            window.removeEventListener('resize', handleWindowSizeChange);
        }
    }, []);

    const isMobile = width <= 768;
    let maxLimit = isMobile ? 2 : 3;

    const handleCompare = () => {

        if (count && count >= maxLimit) {
            toast({
                title: `Only ${maxLimit} Cars are allowed to Compare.`,
                variant: 'left-accent',
                isClosable: true,
                position:'bottom-right'
            })
            drawerOpen && drawerOpen();
        }
        else {

            setCompareText("Added");
            setBtnDisabled(true);
            const payload =
            {
                leadId: lead_id,
                imageUrl: image_url,
                yearOfManufacture: year_of_manufacture,
                make: make,
                model: model,
                variant: variant,
                setBtnDisabled:setBtnDisabled,
                setCompareText:setCompareText
            }
            if (props.handleCompare) { props.handleCompare(payload) }
        }
    }



//    function removeDisabled() {  
//         setBtnDisabled(false);
//         setCompareText("Add to Compare");
//     }

//     React.useImperativeHandle(ref, () => ({
//         removeDisabled
//     }));


    return (<>
        <Box
            display={'block'}
            bg={'white'}
            borderRadius={'10px'}
            boxShadow={'0 10px 40px 0 rgb(0 0 0 / 2%)'}
            transition={'box-shadow .2s ease-in'}
            textDecoration={'none'}
            overflow={'hidden'}
            _hover={{
                boxShadow: '0 40px 40px 0 rgb(0 0 0 / 8%)',
                textDecoration: 'none',
                '& .card__actionIcon': {
                    ml: '20px'
                }
            }}
        >
            <Link
                as={RouterLink}
                to={`/vehicle-details/${lead_id}`}

            >
                <Box position="relative">
                    <AspectRatio
                        ratio={1.618 / 1}
                        borderRadius={'10px 10px 0 0'}>
                        <Image
                            objectFit={'cover'}
                            src={image_url}
                            alt={name}
                        />
                    </AspectRatio>
                    {certification == "Yes" && <Box as="span"
                        transform={"rotate(-38deg) translate(-5%,-92%)"} fontWeight="bold" fontSize="md" bg={"red"} color={"white"} px={"30px"} zIndex={1} position={"absolute"} top={"2rem"} left={0}> Certified</Box>}
                </Box>
            </Link>
            <Box p={'14px 20px'}>
                <Link
                    as={RouterLink}
                    to={`/vehicle-details/${lead_id}`}
                    style={{ textDecoration: 'none' }}>
                    <Text
                        as={'h3'}
                        textTransform="capitalize"
                        fontSize="md"
                        letterSpacing="wide"
                        color="secondary"
                        height={'20px'}
                        mb={'10px'}
                        noOfLines={1}
                        title={name}
                    >
                        {name}
                    </Text>
                </Link>
                <Text
                    fontSize="18px"
                    fontWeight={'bold'}
                    color="secondary"
                    mb={'10px'}
                >{currencyFormatter(selling_price)}</Text>
                <Stack
                    direction={'row'}
                    spacing={'10px'}
                >
                    <CiLocationOn size={20} />
                    <Text
                        as='span'
                        display={'block'}
                        fontSize="14px"
                        color="gray"
                    >
                        {dealer}
                    </Text>
                </Stack>
                <Stack py={3} direction={'row'} spacing={'10px'}>
                    <Image src={`/images/${process.env.REACT_APP_THEME}/icons/mileage.svg`} alt="mileage" boxSize={8} />
                    <Text
                        as='span'
                        display={'block'}
                        fontSize="14px"
                        color="gray"
                        pt={2}
                        textAlign="center"
                    >
                        {odoReading} {" "} Km
                    </Text>
                </Stack>
            </Box>
            <Stack direction='row' spacing={4} mb={3} mx={5}>
                <Link
                    as={RouterLink}
                    to={`/vehicle-details/${lead_id}`}
                    style={{ textDecoration: 'none' }}>
                    <Button leftIcon={<ArrowForwardIcon className='card__actionIcon' transition={'margin .2s ease-in'} />} variant='blank' w={'full'} color={'primary'} fontSize={'14px'} py={'15px'} >
                        More Details
                    </Button>
                </Link>
                <Button variant='outline' w={'full'} onClick={enqModal.onOpen} fontSize={'14px'}>
                I am interested
                </Button>
            </Stack>
            <Button leftIcon={btnDisabled ? <IoAddCircleSharp size={20} /> : <IoAddCircleOutline size={20} />} variant='ghost' onClick={handleCompare} disabled={btnDisabled ? true : false}>
                {compareText}
            </Button>
        </Box>
        <MakeEnquiryModal open={enqModal.isOpen} close={enqModal.onClose} payload={payload} />
    </>)

}
)

export default VehicleCard