import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import { DEALERLIST_API, DEALER_DETAILS_API, DEALER_LISTING_API ,DEALERLIST_API_CITY} from "src/constants";
import ibbApi from "src/api/ibbApi";


export const fetchDealers = createAsyncThunk(
    "dealer/fetchDealers",
    (stateId: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            "state": stateId,
            "platform": process.env.REACT_APP_PLATFORM_ID
        }
        
        return ibbApi
            .get(
                `${DEALERLIST_API}?platform=${process.env.REACT_APP_PLATFORM_ID}&state=${stateId}`,
                //params,
                {
                    headers: headers
                }
            )
            .then((response) => 
            { 
                return response.data.data}
                )
            .catch((error) => error)
    }
)


export const fetchDealersByCity = createAsyncThunk(
    "dealer/fetchDealersByCity",
    (cityId: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            "city": cityId,
            "platform": process.env.REACT_APP_PLATFORM_ID
        }
        
        return ibbApi
            .get(
                `${DEALERLIST_API_CITY}?platform=${process.env.REACT_APP_PLATFORM_ID}&city=${cityId}`,
               // params,
                {
                    headers: headers
                }
            )
            .then((response) => 
            { return response.data.data}
                )
            .catch((error) => error)
    }
)


// export const fetchDealers = createAsyncThunk(
//     "dealer/fetchDealers",
//     (options: any) => {
//         const params = {
//             "dealer_state": options.stateId,
//             "dealer_city": options.cityId ? options.cityId : "all",
//             "load_perpage": options.perPage ? options.perPage : "50"
//         }
//         return oemApi
//             .post(
//                 DEALERLIST_API,
//                 params
//             )
//             .then((response) => response.data.result.dealer_list)
//             .catch((error) => error)
//     }
// )
export const fetchDealerDetails = createAsyncThunk(
    "dealer/fetchDealerDetails",
    (dealerId: any) => {
        const params = {
            "dealer_id": dealerId
        }
        return oemApi
            .post(
                DEALER_DETAILS_API,
                params
            )
            .then((response) => response.data.result.dealer_details[0])
            .catch((error) => error)
    }
)
export const fetchDealerStocks = createAsyncThunk(
    "dealer/fetchDealerStocks",
    (dealerId: any) => {
        const params = {
            per_page: "8",
            page: "1",
            dealer_id_tractor: dealerId,
            order_by: "web_stock.selling_price",
            order_by_reverse: "asc",
            page_type: 13,
            alias_fields: "tb_leads.lead_id,tb_leads.lead_status,website_stock_images.image_url,web_stock.certification,vehicle_details.year_of_manufacture,vehicle_details.horse_power,vehicle_details.make,vehicle_details.model,vehicle_details.variant,vehicle_details.colour,(CASE WHEN vehicle_details.fuel_type='1' THEN 'Petrol' WHEN vehicle_details.fuel_type='2' THEN 'Diesel' WHEN vehicle_details.fuel_type='3' THEN 'Petrol+CNG' WHEN vehicle_details.fuel_type='4' THEN 'Petrol+LPG' WHEN vehicle_details.fuel_type='5' THEN 'Hybrid' WHEN vehicle_details.fuel_type='6' THEN 'Electric' ELSE vehicle_details.fuel_type END) as fuel_type,(CASE WHEN vehicle_details.no_of_veh_owner=1 THEN '1st' WHEN vehicle_details.no_of_veh_owner=2 THEN '2nd' WHEN vehicle_details.no_of_veh_owner=3 THEN '3rd' WHEN vehicle_details.no_of_veh_owner=4 THEN '4th' WHEN vehicle_details.no_of_veh_owner=5 THEN '5th' ELSE '' END) AS no_of_veh_owner,(case when user_dealer.city_id='NEW DELHI' then 'NCR-DELHI' when user_dealer.city_id='VISAKHAPATNAM' then 'VIZAG' else user_dealer.city_id end) as city_id,web_stock.selling_price",
            where_in: [
                {
                    "column": "tb_leads.lead_status",
                    "value": [
                        "13"
                    ]
                }
            ],
            date_range: [],
            custom_where: [],
            wherenotin: [],
            custom_search: [],
            or_where: [],
            or_where_between: [],
            tag: "web"
        }
        return oemApi
            .post(
                DEALER_LISTING_API,
                params
            )
            .then((response) => response.data.data)
            .catch((error) => error)
    }
)

const dealerInitialState = {
    list: {
        status: "idle",
        data: [],
        error: {},
    },
    listByCity: {
        status: "idle",
        data: [],
        error: {},
    },
    current: {
        status: "idle",
        data: [],
        error: {},
    },
    stocks: {
        status: "idle",
        data: [],
        error: {},
    }
}

const dealerSlice = createSlice({
    name: "dealers",
    initialState: dealerInitialState,
    reducers: {
        clearDealer: (state) => {
            state.list.data = [];
        },
    },
    extraReducers: {
        [fetchDealers.pending.type]: (state) => {
            state.list.status = "loading"
            state.list.data = []
            state.list.error = {}
        },
        [fetchDealers.fulfilled.type]: (state, action: any) => {
            state.list.status = "success"
            state.list.data = action.payload
            state.list.error = {}
        },
        [fetchDealers.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.list.status = "error"
            state.list.data = []
            state.list.error = action.payload
        },
        [fetchDealersByCity.pending.type]: (state) => {
            state.listByCity.status = "loading"
            state.listByCity.data = []
            state.listByCity.error = {}
        },
        [fetchDealersByCity.fulfilled.type]: (state, action: any) => {
            state.listByCity.status = "success"
            state.listByCity.data = action.payload
            state.listByCity.error = {}
        },
        [fetchDealersByCity.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.listByCity.status = "error"
            state.listByCity.data = []
            state.listByCity.error = action.payload
        },
        [fetchDealerDetails.pending.type]: (state) => {
            state.current.status = "loading"
            state.current.data = []
            state.current.error = {}
        },
        [fetchDealerDetails.fulfilled.type]: (state, action: any) => {
            state.current.status = "success"
            state.current.data = action.payload
            state.current.error = {}
        },
        [fetchDealerDetails.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.current.status = "error"
            state.current.data = []
            state.current.error = action.payload
        },
        [fetchDealerStocks.pending.type]: (state) => {
            state.stocks.status = "loading"
            state.stocks.data = []
            state.stocks.error = {}
        },
        [fetchDealerStocks.fulfilled.type]: (state, action: any) => {
            state.stocks.status = "success"
            state.stocks.data = action.payload
            state.stocks.error = {}
        },
        [fetchDealerStocks.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.stocks.status = "error"
            state.stocks.data = []
            state.stocks.error = action.payload
        },
    }
});

export const { clearDealer } = dealerSlice.actions;
export default dealerSlice.reducer;