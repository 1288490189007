import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Center,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    Grid,
    Select,
    Box,
    useMultiStyleConfig,
    Heading,
    Checkbox,
    useToast,
    Text,
    HStack
} from '@chakra-ui/react';
import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { openCustomerModal, customerInfo } from 'src/redux/reducers/CustomerFormReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { makeEnquiry } from 'src/redux/reducers/makeEnquiryReducer';
import { fetchDealersByCity } from 'src/redux/reducers/dealerReducer';
import { customerInterest } from 'src/api/customerIntrest';
import {
    updatCertifiedStatus, updatePage, updateMaxKm, updateMinKm,
    updateMinPrice, updateMaxPrice, updateMinYear, updateMaxYear, updateModel,
    updateBodyType, updateTransmissionType, updatefuelType, updateFCity
} from "src/redux/reducers/filterBuyReducer";
import { updateModalValue } from 'src/redux/reducers/commonReducer';
import { updateDealer } from 'src/redux/reducers/cityListReducer';
import Cookies from 'js-cookie';



const styles = {
    title: {
        color: "gray",
        fontWeight: "medium",
        fontSize: { base: 'lg', xl: '3xl' },
        mb: { base: 4, xl: 6 },
        lineHeight: "tall",
        position: 'relative',
    },
    titleBorder: {
        display: { base: 'block', xl: 'none' },
        w: 36,
        borderBottom: '2px solid gray',
        pt: 2
    },
    text: {
        fontSize: { base: 'sm', xl: 'xl' },
        color: 'gray4',
        lineHeight: "tall",
        mb: 10
    },
    sectionBg: {
        bg: 'lightgray',
        pt: { base: 5, xl: 14 },
        pb: { base: 5, xl: 8 }
    },
    subTitle: {
        fontSize: { base: 'base', xl: 'black' },
        fontWeight: 'medium',
        mb: 6
    },
    formGrid: {
        gridTemplateColumns: { xl: 'repeat(2,1fr)' },
        rowGap: { base: 10, xl: 8 },
        columnGap: { xl: 16 },
        mb: { base: 10, xl: 24 }
    },
    formControl: {
        mb: 5,
        _last: {
            mb: 0
        }
    },
    // text-xs text-gray4 leading-snug
    notes: {
        fontSize: 'xs',
        color: 'gray4',
    },
    checkbox: {
        alignItems: "center",
        borderColor: "secondary",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
}


export const MakeEnquiry = ({ updateStocks, handleResetRange }: any) => {

    const containerStyle = useMultiStyleConfig(`Container`);
    const dispatch = useAppDispatch();
    const toast = useToast();
    const cityList = useAppSelector((state: any) => state.cities.data);
    const dealerList = useAppSelector((state: any) => state.dealer.listByCity.data);
    const modelList = useAppSelector((state: any) => state.modelList.data);
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const [invalidFistName, setInvalidFirstName] = useState<boolean>(false);
    const [invalidLastName, setInvalidLastName] = useState<boolean>(false);
    const [mobileNo, setMobileNo] = useState<string>("");
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [termsConditions, setTermsConditions] = useState<boolean>(false);
    const [model, setModel] = useState<string>("");
    const [dealer, setDealer] = useState<string>("");
    const [city, setCity] = useState<any>("");
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    const modelValue = useAppSelector((state: any) => state.commonData.modelValue);
    const defaultcity = useAppSelector((state: any) => state.cities.cityId);
    const dealerId = useAppSelector((state: any) => state.cities.dealer);


    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        const mobileRegex = /^[1-9]\d{9}$/; // Regular expression for Indian mobile numbers
        const isValidMobile = mobileRegex.test(e.target.value);
        setInvalidMobile(!isValidMobile);
        setMobileNo(e.target.value);
    }
    useEffect(() => {
        setModel(modelValue);
    }, [modelValue]);

    useEffect(() => {
        setCity(defaultcity);
    }, [defaultcity])

    useEffect(() => {
        if (dealerId) { setDealer(dealerId); }
    }, [dealerId]);


    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isFormSubmitted) {
            return;
        }
        setSubmitBtnLoading(true)
        const params = {
            "first_name": firstName,
            "last_name": lastname,
            "mobile": mobileNo,
            "email": email,
            "city": city,
            "model": model,
            "dealer_id": dealer,
            "make": "AUDI",
            "terms_conditions": termsConditions,
            "platform": process.env.REACT_APP_PLATFORM_ID,
            "is_subdomain": includesSubDomain ? true : false,
        }
        const res = customerInterest(params);
        res.then((data: any) => {
            if (data.status === "success") {
                if (window.location.hostname.includes('noida.audiapprovedplus')) {
                    fbq('init', '307433628486132');
                    fbq('track', 'PageView');
                    fbq('track', 'Lead');
                }
                toast({
                    title: "Thank you for showing interest in us. Our representative will contact you shortly to confirm the appointment.",
                    status: 'success',
                    duration: 3000,
                    isClosable: true,
                    position: 'bottom-right'
                });

                // dispatch(updatCertifiedStatus(false));
                // dispatch(updateModel(""))
                // dispatch(updateBodyType(""))
                // dispatch(updateMinPrice(""))
                // dispatch(updateMaxPrice(""))
                // dispatch(updateMinYear(""))
                // dispatch(updateMaxYear(""))
                // dispatch(updatefuelType(""))
                // dispatch(updateTransmissionType(""))
                // dispatch(updateMaxKm(""))
                // dispatch(updateMinKm(""))
                // dispatch(updateMinKm(""))
                // LSCityId && dispatch(updateFCity(LSCityId));
                // window.location.reload();
                updateStocks("reset", "from Enquiry");
                dispatch(updateModalValue(""));
                dispatch(updateDealer(""));
                handleResetRange();


            } else {
                toast({
                    title: data.message,
                    status: 'warning',
                    isClosable: true,
                    position: 'bottom-right'
                })
            }
        })
        setIsFormSubmitted(true);
        setSubmitBtnLoading(false)
    }
    const handleChecked = (e: ChangeEvent<HTMLInputElement>) => {
        const { checked }: any = e.target
        if (checked) {
            setTermsConditions(true);
        }
    }

    useEffect(() => {
        let customer = Cookies.get('cust');
        if (customer) {
            const parsedCustomer = JSON.parse(customer);
            if (typeof parsedCustomer === 'object' && parsedCustomer !== null) {
                setFirstName(parsedCustomer.first_name);
                setLastName(parsedCustomer.last_name);
                setMobileNo(parsedCustomer.mobile);
                setEmail(parsedCustomer.email);
            }
        }
    }, []);

    useEffect(() => {
        if (city) {
            dispatch(fetchDealersByCity(city));
        }
    }, [city]);

    return (
        <Box my={8}>
            <Box sx={containerStyle.wrapper}>
                <Box>
                    <form onSubmit={handleSubmit} id="myForm">
                        <FormControl sx={styles.formControl} isRequired isInvalid={invalidFistName}>
                            <FormLabel htmlFor='firstName'>First Name</FormLabel>
                            <Input height={"0 !important"} name='firstName' id="firstName" type='text' placeholder=" " value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
                            <FormErrorMessage>Please add valid first name</FormErrorMessage>
                        </FormControl>
                        <FormControl sx={styles.formControl} isRequired isInvalid={invalidLastName}>
                            <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                            <Input height={"0 !important"} name='lastName' type='text' placeholder=" " value={lastname} onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                        </FormControl>
                        <FormControl sx={styles.formControl} isRequired isInvalid={invalidMobile}>
                            <FormLabel htmlFor='mobile'>Mobile</FormLabel>
                            <Input height={"0 !important"} name='mobile' type='tel' placeholder=" " maxLength={10} value={mobileNo} onChange={validateMobileNo} />
                        </FormControl>
                        <FormControl sx={styles.formControl} isRequired isInvalid={false}>
                            <FormLabel htmlFor='email'>Email</FormLabel>
                            <Input height={"0 !important"} name='email' type='email' placeholder=" " value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                        </FormControl>
                        <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                            <Select placeholder='Model Name' onChange={(e: ChangeEvent<HTMLSelectElement>) => setModel(e.target.value)} variant='mySelect'>
                                {
                                    (modelList && modelList.length !== 0) ? modelList.map((item: any, index: number) => (<option key={index} selected={item === modelValue} value={item}>{item}</option>)) : (<option>Loading</option>)
                                }
                            </Select>
                        </FormControl>

                        {includesSubDomain ?
                            <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                <Select placeholder='Select City' value={defaultcity} variant='mySelect'>
                                    {
                                       cityList &&  cityList.length !== 0 ? cityList.filter((item: any) => item.city_id == defaultcity).map((item: any) => (<option
                                            selected={item.city_id === defaultcity} key={item.city_id} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                                    }
                                </Select>
                                <FormErrorMessage>Select any city</FormErrorMessage>
                            </FormControl>
                            :
                            <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                <Select placeholder='Select City' onChange={(e: ChangeEvent<HTMLSelectElement>) => setCity(e.target.value)} variant='mySelect'>
                                    {
                                     cityList && cityList.length !== 0 ? cityList.map((item: any) => (<option key={item.city_id} selected={item.city_id == defaultcity} value={item.city_id}>{item.city_name}</option>)) : <option>Loading</option>
                                    }
                                </Select>
                                <FormErrorMessage>Select any city</FormErrorMessage>
                            </FormControl>
                        }
                        {
                            includesSubDomain ?
                                <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                    <Select placeholder='Select Dealer' onChange={(e: ChangeEvent<HTMLSelectElement>) => setDealer(e.target.value)} variant='mySelect'>
                                        {
                                         dealerList &&   dealerList.length !== 0 ? dealerList.filter((item: any) => item.id === dealerId).map((item: any) => (<option
                                                selected={item.id === dealerId}
                                                key={item.id} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                                        }
                                    </Select>
                                </FormControl>
                                :
                                <FormControl sx={styles.formControl} variant='floating' isRequired isInvalid={false}>
                                    <Select placeholder='Select Dealer' onChange={(e: ChangeEvent<HTMLSelectElement>) => setDealer(e.target.value)} variant='mySelect'>
                                        {
                                         dealerList &&  dealerList.length !== 0 ? dealerList.map((item: any) => (<option key={item.id} selected={item.id == dealerId} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                                        }
                                    </Select>
                                </FormControl>
                        }
                        <FormControl sx={styles.formControl} isRequired isInvalid={false}>
                            <HStack>
                                <Checkbox
                                    sx={styles.checkbox}
                                    onChange={(e) => handleChecked(e)} >

                                </Checkbox>
                                <Text>By submitting this form you are confirming that you agree with our Privacy Policy.</Text>
                            </HStack>
                        </FormControl>
                        <Button type='submit' variant="primary" my={5} w={"full"} isLoading={submitBtnLoading} loadingText='Submitting' minW={60} maxW={"30%"}>Submit</Button>
                    </form>
                </Box>
            </Box>
        </Box>

    )
}


