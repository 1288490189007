import React from 'react';
import { Box, Text, ListItem, OrderedList, Link } from '@chakra-ui/react';

export const TermsAndConditions = () => {
    return (
        <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb="60px" pt={"40px"}>
            <Text as="h1" fontSize={"3rem"} fontWeight={"bold"} mb={"30px"} textAlign={"center"}>
                Legal Notice
            </Text>
            <Text as="p" fontSize={"1rem"} fontWeight={"bold"} mb={"20px"}>
            This website (hereinafter may be referred as website or site or web pages) is owned and maintained by Audi India, a Division of  SKODA Auto Volkswagen  India Private Limited), a private limited company incorporated under Indian Companies Act (hereinafter “AUDI INDIA” ‘SAVWIPL” or “we”). This website and details provided therein are intended for use by persons located and resident within the Union of India.
            </Text>
            <Text as="h2" fontSize={"2rem"} mb={"20px"} fontWeight={"bold"}>
            Disclaimer 
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            AUDI INDIA takes great care in compiling the contents of this website and makes sure that webpages get updated on best effort and regular basis. They are nevertheless only meant to provide non-binding general information and cannot replace detailed individual advice to help the prospective buyer / existing customer make his/her decision. The technical features and equipment of the vehicles described are only examples. and are subject to alterations without any prior notice. You will get detailed specifications of the features, equipment’s or  of our vehicles exclusively from AUDI INDIA authorized Dealers.
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            AUDI INDIA does not guarantee that the information on these pages is up-to-date, accurate and complete, or that they can be accessed without disturbance at any time.
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            AUDI INDIA does not take any responsibility for web pages of third parties (links) and the contents of the linked pages. In activating the reference, you leave the information service of Audi India . Diverging regulations can therefore be applicable to the services of third parties, in particular with regard to data protection.
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
            AUDI INDIA, SAVWIPL its affiliates or its Agencies shall not be liable for any or all liability for related services - in particular for the downloading of files provided by AUDI INDIA on the web pages.
            </Text>
            <OrderedList mb={"20px"}>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        General
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                    By using the site or downloading materials from the site, you agree to abide by the terms and conditions set forth in this notice. If you do not agree to abide by these terms and conditions please do not use the site or download materials from the site.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Intellectual Property Rights
                    </Text>
                    <OrderedList style={{ listStyleType: "lower-alpha" }}>
                        <ListItem fontSize={"1rem"} mb={"15px"}>This web site is published by AUDI INDIA and is protected by copyright and other intellectual property rights in existence at any time throughout the world. All web site design, text, graphics, the typographical arrangement thereof, and all other material on this web site is copyright of AUDI INDIA, Audi AG or its Group Companies / Affiliates or their service and technology providers.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>All texts, pictures and other works published on the website are subject to the copyright of AUDI INDIA or its group Companies, if not otherwise indicated. Any duplication, distribution, storage, communication, broadcast and reproduction or transmission of the contents without written approval from AUDI INDIA is prohibited. All trademarks including TDI, quattro, Spaceframe, ASF as well as the model names are registered trademarks of AUDI AG.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Permission is hereby granted to electronically copy and to print hard copy portions of this web site for the sole purpose of using this as a personal non-commercial information resource. Any other use of materials on this website, including reproduction for purposes other than the above, modification, distribution or republication without the prior written permission of AUDI INDIA is prohibited.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The trademarks, logos and service marks shown on this website, unless otherwise specified, are the trademarks of AUD AG or Audi Group entities. No rights are hereby granted to use any of them in any manner whatsoever.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Any infringement of these rights will result in appropriate legal action being taken against you.</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Disclaimer and Liability
                    </Text>
                    <OrderedList style={{ listStyleType: "lower-alpha" }}>
                        <ListItem fontSize={"1rem"} mb={"15px"}>All express warranties, representations, conditions of any kind or other terms implied by statute or law with respect to this website or the information, content, materials or products included on this website are hereby expressly excluded to the fullest extent permitted by law.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>AUDI INDIA shall not be liable in any circumstances for any direct or indirect, special or consequential loss or damage (whether for profit or loss or otherwise) costs, claims, expenses or other claims for compensation what so ever, whether caused by the acts, omissions or the negligence of AUDI INDIA, its employees or agents, which arise out of or in connection with the use of this website or the information, content materials or products included on this website.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The Content (Pricing, specifications, pictures, material, information, data, news items, etc. if any) contained on this website is provided for general information only and should not be used as a basis for making business/commercial decisions. You are advised to exercise due caution and/or seek independent advice before entering into any financial obligation based on the Content contained on this website.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The Content contained in this Website, including text, graphics, links or other terms are provided on an “as is”, “as available” basis. AUDI INDIA does not accept any responsibility or liability for access to or material on, any website that is linked from or to this website.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>In the event of any conflict between the Terms and Condition of specific Products and Service and these Terms and Conditions specific to such product and service shall prevail.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The Content on this website should not be regarded as an offer, solicitation, invitation, advice or recommendation to buy or sell products / schemes of AUDI INDIA, its subsidiary companies or any of its affiliates. Use of the products or services described at this web site may not be permitted in some countries and if in doubt, you should check with your local regulator or authority before requesting further information on such products/ services.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>No information on this site shall constitute an invitation to invest in AUDI INDIA or any of its affiliates or business partners. Neither AUDI INDIA, nor their or their affiliates', officers, employees or agents shall be liable for any loss, damage or expense arising out of any access to or use of this site or any site linked to it, including, without limitation, any loss of profit, indirect, incidental or consequential loss.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Products and Services are available only at the discretion of AUDI INDIA, subject to the individual terms and conditions of products and services (if any) on which they are offered and such products and services may be withdrawn or amended at any time without notice. The full range of products or services may not be available in all locations.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>This website is only to generate leads and not to conclude sales or to take any responsibility for payment between the dealer and customers, if the sales is concluded between them.</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Misuse Statement
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        AUDI INDIA reserves the right to prevent your usage of this site if you misuse the contents contained within this website in any manner. AUDI INDIA is the final arbiter as to what shall constitute misuse and its decision will be final.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Governing Law
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        This website is for use by users located and resident within the Union of India, accordingly, these terms and conditions or any claim shall be subject to Indian Laws and the parties to any dispute or action hereby submit to the exclusive jurisdiction of the courts in Mumbai.    </Text></ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Amendments
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        AUDI INDIA reserves the right to change the information provided on or via this web site, including the terms of this disclaimer, at any time and without notice. However, AUDI INDIA shall not undertake to update the Content contained on this web site from time to time. It is recommended that you review the information provided on or via this web site, including the terms of this disclaimer, periodically for changes. AUDI INDIA shall not be held responsible for all or any actions that may subsequently result in any loss, damage and or liability on account of such change in the information on this website.    </Text></ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Sign up / Account registration:
                    </Text>
                    <OrderedList style={{ listStyleType: "lower-alpha" }}>
                        <ListItem fontSize={"1rem"} mb={"15px"}>By accessing or using the Web Site, you represent and warrant that you are a resident of India and at least 18 years old.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>You may opt-in to receive marketing materials, information about you vehicle from AUDI INDIA, including news and special event and new vehicle information. If you opt-in you may be asked to provide your Personal Information to us so we can fulfill your request. It is completely voluntarily to Sign-up to this website.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>You may also opt-out of receiving any marketing materials from AUDI INDIA at any time by putting such a request on the contact details provided under the Privacy Policy section.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>If you opt-in to receive information from AUDI INDIA, you may be required to provide your personally identifiable information, such as your name, address, phone number or email address ("Personal Information"). Further, if you register on any of the Sites, you may be asked to provide additional information, including your Vehicle Identification Number. Please see our Privacy Policy for more information on how we protect your Personal Information.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>We shall have no liability associated with or arising from your failure to maintain accurate Personal Information in connection with your Account, including, but not limited to, your failure to receive critical information about AUDI INDIA and/or the Sites.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>If you opt-in to receive information from AUDI INDIA, you agree to provide true, accurate and complete information about yourself as prompted by the online or mobile forms. You may also have the opportunity to register for an account on the Sites ("Account") to access and use certain features of the Sites, including certain applications offered by AUDI INDIA and certain specially-tailored products and services (e.g., for Audi Owners). If we have reasonable grounds (as determined by us in our sole discretion) to suspect that the Personal Information you provide for opt-in or registration purposes is untrue, inaccurate or incomplete, we have the right to refuse, with or without notice, any and all current or future requests you make through the Sites and/or to suspend or terminate your Account.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Your selection and use of an Account username and password, and any other information required for security access and authentication purposes, are subject to these Terms. You agree not to share your username, password and any security-related information with anyone. You are responsible for all use of your Account and for ensuring that access to and use of your Account comply fully with these Terms. You acknowledge and agree that any use of your Account through your username, password and security information shall be deemed to be your actions which we may rely upon. You are solely responsible for protecting the confidentiality and security of your Account.</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Miscellaneous
                    </Text>
                    <OrderedList style={{ listStyleType: "lower-alpha" }}>
                        <ListItem fontSize={"1rem"} mb={"15px"}>No reproduction of any part of the site may be sold or distributed for commercial gain, nor shall it be modified or incorporated in any other work, publication or site, whether in hard copy or electronic format, including postings to any other site. AUDI INDIA reserves all other rights.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The information on this site has been included in good faith and is for general purposes only. It should not be relied upon for any specific purpose and no representation or warranty is given as regards its accuracy or completeness.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Please proceed only if you accept all the conditions enumerated herein above, out of your free will and consent.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>The Prices, Pictures, specifications of the vehicle models and other details provided on the Website are for reference purpose only and are subject to change without prior notice.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Accessories shown and features listed may not be aIf you opt-in to receive information from AUDI INDIA, youvailable in all the variants.</ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>Actual color and appearance may vary and picture displayed on the website my not correspond to the actual vehicle offered for sale at the Dealership.</ListItem>
                    </OrderedList>
                </ListItem>
            </OrderedList>
            <Text as="h2" fontSize={"2rem"} mb={"20px"} fontWeight={"bold"}>
                Privacy Policy
            </Text>
            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                To ensure that vehicle orders, financial, insurance and support services as well as servicing are all handled properly and quickly, AUDI INDIA and Audi partners make use of electronic data processing (EDP).
            </Text>
            <OrderedList mb={"20px"}>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Data collection and storage at Audi partners:
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        AUDI INDIA its affiliates and partners may store any data required for ordering your new car or for rendering financial, insurance and support services as well as for aftersales servicing. Such data will initially include information in purchase, leasing, service, financing and insurance contracts. Moreover, data relating to processing transactions, such as the customer number and purchase price, shall be maintained, and records of vehicle-specific data and services rendered as part of vehicle servicing shall be collected and processed. Providing you have given your consent thereto, data required to perform customer support, to conduct customer surveys and to tailor customer information exactly to your individual demands, will also be collected and stored.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Data transmission between AUDI INDIA, Group Companies-Affiliates and Partners
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        To ensure that AUDI INDIA, Group Companies and Affiliates, and AUDI INDIA Partners have an up-to-date record and so that such records can be amended where appropriate, any data collected will be exchanged between these entities.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Use of data by AUDI INDIA:
                    </Text>
                    <OrderedList style={{ listStyleType: "lower-alpha" }}>
                        <ListItem fontSize={"1rem"} mb={"15px"}>
                            <Text as="p" fontSize={"1rem"} >
                                Rendering Services:
                            </Text>
                            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                                AUDI INDIA will use the customer data to render ordered services properly and quickly (e.g. new cars, after sales service, financial and insurance services).
                            </Text>
                        </ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>
                            <Text as="p" fontSize={"1rem"} >
                                Customer Care:
                            </Text>
                            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                                Customer data will also be used by AUDI INDIA or its Affiliates to ensure that you receive comprehensive customer care. The data may be used by AUDI INDIA or its Affiliates the service providers acting on AUDI INDIA's behalf so as to conduct customer surveys.
                            </Text>
                        </ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>
                            <Text as="p" fontSize={"1rem"} >
                                Customer information tailored exactly to your needs:
                            </Text>
                            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                                By accessing the website you are providing your consent to customer information tailored exactly to your individual needs – if applicable together with other personal data given by you to AUDI INDIA or its affiliates and will be pooled and stored in a database, where your data will be automatically evaluated in order to compare the resultant personal customer profile with individual product and service offerings.
                            </Text>
                        </ListItem>
                        <ListItem fontSize={"1rem"} mb={"15px"}>
                            <Text as="p" fontSize={"1rem"} >
                                No forwarding of data beyond the scope defined above:
                            </Text>
                            <Text as="p" fontSize={"1rem"} mb={"20px"}>
                                Your personal data will not be transmitted unless covered by any of the instances mentioned above.
                            </Text>
                        </ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Information on Data Protection
                    </Text>
                    <Text as="p" fontSize={"1rem"} >
                        For AUDI INDIA, the protection of personal data is of utmost importance. We would therefore like to inform you how we protect your privacy when you give your personal data to us.
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        It goes without saying that we shall comply with data protection regulations, but in addition, in giving you the following information, we would like to commit ourselves to using your data in a responsible way, making sure that your privacy is protected at any time. We want you to feel good and safe when visiting our web pages.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        General Information
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                    f you visit the web pages of AUDI INDIA, information of general nature is collected automatically. You may requested to accept Cookies .This information includes, for example, such things as the kind of web browser, the operating system which is used, or the domain names of your internet service providers. Exclusively such information is collected which does not allow any conclusions as to your identity. Besides, these data are also generated when visiting any other web page on the internet, i. e. this is not a function specific of AUDI INDIA internet pages. This kind of information is collected exclusively in an anonymizyed way and interpreted by us for statistical purposes. The better we understand what you want, the faster you will find the desired information on our internet pages.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Personal Information
                    </Text>
                    <Text as="p" fontSize={"1rem"}>
                        If you are asked to give personal information on our pages, such as for example your name, address or phone number, this is subject to special provisions, which are indicated to you in the following wording:
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        We will use these data for marketing purposes within the Audi Group, to attract prospective customers, conduct surveys and provide information. Your data will not be passed on to any unauthorized third parties. Apart from the data which you give to us, we will use information derived from the way in which you use our service to be able to lead you to the information which could be interesting for you as fast as possible, and to constantly optimize our service.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Security
                    </Text>
                    <Text as="p" fontSize={"1rem"} mb={"20px"}>
                        AUDI INDIA employs technical and organizational security measures to protect the personal data you give to us from any deliberate or undeliberate manipulation, loss, destruction or access by unauthorized persons. Our security measures are improved and adapted on a regular basis.
                    </Text>
                </ListItem>
                <ListItem fontSize={"1.2rem"}>
                    <Text as="h5" fontSize={"1.2rem"} mb={"15px"} fontWeight={"bold"}>
                        Contact Details
                    </Text>
                    <Text as="p" fontSize={"1rem"} >
                        If you have any queries, requests or complaints concerning this website or services offered thereunder, please do not hesitate to contact us on below mentioned details:
                    </Text>
                    <Link href="mailto:customer.first@audi.in" fontSize={"1rem"} color={"rgba(245,5,55,1)"} textDecoration={"underline"}>customer.first@audi.in</Link>
                </ListItem>
            </OrderedList>
            <Box fontStyle={"italic"} my={"20px"} ml={"10px"}>
                <Text as="h5" fontSize={"1.2rem"} fontWeight={"bold"}>Audi India</Text>
                <Text as="h5" fontSize={"1.2rem"} fontWeight={"bold"}>Division of ŠKODA AUTO Volkswagen India Private Limited</Text>
                <Text as="p" fontSize={"1rem"}>(Formerly known as Volkswagen India Private Limited)
                    <br />
                    Silver Utopia. 4th Floor
                    <br />
                    Cardinal Gracious Road
                    <br />
                    Chakala, Andheri (East)
                    <br />
                    Mumbai - 400 099
                    <br />
                    Tel: 022-33137205
                </Text>
            </Box>
        </Box>
    )
}
