import React, { FunctionComponent } from 'react';
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Box,
  Hide,
  Show,
  useMultiStyleConfig,
  Image,
  Heading, Text,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
} from '@chakra-ui/react';
import {  ChevronRightIcon} from '@chakra-ui/icons';

const styleValue = {
  section: {
    mb: { base: 15, xl: 30 },
  },
  subTitle: {
    fontSize: { base: 'xl', xl: '40px' },
    fontWeight: 'extendedBold',
    mb: 3
  },
  text: {
    fontSize: { base: 'tiny', xl: 'base' },
    lineHeight: 'tall',
  },
  myModal:{
    borderRadius:"none"
  }
}
export const DocumentsReq: React.FC = (props: any) => {
  const { onClose, isOpen } = props;
  const ContainerStyle = useMultiStyleConfig('Container');

  return (
    <Modal onClose={onClose} size={"xl"} isOpen={isOpen} closeOnOverlayClick={false} scrollBehavior={"outside"} >
      <ModalOverlay />
      <ModalContent sx={styleValue.myModal}>
        {/* <ModalHeader>Modal Title</ModalHeader> */}
        <ModalCloseButton />
        <ModalBody>
          <Box sx={{ ...ContainerStyle.wrapper, ...styleValue.section }}>
            <Heading as='h3' color="gray" sx={styleValue.subTitle}>Documents required</Heading>
            {/* <Text as='p'>2 Years or 30000 Kms</Text>
            <Text as='p' sx={styleValue.text}>Available Option</Text>
            <Text as="p" fontSize={"1rem"} mb={"5px"} fontWeight={"bold"} >
              Best Protection for your investments
            </Text> */}
            <List spacing={1}>
              <ListItem>
                <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6} />
                Original RC
              </ListItem>
              <ListItem>
                <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
                RTO Tax paid receipt and PUC
              </ListItem>
              <ListItem>
                <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6} />
                Warranty booklet and extended warranty papers
              </ListItem>
              <ListItem>
                <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
                Original Insurance Policy / Cover note (if applicable)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Copy of dealer invoice (if required for said state)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Foreclosure Statement (if applicable)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
                Original Insurance Policy / Cover note (if applicable)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Payment deposit receipt of finance company (if applicable)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Owner manual and Service history book
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Duplicate key
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Authority letter for payment (if payment is other than RC holder)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Letter Of Authority And Company Letter Head (In Case Company Registered Car)
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Tool Kit And Accessories
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Identity Proof
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Address Proof
              </ListItem>
              <ListItem>
              <ListIcon as={ChevronRightIcon} color='red.500' boxSize={6}/>
              Passport Size Photographs
              </ListItem>
            </List>
          </Box>
        </ModalBody>
      </ModalContent>
    </Modal>
  )
}
