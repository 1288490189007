import ibbApi from "./ibbApi";

const FetchVarientList = (
  token: string,
  make: string,
  model: string,
  year: number
): any => {

  const params = {
    for: "variant",
    make: make,
    model: model,
    year: year,
    access_token: token,
  };
  const data = ibbApi
    .post("/api/Swaraj", params)
    .then((res) => res.data.data)
    .catch((err) => err);
  return data;
};
export default FetchVarientList;
