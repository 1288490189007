import React, { FC } from 'react'
import { useAppSelector,useAppDispatch } from 'src/redux/hooks'
import { Link as RouterLink, useLocation } from "react-router-dom"
import {
  Flex,
  Box,
  Link,
  List,
  ListItem,
  Image,
  useMultiStyleConfig,
  HStack,
  Text,
} from "@chakra-ui/react"
import MNav from './MNav'
import LodginModal from './Login/LoginModal'
import ProfileModal from './Login/ProfileModal'
import { CiLocationOn } from "react-icons/ci";
import { ChevronDownIcon } from '@chakra-ui/icons';
import {openCityModal}  from 'src/redux/reducers/cityListReducer';
import smoothscroll from 'smoothscroll-polyfill';

const Header: FC = ({
  // children,
  ...rest
}) => {
  const styles = useMultiStyleConfig(`Header`)
  const ContainerStyle = useMultiStyleConfig('Container')
  const location = useLocation();
  const nav = useAppSelector((state: any) => state.commonData.nav)
  const userLogged = useAppSelector((state: any) => state.userData.isLogged);
  const city = useAppSelector((state: any) => state.cities.city);
  const dispatch = useAppDispatch();
  const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
  smoothscroll.polyfill();

  const goToTop = () => {       

          window.scroll({
              top: 0,
              left: 0,
              behavior: "smooth"
          })
      return false
  }
   
  const openModal=()=>{
   dispatch(openCityModal(true)); 
  }
  return (
    <Box as="header" __css={{ ...styles.wrapper }} {...rest}>
      <Flex sx={{ ...styles.wrapperIn, ...ContainerStyle.wrapper }} >
        <Flex sx={{ ...styles.logoWrap }}>
          <MNav />
          <Link
            as={RouterLink}
            to="/"
            title={`${process.env.REACT_APP_NAME}`}
            sx={{ ...styles.logoLink }}
           
          >
            <Image
              src={`/images/${process.env.REACT_APP_THEME}/${process.env.REACT_APP_LOGO_FILE}`}
              alt={`${process.env.REACT_APP_NAME}`}
              sx={{ ...styles.logo }}
            />
          </Link>
        </Flex>
        <Box sx={{ ...styles.navWrap }}>
          <List display={"flex"}>
            {nav.map((item: any) => (
              <ListItem key={item.id} sx={{ ...styles.listItem }}>
                <Link
                  as={RouterLink}
                  to={item.href}
                  sx={{ ...styles.listLink }}
                  className={location.pathname === item.href ? 'active' : ''}
                  onClick={goToTop}
                >
                  {item.title}
                </Link>
              </ListItem>
            ))}
          </List>
        </Box>
        {
          process.env.REACT_APP_PLATFORM_ID && Number(process.env.REACT_APP_PLATFORM_ID) === 1 ? (
            <Flex sx={{ ...styles.loginWrap }}>
              {userLogged ? (<ProfileModal />) : (<LodginModal />)}
            </Flex>
          ) : 
          <Flex sx={{ ...styles.loginWrap }} onClick={includesSubDomain ? undefined : openModal}>
            <CiLocationOn size={20} />
            <Box>
              {city ? <Text as="p" mx="2">{city}</Text> : <Text as="p" mx={2}>Select City</Text>}
            </Box>
          { !includesSubDomain  && <ChevronDownIcon boxSize={6} />}
          </Flex>
        }
      </Flex>
    </Box>
  );
};

export default Header;
