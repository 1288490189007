import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'src/redux/hooks'
import {

    Box,

} from "@chakra-ui/react";
import { useAppDispatch } from 'src/redux/hooks';
import { DealerList } from './DealerList';
import '../../theme/slider.css';
import Cookies from 'js-cookie';
import getDealerDetails from 'src/api/getDealerDetails';



export default function GetDealer() {
    const dispatch = useAppDispatch();

    const [dealers, setDealers] = useState<[]>([])
    const [status, setStatus] = useState<string>("");

    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
 
    const dealerId = Number(Cookies.get('dealer'));
  
    useEffect(() => {
            if (dealerId) {
                const res = getDealerDetails(dealerId);
                res.then((data: any) => {
                    const dataArray = [data.data];
                    setDealers(dataArray as any);
                    setStatus(data.status);
                })
            }
    }, []);

    return (
       <Box>
            <DealerList dealers={dealers} status={status}/> 
        </Box>
    );
}
