
import { SUBDOMAIN } from 'src/constants'
import ibbApi from "./ibbApi";

const getSubDomain = () => {
    const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
    let headers: any = {};
    if (LSToken) {
        headers = {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${LSToken.token}`
        }
    }
    const res= ibbApi
        .get(
            `${SUBDOMAIN}`,
            {
                headers: headers
            }
        )
        .then((response) => 
        {
            return response.data.data}
            )
        .catch((error) => error)
        return res
};
export default getSubDomain






