import React, { FC } from 'react';
import {
    Box,
    Heading,
    Hide,
    Image,
    Show,
    // Link,
    useMultiStyleConfig,
    Button
} from '@chakra-ui/react'
import HTMLReactParser from 'html-react-parser';
import { Link as RouterLink } from 'react-router-dom';
import { useAppSelector } from 'src/redux/hooks';
import CarouselSlider from './CustomView/carouselSlider';

const HomeBanner: FC = ({
    ...rest
}) => {
    const styles = useMultiStyleConfig(`HomeBanner`)
    const containerStyle = useMultiStyleConfig(`Container`)
    const data = useAppSelector((state: any) => state.homePage.banner)
    return (
        <Box __css={{ ...styles.wrapper }} {...rest}>
            <Box as="figure" sx={{ ...styles.figure }}>
                <Hide above='md'>
                    {/* <Image
                        src={data.image.sm}
                        alt={process.env.REACT_APP_NAME}
                        sx={{ ...styles.image }}
                        objectFit="cover"
                    /> */}
                    <CarouselSlider images={data.image.sm} />
                </Hide>
                <Show above='md'>
                    <Show below='xl'>
                        {/* <Image
                            src={data.image.md}
                            alt={process.env.REACT_APP_NAME}
                            sx={{ ...styles.image }}
                        /> */}
                        <CarouselSlider images={data.image.md} />
                    </Show>
                </Show>
                <Show above='xl'>
                    {/* <Image
                        src={data.image.xl}
                        alt={process.env.REACT_APP_NAME}
                        sx={{ ...styles.image }}
                    /> */}
                    <CarouselSlider images={data.image.xl} />
                </Show>
            </Box>
            <Box sx={{ ...styles.caption, ...containerStyle.wrapper }}>
                {data.title ? <Heading as='h1' sx={{ ...styles.title }}>{HTMLReactParser(data.title)}</Heading> : null}
                {data.link ? <Button as={RouterLink} to={data.link.href} sx={{ ...styles.link }}>{data.link.text}</Button> : null}
            </Box>
        </Box>
    );
}

export default HomeBanner