import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import {HOME_DETAILS_API} from 'src/constants'

export const fetchCityInfo = createAsyncThunk(
    "city/fetchCityInfo",
    (cityId: number) => {
        const params = {
            "for": "all",
            "city": cityId,
            "budget": '0'
        }
        const res = oemApi
            .post(
                `${HOME_DETAILS_API}`,
                params
            )
            .then((response) => response.data.result)
            .catch((error) => error)
        return res
    }
);

const LSCityId = Number(localStorage.getItem('cityId'))

const cityInitialState = {
    status: "loading",
    openModal: LSCityId ? false : true,
    cityId: LSCityId ? LSCityId : null,
    cityName: null,
    recentStocks: [],
    stocks: [],
    error: {},
};


const citySlice = createSlice({
    name: "cityInfo",
    initialState: cityInitialState,
    reducers: {
        openCityModal: (state, action) => {
            state.openModal = action.payload;
        },
        updateCityId: (state, action) => {
            state.cityId = action.payload;
            localStorage.setItem('cityId', `${action.payload}`)
        },
    },
    extraReducers: {
        [fetchCityInfo.pending.type]: (state, action) => {
            state.status = "loading"
            state.cityName = null
            state.recentStocks = []
            state.stocks = []
            state.error = {}
        },
        [fetchCityInfo.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.cityName = action.payload.city_namee[0].city_name
            state.recentStocks = action.payload.latest_tractors
            state.stocks = action.payload.tractors_list
            state.error = {}
        },
        [fetchCityInfo.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.cityName = null
            state.recentStocks = []
            state.stocks = []
            state.error = action.payload
        },
    },
});

export const { openCityModal, updateCityId } = citySlice.actions;

export default citySlice.reducer;