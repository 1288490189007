// src/lib/meta.js

function initializeMeta(metaId = '389066024760021') {
    (function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
            n.callMethod ?
                n.callMethod.apply(n, arguments) : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = '2.0';
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
    }(window, document, 'script', 'https://connect.facebook.net/en_US/fbevents.js'));

    window.fbq('init', metaId);
    window.fbq('track', 'PageView');
    console.log("meta initialize");
}
function eventMeta(metaId = '389066024760021',track) {
    initializeMeta(metaId);
    window.fbq('track', track);
    console.log("event tracking meta");
}


export { initializeMeta, eventMeta };
