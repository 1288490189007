import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";

const grecaptcha = localStorage.getItem('_grecaptcha');

const initialState={
    openCaptchaModal:grecaptcha ? false :true
}
const captchaSlice = createSlice({
    name: 'captchaInfo',
    initialState,
    reducers: {
      openCaptchaModal: (state, action) => {
            state.openCaptchaModal = action.payload;
        },

    },
  })
  
  export const { openCaptchaModal } = captchaSlice.actions
  export default captchaSlice.reducer