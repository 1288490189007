import { combineReducers } from "@reduxjs/toolkit";
import commonReducer from '../reducers/commonReducer';
import filterReducer from '../reducers/filterReducer';
import stateListReducer from "./stateReducer"
import cityListSlice from "./cityListReducer"
import makeListReducer from "./makeListReducer";
import ibbTokenReducer from "./ibbTokenReducer";
import globalCityReducer from "./globalCityReducer";
import yearSlice from "./yearListReducer";
import productReducer from "./productReducer";
import dealerReducer from "./dealerReducer";
import userReducer from "./userReducer";
import homeReducer from "./homeReducer";
import modelListReducer from "./modelListReducer";
import customerSlice from "./CustomerFormReducer";
import priceListSlice from "./priceListReducer";
import kmListReducer from "./kmListReducer";
import constantSlice from "./constantReducer";
import productDetailsReducer from "./productDetailsReducer";
import compareReducer from "./compareReducer";
import filterBuyReducer from "./filterBuyReducer";
import compareListReducer from "./compareListReducer";
import captchaReducer from "./captchaReducer";
import subdomainReducer from "./subdomainReducer";
import { type } from "os";
import { TypeOf } from "yup";


export const rootReducer = combineReducers({
    commonData: commonReducer,
    filterData: filterReducer,
    stateList: stateListReducer,
    cities: cityListSlice,
    makes: makeListReducer,
    modelList: modelListReducer,
    ibbToken: ibbTokenReducer,
    yearList: yearSlice,
    globalCity: globalCityReducer,
    product: productReducer,
    dealer: dealerReducer,
    userData: userReducer,
    homePage: homeReducer,
    customer: customerSlice,
    priceList:priceListSlice,
    kmList:kmListReducer,
    constants:constantSlice,
    productDetails:productDetailsReducer,
    compare:compareReducer,
    buyFilterData:filterBuyReducer,
    compareList:compareListReducer,
    captcha:captchaReducer,
    subDomain:subdomainReducer
})

export type TAppState=ReturnType<typeof rootReducer>