import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Center,
    Button,
    useDisclosure,
    Grid,
    Box,
    useMultiStyleConfig,
    Heading,
    Checkbox,
    useToast, Drawer,
    DrawerBody,
    DrawerFooter,
    DrawerHeader,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    Link,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    GridItem,
    HStack, Image,
    Text
} from '@chakra-ui/react';
import React, { useEffect, useState, ChangeEvent, FormEvent, useMemo } from 'react';
import { openCustomerModal, customerInfo } from 'src/redux/reducers/CustomerFormReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate } from 'react-router-dom';
import { makeEnquiry } from 'src/redux/reducers/makeEnquiryReducer';
import { Link as RouterLink } from 'react-router-dom'



const styles = {
    title: {
        color: "gray",
        fontWeight: "medium",
        fontSize: { base: 'lg', xl: '3xl' },
        mb: { base: 4, xl: 6 },
        lineHeight: "tall",
        position: 'relative',
    },
    titleBorder: {
        display: { base: 'block', xl: 'none' },
        w: 36,
        borderBottom: '2px solid gray',
        pt: 2
    },
    text: {
        fontSize: { base: 'sm', xl: 'xl' },
        color: 'gray4',
        lineHeight: "tall",
        mb: 10
    },
    sectionBg: {
        bg: 'lightgray',
        pt: { base: 5, xl: 14 },
        pb: { base: 5, xl: 8 }
    },
    subTitle: {
        fontSize: { base: 'base', xl: 'black' },
        fontWeight: 'medium',
        mb: 6
    },
    formGrid: {
        gridTemplateColumns: { xl: 'repeat(2,1fr)' },
        rowGap: { base: 10, xl: 8 },
        columnGap: { xl: 16 },
        mb: { base: 10, xl: 24 }
    },
    formControl: {
        mb: 5,
        _last: {
            mb: 0
        }
    },
    // text-xs text-gray4 leading-snug
    notes: {
        fontSize: 'xs',
        color: 'gray4',
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
}

interface CompareProps {
    open: any;
    close: any;
    payload: any;
    handleRemoveCar: (leadId: any) => void
    maxLimit?:number
}
export const CompareDrawer: React.FC<CompareProps> = (props: any) => {
    const { close, open, payload, handleRemoveCar ,maxLimit} = props;
    const [width, setWidth] = useState<number>(window.innerWidth);

    // function handleWindowSizeChange() {
    //     setWidth(window.innerWidth);
    // }
    // useEffect(() => {
    //     window.addEventListener('resize', handleWindowSizeChange);
    //     return () => {
    //         window.removeEventListener('resize', handleWindowSizeChange);
    //     }
    // }, []);

    // const isMobile = width <= 768;
    // let maxLimit = isMobile ? 2 : 3;
    const dispatch = useAppDispatch();
    let payloadNew =  [...payload];
    let payloadLength = Math.abs(payload.length - maxLimit);
    for (let i = 0; i < payloadLength; i++) {
        payloadNew.push({});
    }
    let urlArray = payload.map((item: any) => item.lead_id)
    
    return (
        <Drawer placement={"bottom"} onClose={close} isOpen={open}>
            <DrawerOverlay />
            <DrawerContent>
                <DrawerHeader textAlign={"center"}>Compare cars</DrawerHeader>
                <DrawerBody p="20px">
                    <Grid templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(3, 1fr)" }} gap={6}>
                        {payloadNew && payloadNew.map((item: any, key: any) => {
                            return (
                                Object.entries(item).length > 0 ?
                                <>   <GridItem w='100%' h='30'>
                                        <HStack spacing={3}>
                                            <Box bg="lightgray" h='20' w="30" position={"relative"}>
                                                <Image src={item.image_url} alt="calendar" objectFit={'cover'} h='20' m="auto"/>
                                                <Box  bg="white" w={5} h={5} position={"absolute"} top={1} left={1} textAlign="center">{key+1}</Box>
                                            </Box>
                                            <Box >
                                                <Text
                                                    as={'h3'}
                                                    textTransform="capitalize"
                                                    fontSize="sm"
                                                    letterSpacing="wide"
                                                    color="secondary"
                                                    height={'20px'}
                                                    noOfLines={1}
                                                >
                                                    {item.make}{" "}{item.model}
                                                </Text>
                                                <Text
                                                    as={'h3'}
                                                    fontSize="sm"
                                                    letterSpacing="wide"
                                                    color="secondary"
                                                    mb={'10px'}
                                                    noOfLines={1}
                                                >
                                                    {item.year_of_manufacture + ' ' + item.variant}
                                                </Text>
                                                <Text textDecoration={"underline"} cursor="pointer" onClick={() => handleRemoveCar(item)}>Remove</Text>
                                            </Box>
                                        </HStack>
                                    </GridItem>
                                    </> 
                                    :
                                    <GridItem w='100%' h='30'>
                                        <HStack>
                                            <Box bg="lightgray" h='20' w="30">
                                                <Image src={`/images/${process.env.REACT_APP_THEME}/icons/car.svg`} alt="calendar" m={"auto"} boxSize={15} />
                                            </Box>
                                            <Box color="gray.500" as="p"> please choose another car to compare</Box>
                                        </HStack>
                                    </GridItem>
                            )
                        })}
                    </Grid>
                    <Center>
                        <Link
                            as={RouterLink}
                            to={`/compare-cars/${urlArray.join("/")}`}
                            style={{ textDecoration: 'none' }}>
                            <Button disabled={payload && payload.length <= 1 ? true : false}>Compare</Button>
                        </Link>
                    </Center>
                    <Center mt={3}>
                        {payload && payload.length == 1 && "One Car Added, Add one More To Compare"}
                        {payload && payload.length == 2 && "Two Cars Added, Click Button To Compare."}
                    </Center>
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}


