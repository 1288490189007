//import oemApi from 'src/api/oemApi';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import { IFilter } from 'src/commonTypes'
import { STOCK_LISTING_API,SEARCH_BUY_LOCAL } from "src/constants";
import ibbApi from "src/api/ibbApi";

// export const fetchStocks = createAsyncThunk(
//     "filter/fetchStocks",
//     (payload: any) => {
//         const res = oemApi
//             .post(
//                 STOCK_LISTING_API,
//                 payload
//             )
//             .then((response) => response.data)
//             .catch((error) => error)
//         return res
//     }
// )

// const filterInitialState: IFilter = {
//     status: "idle",
//     perPage: 12,
//     page: "all",
//     orderBy: "web_stock.selling_price",
//     orderByReverse: "asc",
//     pageType: 13,
//     aliasFields: "tb_leads.lead_id,tb_leads.lead_status,website_stock_images.image_url,web_stock.certification,vehicle_details.year_of_manufacture,vehicle_details.horse_power,vehicle_details.make,vehicle_details.model,vehicle_details.variant,vehicle_details.colour,(CASE WHEN vehicle_details.fuel_type='1' THEN 'Petrol' WHEN vehicle_details.fuel_type='2' THEN 'Diesel' WHEN vehicle_details.fuel_type='3' THEN 'Petrol+CNG' WHEN vehicle_details.fuel_type='4' THEN 'Petrol+LPG' WHEN vehicle_details.fuel_type='5' THEN 'Hybrid' WHEN vehicle_details.fuel_type='6' THEN 'Electric' ELSE vehicle_details.fuel_type END) as fuel_type,(CASE WHEN vehicle_details.no_of_veh_owner=1 THEN '1st' WHEN vehicle_details.no_of_veh_owner=2 THEN '2nd' WHEN vehicle_details.no_of_veh_owner=3 THEN '3rd' WHEN vehicle_details.no_of_veh_owner=4 THEN '4th' WHEN vehicle_details.no_of_veh_owner=5 THEN '5th' ELSE '' END) AS no_of_veh_owner,(case when user_dealer.city_id='NEW DELHI' then 'NCR-DELHI' when user_dealer.city_id='VISAKHAPATNAM' then 'VIZAG' else user_dealer.city_id end) as city_id,web_stock.selling_price",
//     leadStatus: ["13"],
//     yearRange: [],
//     priceRange: [],
//     states: [],
//     makes: [],
//     models: [],
//     dateRange: [],
//     customWhere: [],
//     customSearch: [],
//     data: [],
//     error: {}
// };

export const fetchStocks = createAsyncThunk(
    "filter/fetchStocks",
    (payload: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .post(
                `${SEARCH_BUY_LOCAL}`,
                payload,
                {
                    headers: headers
                }
            )
            .then((response) => {
                return response.data.data})
            .catch((error) => error)
        return res
    }
)


const filterInitialState: IFilter = {
    status: "idle",
    perPage: 6,
    page: 1,
    orderBy: "web_stock.selling_price",
    orderByReverse: "price_l2h",
    pageType: 13,
    aliasFields: "tb_leads.lead_id,tb_leads.lead_status,website_stock_images.image_url,web_stock.certification,vehicle_details.year_of_manufacture,vehicle_details.horse_power,vehicle_details.make,vehicle_details.model,vehicle_details.variant,vehicle_details.colour,(CASE WHEN vehicle_details.fuel_type='1' THEN 'Petrol' WHEN vehicle_details.fuel_type='2' THEN 'Diesel' WHEN vehicle_details.fuel_type='3' THEN 'Petrol+CNG' WHEN vehicle_details.fuel_type='4' THEN 'Petrol+LPG' WHEN vehicle_details.fuel_type='5' THEN 'Hybrid' WHEN vehicle_details.fuel_type='6' THEN 'Electric' ELSE vehicle_details.fuel_type END) as fuel_type,(CASE WHEN vehicle_details.no_of_veh_owner=1 THEN '1st' WHEN vehicle_details.no_of_veh_owner=2 THEN '2nd' WHEN vehicle_details.no_of_veh_owner=3 THEN '3rd' WHEN vehicle_details.no_of_veh_owner=4 THEN '4th' WHEN vehicle_details.no_of_veh_owner=5 THEN '5th' ELSE '' END) AS no_of_veh_owner,(case when user_dealer.city_id='NEW DELHI' then 'NCR-DELHI' when user_dealer.city_id='VISAKHAPATNAM' then 'VIZAG' else user_dealer.city_id end) as city_id,web_stock.selling_price",
    leadStatus: ["13"],
    yearRange: [],
    priceRange: [],
    states: [],
    makes: [],
    models: [],
    dateRange: [],
    customWhere: [],
    customSearch: [],
    data: [],
    error: {}
};

const filterSlice = createSlice({
    name: "filter",
    initialState: filterInitialState,
    reducers: {
        updatePerPage: (state, action: PayloadAction<number>) => {
            state.perPage = action.payload;
        },
        updatePage: (state, action: PayloadAction<string>) => {
            state.page = action.payload;
        },
        updateOrderBy: (state, action: PayloadAction<string>) => {
            state.orderBy = action.payload;
        },
        updateOrderByReverse: (state, action: PayloadAction<string>) => {
            state.orderByReverse = action.payload;
        },
        updatePageType: (state, action: PayloadAction<number>) => {
            state.pageType = action.payload;
        },
        updateYearRange: (state, action: PayloadAction<[]>) => {
            state.yearRange = action.payload;
        },
        updatePriceRange: (state, action: PayloadAction<[]>) => {
            state.priceRange = action.payload;
        },
        updateStates: (state, action) => {
            state.states = action.payload;
        },
        updateMakes: (state, action) => {
            state.makes = action.payload;
        },
        updateModels: (state, action: PayloadAction<[]>) => {
            state.models = action.payload;
        }
    },
    extraReducers: {
        [fetchStocks.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchStocks.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchStocks.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export const {
    updatePerPage,
    updatePage,
    updateOrderBy,
    updateOrderByReverse,
    updatePageType,
    updateYearRange,
    updatePriceRange,
    updateStates,
    updateMakes,
    updateModels
} = filterSlice.actions

export default filterSlice.reducer;


