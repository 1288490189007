import React, { FC } from 'react';
import {
    Box,
    Heading,
    Hide,
    Image,
    Show,
    useMultiStyleConfig,
} from '@chakra-ui/react'
import HTMLReactParser from 'html-react-parser';

const SellBanner: FC = ({
    ...rest
}) => {
    const styles = useMultiStyleConfig(`SellBanner`)
    const containerStyle = useMultiStyleConfig(`Container`)
    const banner = {
        image: {
            sm: `/images/${process.env.REACT_APP_THEME}/sell-banner@2x.jpg`,
            md: `/images/${process.env.REACT_APP_THEME}/sell-banner.jpg`,
            xl: `/images/${process.env.REACT_APP_THEME}/sell-banner-xl.jpg`,
        },
        title: 'Driven with smile <br/> Handles with care',
        text: 'Get the best Value for your Pre-Owned Car at Audi Approved <span class="em">:plus</span>',
    }
    return (
        <Box __css={{ ...styles.wrapper }} {...rest}>
            <Box as="figure" sx={{ ...styles.figure }}>
                <Hide above='md'>
                    <Image
                        src={banner.image.sm}
                        alt={process.env.REACT_APP_NAME}
                        sx={{ ...styles.image }}
                        objectFit="cover"
                    />
                </Hide>
                <Show above='md'>
                    <Show below='xl'>
                        <Image
                            src={banner.image.md}
                            alt={process.env.REACT_APP_NAME}
                            sx={{ ...styles.image }}
                        />
                    </Show>
                </Show>
                <Show above='xl'>
                    <Image
                        src={banner.image.xl}
                        alt={process.env.REACT_APP_NAME}
                        sx={{ ...styles.image }}
                    />
                </Show>
            </Box>
            <Box sx={{ ...styles.caption, ...containerStyle.wrapper }}>
                {banner.title ? <Heading as='h1' sx={{ ...styles.title }}>{HTMLReactParser(banner.title)}</Heading> : null}
                {banner.text ? <Box as='p' sx={{ ...styles.text }}>{HTMLReactParser(banner.text)}</Box> : null}
            </Box>
        </Box>
    );
}

export default SellBanner