//  <reference types="@react-google-maps/api" />

import React, { useEffect, useMemo, useState } from 'react';
import { GoogleMap, useJsApiLoader, MarkerClustererF, MarkerF, MarkerClusterer, Marker, MarkerProps, InfoWindow } from '@react-google-maps/api';

const containerStyle = {
    width: "100%",
    height: '500px'
};


export const DealerMap = ({ dealers }: any) => {
    const [markers, setMarkers] = useState<any>([]);
    const [newDealer, setNewDealer] = useState<any>([]);



    useEffect(() => {
        var newDealers = [...dealers];
        newDealers.forEach((element: { isOpen: boolean; }) => {
            element.isOpen = false;
        });
        setNewDealer(newDealers);
    }, [dealers]);

    const center = useMemo(() => {
        const newArray: { latitude: string, longitude: string }[] = dealers.map(({ latitude, longitude }: { latitude: string, longitude: string }) => ({ latitude, longitude }));
        if (newArray.length > 0) {
            const center2 = newArray.find(({ latitude, longitude }) => latitude && longitude);
            if (center2) {
                return ({ lat: parseFloat(center2.latitude), lng: parseFloat(center2.longitude) });
            }
        }
    }, [dealers]);

    const handleToggle = (marked: any, val: boolean) => {
        const selectedDealer = newDealer.map((item: any) => {
            if (item.id === marked) {
                return { ...item, isOpen: val }
            }
            return { ...item }
        })
        setNewDealer(selectedDealer);

    }

    const [map, setMap] = React.useState<any>(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "AIzaSyDA2t-9V5jQl-qMmI1LbiFpz3ZMthqub7k"
    })
    const onLoad = React.useCallback(function callback(map: any) {
        const bounds = new window.google.maps.LatLngBounds(center);
        map.fitBounds(bounds);
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map: any) {
        setMap(null)
    }, []);

    return isLoaded ? (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={center}
            zoom={6}
            onLoad={onLoad}
            onUnmount={onUnmount}
        >
            {newDealer.map((dealer: any) => {
                if (dealer.latitude && dealer.longitude) {
                    return (<Marker
                        key={dealer.id}
                        position={{ lat: parseFloat(dealer.latitude), lng: parseFloat(dealer.longitude) }}
                        title={dealer.name}
                        label={dealer.name}
                        onClick={() => handleToggle(dealer.id, true)}
                    >
                        {dealer.isOpen && (
                            <InfoWindow onCloseClick={() => handleToggle(dealer.id, false)}>
                                <span>{dealer.address}</span>
                            </InfoWindow>
                        )}
                    </Marker>)
                }
            })}
        </GoogleMap>
    ) : <></>
}
