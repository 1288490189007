
import { useEffect, useState } from "react";
import {
    Box,
    Grid,
    Text, Link, Image, Skeleton, VStack, Spinner, GridItem, Button, AspectRatio
} from "@chakra-ui/react";
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { LightBox } from "./LightBox";
import { Carousel } from 'react-responsive-carousel'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
// import {decode as base64_decode, encode as base64_encode} from '@types/base-64';
import ReactPlayer from 'react-player';
import { VideoModal } from "./videoModal";
import { BsPlayCircle } from "react-icons/bs";


interface IGallery {
    data: [],
    leadId: string,
    video: string
}

const ImageGallery = ({ data, leadId, video }: IGallery) => {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [photoIndex, setPhotoIndex] = useState<number>(0);
    const [lightboxImages, setLightboxImages] = useState<string[]>([]);
    const [isVideoOpen, setIsVideoOpen] = useState(false);
    const [lightboxImage, setLightboxImage] = useState<string | null>(null);
    const handleVideoClick = () => {
        setIsVideoOpen(true);
    };
    // Encode the String
    var encodedStringBtoA = btoa(leadId);

    useEffect(() => {
        let images = data?.map((item: any) => item.image_url) ?? [];
        setLightboxImages(images);
    }, [data]);

    useEffect(() => {
        if (lightboxImages && lightboxImages.length > 0) {
            setLightboxImage(lightboxImages[0]);
        }
    }, [lightboxImages]);

    const handlePhotoIndex1 = () => {
        setIsOpen(true)
        setPhotoIndex(0)
    }

    const handlePhotoIndex2 = (key: any) => {
        setPhotoIndex(key);
        setIsOpen(true)
    }
    const handlePhotoIndex3 = (key: any) => {
        setPhotoIndex(key);
        setIsOpen(true)
    }

    const handlePhotoIndex4 = () => {
        // setPhotoIndex(lightboxImages.length - 1);
        // setIsOpen(true)
        setIsVideoOpen(true);
    }
    const handlePhotoIndexVideo = (key: any) => {
        setIsOpen(true);
        setPhotoIndex(key);
    }

    return (
        <>
            {isOpen &&
                <LightBox lightboxImages={lightboxImages} setIsOpen={(e: any) => setIsOpen(e)} setPhotoIndex={(e: any) => setPhotoIndex(e)}
                    photoIndex={photoIndex} />
            }
            <Box mb={4} display={{ base: 'none', xl: 'block' }}>
                <Grid
                    gap={{ base: "5px", xl: "15px" }}
                    templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(4,minmax(0,1fr))" }}
                    templateRows={{ base: "repeat(1, 1fr)", xl: "repeat(2,1fr)" }}
                    cursor="pointer"
                >
                    {data.length !== 0 &&
                        data.map((item: any, key: number) => {
                            if (key === 0) {
                                return (
                                    <GridItem rowSpan={2} colSpan={2} bg='lightgray' onClick={handlePhotoIndex1}>
                                        {data.length !== 0 && <Image src={item.image_url} alt={item.id} w="full" h="full" objectFit="cover" display="block" />}
                                    </GridItem>
                                );
                            }
                            else if (key === 1) {
                                return (
                                    <>
                                        {video && <GridItem colSpan={1} bg="lightgray" onClick={() => handlePhotoIndex4()} position="relative">
                                            {lightboxImage && (
                                                <Image src={lightboxImage} alt="First Image" w="full" h="full" objectFit="cover" display="block" />
                                            )}
                                            <Box
                                                position="absolute"
                                                top="0"
                                                left="0"
                                                right="0"
                                                bottom="0"
                                                zIndex="1"
                                                bg="black"
                                                opacity="0.6"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box color="white">
                                                    <BsPlayCircle size={30} />
                                                </Box>
                                            </Box>
                                        </GridItem>}
                                        <GridItem colSpan={1} bg='lightgray' onClick={() => handlePhotoIndexVideo(key)}>
                                            <Image src={item.image_url} alt={item.id} w="full" h="full" objectFit="cover" display="block" />
                                        </GridItem>
                                    </>
                                );
                            }
                            else if (key >= 2 && key <= 3) {
                                return (
                                    <GridItem colSpan={1} bg='lightgray' onClick={() => handlePhotoIndex2(key)} position="relative">
                                        <Image src={item.image_url} alt={item.id} w="full" h="full" objectFit="cover" display="block" />
                                        {(video && key === 3) && lightboxImages && lightboxImages.length > 5 && (
                                            <Box
                                                position="absolute"
                                                top="0"
                                                left="0"
                                                right="0"
                                                bottom="0"
                                                zIndex="1"
                                                bg="black"
                                                opacity="0.7"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box color="white" fontWeight="bold" fontSize="16px">
                                                    + {lightboxImages.length - 4} More
                                                </Box>
                                            </Box>
                                        )}
                                    </GridItem>
                                );
                            } else if (!video && key === 4) {
                                return (
                                    <GridItem colSpan={1} bg='lightgray' onClick={() => handlePhotoIndex3(key)} position="relative">
                                        <Image src={item.image_url} alt={item.id} w="full" h="full" objectFit="cover" display="block" />
                                        {lightboxImages && lightboxImages.length > 5 && (
                                            <Box
                                                position="absolute"
                                                top="0"
                                                left="0"
                                                right="0"
                                                bottom="0"
                                                zIndex="1"
                                                bg="black"
                                                opacity="0.7"
                                                display="flex"
                                                justifyContent="center"
                                                alignItems="center"
                                            >
                                                <Box color="white" fontWeight="bold" fontSize="16px">
                                                    + {lightboxImages.length - 5} More
                                                </Box>
                                            </Box>
                                        )}
                                    </GridItem>
                                );
                            }
                        })}
                </Grid>
            </Box>
            <Box mb={4} display={{ base: 'block', xl: 'none' }} p={3}>
                <Carousel
                    showArrows={true}
                    autoPlay={true}
                    showIndicators={false}
                    showThumbs={true}
                    dynamicHeight={false}
                >
                    {
                        data && data.map((item: any, i: number) => (
                            <AspectRatio
                                ratio={1.618 / 1}
                                borderRadius={'10px 10px 0 0'}>
                                <img key={i} src={item.image_url} alt={item.id} />
                            </AspectRatio>
                        ))
                    }
                </Carousel>
               {video && <Button leftIcon={<ArrowForwardIcon className='card__actionIcon' transition={'margin .2s ease-in'} />} onClick={handlePhotoIndex4} variant={"blank"} color={'primary'} fontSize={'14px'} >View Video</Button>}
            </Box>
            <Box p={2} float="right">
                <Link href={`${process.env.REACT_APP_OEM_API_URL}/download-car-images-as-zip/${encodedStringBtoA}`} >
                    <Button transition={'margin .2s ease-in'} variant='primary' w={'full'} fontSize={'16px'} >
                        Download Images
                    </Button> </Link>
            </Box>
            {isVideoOpen && (
                <VideoModal
                    video={video}
                    setIsOpen={(e: any) => setIsVideoOpen(e)}
                    isVideoLightbox={true}
                />
            )}
        </>
    );
}

export default ImageGallery;