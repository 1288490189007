import { Box, Checkbox, FormControl, FormLabel, Text, VStack, Select } from '@chakra-ui/react';
import { ChangeEvent, FunctionComponent } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { updatefuelType,updatePage,updatePreviousData } from 'src/redux/reducers/filterBuyReducer';

const styles = {
    formControl: {
        mb: 10
    },
    scroller: {
        maxHeight: 200,
        overflowY: "scroll",
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        mb: 4
    }
}

interface IFFuelType {
    updateStocks?:(type:any,value:any)=>void
}

const FFuelType = ({ updateStocks}: IFFuelType) => {

    const dispatch = useAppDispatch()
    const fuelTypeList = useAppSelector((state: any) => state.constants.data.fuelType)
  

    const handleSubmit = (e: any) => {
    //    dispatch(updatefuelType(e.target.value));
    //    dispatch(updatePage(1));
    updateStocks &&  updateStocks("fuelType",e.target.value);
    //    dispatch(updatePreviousData([]));
    }
    return (
        <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.legend}>Fuel Type</FormLabel>
            <Select placeholder='Select Fuel Type' onChange={handleSubmit} variant='mySelect'>
                {
                    (fuelTypeList.length !== 0) ? fuelTypeList.map((item: any, index: number) => (<option key={index} value={item}>{item}</option>)) : (<option>Loading</option>)
                }
            </Select>
        </FormControl>
    );
}

export default FFuelType;