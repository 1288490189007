import React, { useState } from 'react';
import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Flex, Select, Text, Button, Image } from '@chakra-ui/react';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { fetchModelList } from 'src/redux/reducers/modelListReducer';
import { useNavigate } from 'react-router-dom';
import { updateMakeValue } from 'src/redux/reducers/commonReducer';


export const SearchSellBar = () => {
  const navigate = useNavigate();
  const makeList = useAppSelector((state: any) => state.makes.data);
  const modelList = useAppSelector((state: any) => state.modelList.data);

  const [make, setMake] = useState<string>("");
  const [model, setModel] = useState<string>("");

  const dispatch = useAppDispatch();

  const handleSubmit = () => {
    navigate('/sell', { state: { make, model } });
  }

  const handleMakeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedMake = e.target.value;
    setMake(selectedMake);
    dispatch(updateMakeValue(e.target.value));    

    if (selectedMake) {
      dispatch(fetchModelList(selectedMake));
    } else {
      setModel("");
    }
  }

  const handleModelChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedModel = e.target.value;
    setModel(selectedModel);
  }

  return (
    <Flex flexWrap={{ base: "wrap", xl: "nowrap" }} justifyContent={"center"}>
      <Box w={{ base: "100%", md: "auto" }} mb={{ base: "4", md: "0" }}>
        <Select placeholder="Make" variant="mySearch" onChange={handleMakeChange}>
          {
            (makeList.length !== 0) ? makeList.map((item: any, index: number) => (<option key={index} value={item}>{item}</option>)) : (<option>Loading</option>)
          }
        </Select>
      </Box>
      <Box w={{ base: "100%", md: "auto" }} mb={{ base: "4", md: "0" }}>
        <Select placeholder="Model" variant="mySearch" onChange={handleModelChange} isDisabled={make ? false : true}>

          {
            (modelList.length !== 0) ? modelList.map((item: any, index: number) => (<option key={index} value={item}>{item}</option>)) : (<option>Loading</option>)
          }
        </Select>
      </Box>
      <Button colorScheme="blue" variant="blank" onClick={handleSubmit}><Image src={`/images/${process.env.REACT_APP_THEME}/search-app-submit.png`} alt="waranty"></Image></Button>
    </Flex>

  )
}
