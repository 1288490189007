import { IProductPayload } from './../../commonTypes';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import { COMPARE } from "src/constants"


export const compareProduct = createAsyncThunk(
    "product/compareProduct",
    (leadList: any) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            "lead_ids": leadList,
            "platform": process.env.REACT_APP_PLATFORM_ID
        }
        const res = oemApi
            .post(
                COMPARE,
                params,
                {
                    headers: headers
                }
            )
            .then((res) => {
                return res.data.data})
            .catch((error) => error)
        return res
    }
)


const compareSlice = createSlice({
    name: "compare",
    initialState: {
        status: "idle",
        data: [],
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [compareProduct.pending.type]: (state) => {
            state.status = "loading"
            state.data=[]
            state.error = {}
        },
        [compareProduct.fulfilled.type]: (state, action: any) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [compareProduct.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data=[]
            state.error = action.payload
        },
 
    },
});


export default compareSlice.reducer;