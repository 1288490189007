import {
    Checkbox, FormControl, FormLabel, VStack,
    RangeSlider,
    RangeSliderTrack,
    RangeSliderFilledTrack,
    RangeSliderThumb,
    Box, Flex, Spacer
} from '@chakra-ui/react';
import { ChangeEvent, FunctionComponent, useState, useEffect } from 'react'
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
//import { updatePage, updateYearRange } from 'src/redux/reducers/filterReducer';
import { BiChevronLeftCircle } from "react-icons/bi";
import { BiChevronRightCircle } from "react-icons/bi";
import { updateMaxYear, updateMinYear ,updatePage,updatePreviousData} from 'src/redux/reducers/filterBuyReducer';

const styles = {
    formControl: {
        mb: 5
    },
    legend: {
        color: 'gray5',
        display: 'block',
        fontSize: { base: 'sm', xl: 'base' },
        mb: 2.5,
    },
    checkbox: {
        alignItems: "center",
        _hover: {
            color: "primary",
            borderColor: "primary70",
        }
    },
    vstack: {
        alignItems: 'flex-start',
        maxHeight: 200,
        overflowY: "scroll",
        mb: 4
    }
}

interface IFYear {
    updateStocks?:(type:any,value:any)=>void
}

const FYear = ({ updateStocks}: IFYear) => {

    const dispatch = useAppDispatch()
    const yearList = useAppSelector((state: any) => state.yearList.data)
    //const FYearRange = useAppSelector((state: any) => state.filterData.yearRange);
    //const[rangeValue, setRangeValue]=useState([]) ;
    const [minYear, setMinYear] = useState(yearList?.[yearList.length - 1]);
    const [maxYear, setMaxYear] = useState(yearList?.[0]);

    const handleChangePrice = (val: any) => {
        setMinYear(val[0]);
        setMaxYear(val[1]);
        // dispatch(updateMinYear(val[0]));
        // dispatch(updateMaxYear(val[1]));
        // dispatch(updatePage(1));
        updateStocks  && updateStocks("year",val);
        // dispatch(updatePreviousData([]));
    }

    useEffect(() => {
        setMinYear(yearList?.[yearList.length - 1]);
        setMaxYear(yearList?.[0]);
    }, [yearList])


    // const handleSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    //     const { value, checked }: any = e.target
    //     let setArr

    //     if (checked && !FYearRange.includes(value)) {
    //         setArr = [...FYearRange, value]
    //     } else {
    //         setArr = FYearRange.filter((ele: any) => ele !== value);
    //     }
    //     return dispatch(updateYearRange(setArr))
    // }

    return (
        <FormControl sx={styles.formControl}>
            <FormLabel sx={styles.legend}>Year</FormLabel>
            {/* <VStack sx={styles.vstack}>
                {yearList ? yearList.map((year: string, index: number) => (
                    <Checkbox
                        key={index}
                        value={year}
                        sx={styles.checkbox}
                        defaultChecked={FYearRange.includes(year) ? true : false}
                        onChange={(e) => handleSubmit(e)}
                    >
                        {year}
                    </Checkbox>
                )) : null}
            </VStack> */}
            <Box px={6}>
                <RangeSlider aria-label={['min', 'max']} defaultValue={[2007, 2023]} min={2007} max={2023} step={1} onChangeEnd={(val) => handleChangePrice(val)}>
                    <RangeSliderTrack bg='lightgray'>
                        <RangeSliderFilledTrack bg='gray5' />
                    </RangeSliderTrack>
                    <RangeSliderThumb boxSize={10} index={0}>
                        <Box color='gray5' as={BiChevronLeftCircle} boxSize={10} />
                    </RangeSliderThumb>
                    <RangeSliderThumb boxSize={10} index={1}>
                        <Box color='gray5' as={BiChevronRightCircle} boxSize={10} />
                    </RangeSliderThumb>
                </RangeSlider>
            </Box>
            <Flex>
                <Box px='4'>
                    {minYear}
                </Box>
                <Spacer />
                <Box px='4'>
                    {maxYear}
                </Box>
            </Flex>
        </FormControl>
    );
}

export default FYear;