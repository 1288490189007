import React, { } from 'react';
import { useAppSelector } from 'src/redux/hooks';
import {
    Box,
    Text,
    Grid,
    Flex,
    Button,
} from "@chakra-ui/react";
import AutoComplete from "../AutoComplete";

interface IData {
    sliderStep: number;
    nextSliderStep: () => void;
    setState: (params: string) => void;

}

export default function SliderState({ sliderStep, nextSliderStep, setState }: IData) {
    const stateList = useAppSelector((state: any) => state.stateList.data);
    const dataState: object[] = []
    stateList.filter((item: any) => dataState.push({ id: item.state_id, name: item.state_name }));
    const handleSubmit = (values: string) => {
        setState(values)
        nextSliderStep();
    };

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT STATE
            </Text>
            <AutoComplete
                data={dataState}
                cb={handleSubmit}
            />
            <Box overflowY={"scroll"}>
                <Grid templateColumns="repeat( 1fr)" gap="8px">
                    {
                        stateList.map((item: any) =>
                            <Button
                                key={item.state_id}
                                value={item.state_id}
                                variant="listBtn"
                                onClick={() => handleSubmit(item.state_name)}
                            >
                                {item.state_name}
                            </Button>
                        )
                    }
                </Grid>
            </Box>
        </Flex>
    );
}