import React from 'react'
import {
    Box,
    Grid,
    Text,
    Flex,
    Image
} from '@chakra-ui/react'
import { useAppSelector } from 'src/redux/hooks'
import TableGrayCard from '../TableGrayCard'
import DealerLeadModal from './LeadModal'

const styles = {
    wrapper: {
        bg: "white",
        boxShadow: "0 10px 40px 0 rgb(0 0 0 / 2%)",
        columnGap: ["30px", "30px"],
        p: [5, "30px"],
        borderRadius: "14px",
        gridTemplateColumns: "564px 1fr",
        mb: "60px"
    },
    title: {
        marginBottom: "18px",
        fontSize: "24px",
        color: 'dark',
        fontWeight: "semibold",
        lineHeight: '1.2'
    },
    button: {
        _hover: {
            boxShadow: '0 15px 15px 0 rgb(0 0 0 / 10%)',
            textDecoration: 'none',
        },
    },
    img: {
        w: "full",
        h: "540px",
        objectFit: "cover"
    },
}
const dummyDealerImg = 'https://mahabirautomobiles.com/index/img/sworaj/logonew.png';


export default function Hero() {
    const data = useAppSelector((state: any) => state.dealer.current.data)
    const dealerData = [
        {
            label: 'Address',
            value: `${data.address}, ${data.master_city_name} ${data.master_state_name}`,
        },
        {
            label: 'Email Id',
            value: data.email,
        },
        {
            label: 'Contact',
            value: data.mobile,
        },
        {
            label: 'State',
            value: data.master_state_name,
        },
        {
            label: 'District',
            value: data.master_city_name,
        },
    ]
    return (
        <Grid sx={styles.wrapper}>
            <Image sx={styles.img} src={data.avatar_image ? data.avatar_image : dummyDealerImg} />
            <Box>
                <Text as="h1" sx={styles.title}>{data.name}</Text>
                <Box mb="30px">
                    <TableGrayCard title="" data={dealerData} />
                </Box>
                <Flex>
                <DealerLeadModal dealerName={data.name} image={data.avatar_image ? data.avatar_image : dummyDealerImg} address={`${data.address}, ${data.master_city_name} ${data.master_state_name}`} dealerId={Number(data.id)} />
                </Flex>
            </Box>
        </Grid>
    )
}
