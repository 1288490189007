import React from 'react';
import {
    Box,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
} from '@chakra-ui/react';

export const PrivacyPolicy = () => {
    return (
        <Box bg={"sectionGray"} px={{ base: "bleed", xl: "bleed-xl" }} pb="60px" pt={"40px"}>
            <Text as="h1" fontSize={"3rem"} fontWeight={"bold"} mb={"30px"} textAlign={"center"}>
                Privacy Policy
            </Text>
            <Accordion allowToggle>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Code of practice concerning the collection, processing, transmission and use of data
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            To ensure that vehicle orders, financial, insurance and support services as well as servicing are all handled properly and quickly, AUDI AG and Audi partners make use of electronic data processing (EDP). The German Data Protection Act (BDSG) essentially governs how your personal data that are disclosed to the Audi partner, AUDI AG and the Audi Bank are processed. Accordingly, data may be collected, processed, transmitted and used (hereinafter: the use of data) if permitted by the BDSG or another statutory provision or if the person in question has given his or her consent. The BDSG permits the use of data in particular when this is needed to fulfil a contract agreed with you.
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            For certain purposes, which go above and beyond the actual contractual and support relationship, your specific contract or the reply coupons used in advertising campaigns run by Audi partners, AUDI AG and the Audi Bank incorporate a declaration of consent under data protection law. If, once the contract in question has been concluded, this declaration of consent is cancelled, in whole or in part, or if it is revoked at a later point in time, Audi partners, AUDI AG and the Audi Bank shall be obliged to use your data solely to the extent permissible by law.
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            You will find further details on the collection, storage, transmission and use of your customer data below:
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"5px"} fontWeight={"bold"} >
                            Data collection and storage at Audi partners
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"} >
                            The Audi partner will store any data required for ordering your new car or for rendering financial, insurance and support services as well as for servicing. Such data will initially include information in purchase, leasing, service, financing and insurance contracts. Moreover, data relating to processing transactions, such as the customer number and purchase price, shall be maintained, and records of vehicle-specific data and services rendered as part of vehicle servicing shall be collected and processed.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Information on data protection
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            For AUDI AG, the protection of personal data is of utmost importance. We would therefore like to inform you how we protect your privacy when you give your personal data to us. It goes without saying that we shall comply with data protection regulations, but in addition, in giving you the following information, we would like to commit ourselves to using your data in a responsible way, making sure that your privacy is protected at any time. We want you to feel good and safe when visiting our web pages.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    General information
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            If you visit the web pages of AUDI AG, information of general nature is collected automatically. This information includes, for example, such things as the kind of web browser, the operating system which is used, or the domain names of your internet service providers. Exclusively such information is collected which does not allow any conclusions as to your identity. Besides, these data are also generated when visiting any other web page on the internet, i. e. this is not a function specific of the Audi internet pages. This kind of information is collected exclusively in an anonymizated way and interpreted by us for statistical purposes. The better we understand what you want, the faster you will find the desired information on our internet pages.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Personal information
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            If you are asked to give personal information on our pages, such as for example your name, address or phone number, this is subject to special provisions, which are indicated to you in the following wording: We will use these data for marketing purposes for AUDI AG, to attract prospective customers, conduct surveys and provide information. Your data will not be passed on to any third parties. This shall not apply to service providers acting on behalf of AUDI AG, or third parties such as the Audi trade organization and affiliates and subsidiary companies of the Audi group. Apart from the data which you give to us, we will use information derived from the way in which you use our service to be able to lead you to the information which could be interesting for you as fast as possible, and to constantly optimize our service. You will find further information on the subject of the protection of data privacy under the rubric "data protection" on our internet pages.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    The use of cookies
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            Cookies are text files containing information which makes it possible to identify repeated visitors exclusively for the duration of their visit to our web pages. Cookies are stored on the hard disk of your computer and do not cause any damage there. The cookies of our internet pages do not contain any personal data on you. Cookies will save you having to enter data several times, facilitate the transmission of specific contents and will help us to identify those parts of our online service that are particularly popular. This will enable us, among other things, to adapt our web pages exactly to your requirements. If you want, you can deactivate the use of cookies any time by changing the preferences of your browser. Please use the help functions of your internet browser to see how you can make these changes.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Security
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            AUDI AG employs technical and organizational security measures to protect the personal data you give to us from any deliberate or undeliberate manipulation, loss, destruction or access by unauthorized persons. Our security measures are improved and adapted on a regular basis according to the state of the art.
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Contact
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            If you have any queries, requests or complaints on the subject of data protection, please do not hesitate to contact our data protection officer:
                        </Text>
                        <Box fontStyle={"italic"} my={"20px"} ml={"10px"}>
                            <Text as="h5" fontSize={"1.2rem"} fontWeight={"bold"}>Audi India</Text>
                            <Text as="p" fontSize={"1rem"}>3, North Avenue, Level 3
                                <br />
                                Maker Maxity, Bandra Kurla Complex
                                <br />
                                Bandra (East), Mumbai 400 051
                                <br />
                                <br />
                                Phone: +91 022 4054 7295
                            </Text>
                        </Box>
                    </AccordionPanel>
                </AccordionItem>
                <AccordionItem border={"none"} mb={"20px"}>
                    <h2 style={{ background: "rgba(245,245,245,1)" }}>
                        <AccordionButton _expanded={{ bg: 'rgba(245,245,245,1)' }}>
                            <Box flex='1' textAlign='left'>
                                <Text as="h3" fontSize={"1.4rem"} fontWeight={"bold"} >
                                    Public notification procedure (4g and 4e of the BDSG - Federal Data)
                                </Text>
                            </Box>
                            <AccordionIcon />
                        </AccordionButton>
                    </h2>
                    <AccordionPanel py={4}>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            The data protection officer herewith makes the information required by law available to everybody.
                        </Text>
                        <Text as="p" fontSize={"1rem"} fontWeight={"bold"}>
                            Name of the responsible corporate body:
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            AUDI AG
                        </Text>
                        <Text as="p" fontSize={"1rem"} fontWeight={"bold"} >
                            Managing Board:
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"} >
                            Rupert Stadler (Chairman of the Board) Ulf Berkenhagen Michael Dick Frank Dreves Peter Schwarzenbauer Axel Strotbek Werner Widuckel
                        </Text>
                        <Text as="p" fontSize={"1rem"} fontWeight={"bold"}>
                            Data Processing:
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            Manager in charge: Klaus Straub
                        </Text>
                        <Text as="p" fontSize={"1rem"} fontWeight={"bold"}>
                            Address of the responsible corporate body:
                        </Text>
                        <Text as="p" fontSize={"1rem"} mb={"20px"}>
                            AUDI AG
                        </Text>
                    </AccordionPanel>
                </AccordionItem>
            </Accordion>
        </Box>
    )
}
