import { createSlice } from "@reduxjs/toolkit";


const initialState = {
  priceRange: [
    {
      value: {
        min: 0,
        max: 300000
      },
      name: '0 Lakh - 3 Lakh',
    }, {
      value: {
        min: 300000,
        max: 600000
      },
      name: '3 Lakh - 6 Lakh',
    }, {
      value: {
        min: 600000,
        max: 900000
      },
      name: '6 Lakh - 9 Lakh',
    }, {
      value: {
        min: 900000,
        max: 1200000
      },
      name: '9 Lakh - 12 Lakh',
    }, {
      value: {
        min: 1200000,
        max: null
      },
      name: 'Above 12 Lakh'
    }
  ],
  condition: [
    {
      value: 1,
      label: "0-25% (Poor)"
    },
    {
      value: 2,
      label: "26-50% (Average)",
    },
    {
      value: 3,
      label: "51-75% (Good)"
    },
    {
      value: 4,
      label: "76-100% (Very Good)"
    },
  ],
  hours: [
    "Less than 1000 hours",
    "1001 - 2000 hours",
    "2001 - 3000 hours",
    "3001 - 4000 hours",
    "4001 - 5000 hours",
    "5001 - 6000 hours",
    "6001 - 7000 hours",
    "7001 - 8000 hours",
    "8001 - 9000 hours",
    "9001 - 10,000 hours",
    "Above 10,000 hours",
    "Not Available",
  ],
  nav: [
    {
      id: 1,
      title: "Home",
      href: "/",
    },
    {
      id: 2,
      title: "Buy",
      href: "/buy",
    },
    {
      id: 3,
      title: "Sell",
      href: "/sell",
    },
    // {
    //   id: 3,
    //   title: "Exchange",
    //   href: "/exchange",
    // },
    // {
    //   id: 4,
    //   title: "Valuation",
    //   href: "/evalution",
    // },
    {
      id: 5,
      title: "Dealership Locator",
      href: "/dealers",
    },
    // {
    //   id: 6,
    //   title: "Contact Us",
    //   href: "/contact",
    // },
  ],
  footer: {
    nav: [
      {
        id: 1,
        title: "Home",
        href: "/",
      },
      {
        id: 2,
        title: "Buy",
        href: "/buy",
      },
      {
        id: 3,
        title: "Sell",
        href: "/sell",
      },
      {
        id: 4,
        title: "Dealership Locator",
        href: "/dealers",
      },
      {
        id: 5,
        title: "About us",
        href: "/about",
      },
      {
        id: 5,
        title: "Terms & Conditions",
        href: "/tnc",
      },
      {
        id: 5,
        title: "Privacy Policy",
        href: "/privacy-policy",
      },
    ]
  },
  maxLimit:3,
  includesSubDomain:false,
  modelValue:"",
  priceValue:"50000000",
  sortOrder:"",
  makeValue:"",
};

const commonSlice = createSlice({
  name: "commonInfo",
  initialState: initialState,
  reducers: {
    updateMaxLimit: (state, action) => {
      state.maxLimit = action.payload;
  },
   updateSubdomain: (state, action) => {
    state.includesSubDomain = action.payload;
    localStorage.setItem('includesSubDomain', action.payload);
  },
  updateModalValue: (state, action) => {
    state.modelValue = action.payload;
  },
  updatePriceValue: (state, action) => {
    state.priceValue = action.payload;
  }, 
   updateSortOrder: (state, action) => {
    state.sortOrder = action.payload;
  },
  updateMakeValue: (state, action) => {
    state.makeValue = action.payload;
  }
  },
  extraReducers: {},
});

export const { updateMaxLimit,updateSubdomain,updateModalValue,updatePriceValue,updateSortOrder,updateMakeValue} = commonSlice.actions;
export default commonSlice.reducer;
