import { IProductPayload } from './../../commonTypes';
import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import oemApi from "src/api/oemApi";
import { GET_PRODUCT } from "src/constants"


export const fetchProductDetails = createAsyncThunk(
    "product/fetchProduct",
    (leadId: number) => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const params = {
            "lead_id": leadId,
            "platform": process.env.REACT_APP_PLATFORM_ID
        }
        const res = oemApi
            .get(
                `${GET_PRODUCT}?platform=${process.env.REACT_APP_PLATFORM_ID}&lead_id=${leadId}`,
               // params,
                {
                    headers: headers
                }
            )
            .then((res) => {
                return res.data.data})
            .catch((error) => error)
        return res
    }
)


const productDetailsSlice = createSlice({
    name: "product",
    initialState: {
        status: "idle",
        data: {
            vechicleDetails: {},
            Images: [],
            dealer: {},
            stockDetails:{}
        },
        error: {},
    },
    reducers: {
    },
    extraReducers: {
        [fetchProductDetails.pending.type]: (state) => {
            state.status = "loading"
            state.data.vechicleDetails = {}
            state.data.Images=[]
            state.data.dealer={}
            state.data.stockDetails={}
            state.error = {}
        },
        [fetchProductDetails.fulfilled.type]: (state, action: any) => {
            state.status = "success"
            state.data.vechicleDetails = action.payload.vehicle_details
            state.data.Images=action.payload.stock_images
            state.data.dealer=action.payload.dealer
            state.data.stockDetails=action.payload.stock_details
            state.error = {}
        },
        [fetchProductDetails.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data.vechicleDetails = {}
            state.data.Images=[]
            state.data.dealer={}
            state.data.stockDetails={}
            state.error = action.payload
        },
 
    },
});

export default productDetailsSlice.reducer;