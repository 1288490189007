import { Box } from '@chakra-ui/react';
import React from 'react';

const SalesConsultantAppDownload = () => {
    return (
        <Box p="20px">
            <Box fontSize={"18px"} fontWeight={"Bold"} marginBottom={"10px"} >Please follow the steps below to install the app</Box>
            <span>
                <p>
                    1.{' '}
                    <a
                        href="itms-services://?action=download-manifest&url=https://vw-prod.s3.ap-south-1.amazonaws.com/audi_sales_consultant_app/manifest.plist"
                        id="text"
                    >
                        Click here to download the app.
                    </a>
                </p>
                <p>2. Go to Settings -&gt; General -&gt; Profiles &amp; Device Management.</p>
                <p>3. Click on Volkswagen Group.</p>
                <p>4. Click on Trust "Volkswagen Group Sales India Private Limited".</p>
                <p>5. The app is installed.</p>
            </span>
            <img src="/images/Untrusted_deve.jpg" alt="Untrusted Development" />
        </Box>
    );
};

export default SalesConsultantAppDownload;