import { ChangeEvent, FunctionComponent,useState ,useEffect } from 'react'
import { Box, Button, Flex, Select, Text, VisuallyHidden } from '@chakra-ui/react';
import FilterIcon from '../icons/Filter';
import { updateSortOrder } from 'src/redux/reducers/commonReducer';
import { useAppDispatch,useAppSelector} from 'src/redux/hooks';



const styles = {
    header: {
        justifyContent: 'space-between',
        alignContent: 'center',
        position: { base: 'sticky', xl: 'relative' },
        left: 0,
        top: { base: '70px', xl: 0 },
        w: 'full',
        columnGap: { base: 6, xl: 13 },
        pt: { base: 5, xl: 10 },
        pb: { base: 5, xl: 0 },
        mb: { base: 2.5, xl: 8 },
        zIndex: '10',
        bg: 'white'
    },
    title: {
        fontFamily: 'heading',
        fontSize: { base: 'base', xl: '3xl' },
        fontWeight: 'bold',
        lineHeight: 'tall',
        color: 'secondary',
    },
    result: {
        color: 'secondary'
    },
    filterBtn: {
        borderWidth: '1px',
        borderStyle: "solid",
        borderColor: 'inputBorderClr',
        height: 10,
        p: 2,
        display: { base: 'block', xl: 'none' },
        _focus: {
            borderColor: 'inputBorderHoverClr',
        }
    }
}

interface BuyHeaderProps {
    showFilter: () => void
    stocks:{
    [key: string]: any;
    }
    updateStocks:( type:any,value:any)=> void
    
}

const BuyHeader: FunctionComponent<BuyHeaderProps> = ({ showFilter,stocks,updateStocks}) => {
    const dispatch = useAppDispatch();
    const sortValue = useAppSelector((state: any) => state.commonData.sortOrder);
    const [sorted, setSorted] = useState<string>("");
    useEffect(()=>{
        setSorted(sortValue)
         },[sortValue]);


    const sortBy = (e: ChangeEvent<HTMLSelectElement>) => {
          updateStocks("sortOrder", e.target.value);
          dispatch(updateSortOrder(e.target.value));        
    }
    const sortOrder: any = [
        { key:"age_h2l" , value: "Age High to Low" },
        { key:"age_l2h" , value: "Age Low to High" },
        { key: "price_h2l" , value: "Price High to Low" },
        { key:"price_l2h", value:"Price Low to High" }    
    ];


    return (
        <Flex sx={styles.header}>
            <Box>
                <Text as="h1" sx={styles.title}>Used cars</Text>
                <Text sx={styles.result}>  {stocks.total} results</Text>
            </Box>
            <Flex columnGap={6} alignContent="center">
                <Select onChange={(e) => sortBy(e)} placeholder="Sort By"  variant='mySelect'>
                 {sortOrder.map((item:any,key:any)=>{
                   return  <option value={item.key}  selected={item.key==sorted}key={key}>{item.value}</option> 
                 })}
                    {/* <option value={"asc"}> Low - High</option>
                    <option value={"desc"}> High -Low </option> */}
                </Select>
                <Button variant='blank' sx={styles.filterBtn} onClick={showFilter}>
                    <FilterIcon fontSize={'20px'} />
                    <VisuallyHidden>Filter</VisuallyHidden>
                </Button>
            </Flex>
        </Flex>
    );
}

export default BuyHeader;