import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
// import oemApi from "src/api/oemApi";
import ibbApi from "src/api/ibbApi";
import { STATE_API } from 'src/constants'

const params = {
    "for": "state",
    "platform": process.env.REACT_APP_PLATFORM_ID
}

export const fetchStateList = createAsyncThunk(
    "state/fetchStates",
    () => {
        const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
        let headers: any = {};
        if (LSToken) {
            headers = {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${LSToken.token}`
            }
        }
        const res = ibbApi
            .get(
                `${STATE_API}?platform=${process.env.REACT_APP_PLATFORM_ID}&is_subdomain=false`,
                // params,
                {
                    headers: headers
                }
        )
            .then((response) => response.data.data)
            .catch((error) => error)
        return res
    }
);

const stateInitialState = {
    status: "idle",
    data: [],
    error: {},
};

const stateListSlice = createSlice({
    name: "stateList",
    initialState: stateInitialState,
    reducers: {},
    extraReducers: {
        [fetchStateList.pending.type]: (state, action) => {
            state.status = "loading"
            state.data = []
            state.error = {}
        },
        [fetchStateList.fulfilled.type]: (state, action: PayloadAction<any>) => {
            state.status = "success"
            state.data = action.payload
            state.error = {}
        },
        [fetchStateList.rejected.type]: (state, action: PayloadAction<object[]>) => {
            state.status = "error"
            state.data = []
            state.error = action.payload
        },
    },
});

export default stateListSlice.reducer;