import { SearchBuyBar } from 'src/components/Home/searchBuyBar';
import { SearchSellBar } from 'src/components/Home/searchSellBar';
import { Flex, Box, Stack, Text } from '@chakra-ui/react';
import { Accordion, AccordionItem, AccordionButton, AccordionPanel, AccordionIcon } from '@chakra-ui/react';
import React, { useState } from 'react';

export const SearchBar = () => {
    return (    
        <Accordion allowToggle>
            <Flex flexWrap="wrap" mb="5">
                <Box width={{ base: "100%", xl: "50%" }} mb={{ base: "5", xl: "0" }} px={{ base: "bleed", xl: "bleed-xl" }}>
                    <AccordionItem border={"none"}>
                        <h2>
                            <AccordionButton justifyContent="flex-start" _hover={{ bg: 'primary', color: 'white' }}
                                bg="black"
                                color="white"
                                _focus={{ boxShadow: 'none' }}>
                                <Box flex="1" textAlign="center">
                                    <Text as={'h3'}
                                        fontSize={{ base: "15px", xl: "20px" }}
                                        fontWeight={'bold'}
                                        letterSpacing="wide"
                                    >
                                        I am looking for an Audi
                                    </Text>
                                </Box>
                                <AccordionIcon color={"white"} />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <SearchBuyBar />
                        </AccordionPanel>
                    </AccordionItem>
                </Box>
                <Box width={{ base: "100%", xl: "50%" }} mb={{ base: "5", xl: "0" }} px={{ base: "bleed", xl: "bleed-xl" }}>
                    <AccordionItem border={"none"}>
                        <h2>
                            <AccordionButton justifyContent="flex-start" _hover={{ bg: 'primary', color: 'white' }}
                                bg="black"
                                color="white"
                                _focus={{ boxShadow: 'none' }}>
                                <Box flex="1" textAlign="center">
                                    <Text as={'h3'}
                                        fontSize={{ base: "15px", xl: "20px" }}
                                        fontWeight={'bold'}
                                        letterSpacing="wide"
                                    >
                                        I am looking to sell/exchange my Car
                                    </Text>
                                </Box>
                                <AccordionIcon color={"white"} />
                            </AccordionButton>
                        </h2>
                        <AccordionPanel>
                            <SearchSellBar />
                        </AccordionPanel>
                    </AccordionItem>
                </Box>
            </Flex>
        </Accordion>
        
    )
}
