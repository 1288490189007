// import React from 'react';
import { createRoot } from "react-dom/client";
import { Provider } from 'react-redux';
import { store } from './redux/store';
import { AppRouter } from "./AppRouter";


const container = document.getElementById("app") as HTMLElement;
const root = createRoot(container);
root.render(
  <Provider store={store}>
    <AppRouter/>
      </Provider>
);
