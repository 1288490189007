import React from 'react';
import { useState } from "react";
import {
    Input,
    InputGroup,
    InputRightElement,
    List,
    ListItem,
} from '@chakra-ui/react'
import SearchIcon from "./icons/Search";
interface IAutoComplete {
    data: object[]
    cb?: (id: any, name:any) => void
}

const AutoComplete = ({ data, cb }: IAutoComplete) => {
    const [active, setActive] = useState<number>(0);
    const [filtered, setFiltered] = useState<any[]>([]);
    const [isShow, setIsShow] = useState<boolean>(false);
    const [input, setInput] = useState<string>("");

    const onChange = (e: any): void => {
        const input = e.currentTarget.value;
        const newFilteredSuggestions = data.filter((data: any) => data.name.toLowerCase().indexOf(input.toLowerCase()) > -1);
        setActive(0);
        setFiltered(newFilteredSuggestions);
        setIsShow(true);
        setInput(e.currentTarget.value);
    };

    const onFocus = (e: any): void => {
        setActive(0);
        setFiltered(data);
        setIsShow(true);
    };

    

    const onClick = (e: any, id: any,name:any):void => {
        setActive(0);
        setFiltered([]);
        setIsShow(false);
        setInput(e.currentTarget.innerText);
        if (cb) {
           cb(id,name);
        }
    };

    const onKeyDown = (e: any): void | null => {
        if (e.keyCode === 13) { // enter key
            setActive(0);
            setIsShow(false);
            if (filtered[active]) {
                setInput(filtered[active].name); 
                if (cb) {
                    cb(filtered[active].id, filtered[active].name);
                }
            }          
        }
        else if (e.keyCode === 38) { // up arrow
            return (active === 0) ? null : setActive(active - 1);
        }
        else if (e.keyCode === 40) { // down arrow
            return (active - 1 === filtered.length) ? null : setActive(active + 1);
        }
    };
    const renderAutocomplete = () => {
        if (isShow ) {
            if (filtered.length) {
                return (
                    <List maxH={"220px"} overflowY="scroll" position={"absolute"} py="2" top="100%" width={"100%"} zIndex="99" bg={"bodyBg"} border="1px" borderColor={"lightgray"} borderRadius={"0 0 6px 6px"}>
                        {filtered.map((item: any, index) => {
                            return (
                                <ListItem py="2" px="4" cursor={"pointer"} bg={(index === active) ? 'primary' : ''} color={(index === active) ? 'white' : 'textClr'} key={index}
                                    onClick={(e) => onClick(e, item.id,item.name)}
                                    _hover={
                                        {
                                            bg: "lightgray",
                                            color: "primary"
                                        }
                                    }>
                                    {item.name}
                                </ListItem>
                            );
                        })}
                    </List>
                );
            } else {
                return (
                    <List maxH={"220px"} overflowY="scroll" position={"absolute"} py="2" top="100%" width={"100%"} zIndex="99" bg={"bodyBg"} border="1px" borderColor={"lightgray"} borderRadius={"0 0 6px 6px"}>
                        <ListItem py="2" px="4" bg='red' color={'white'} >Not found</ListItem>
                    </List>
                );
            }
        }
        return <></>;
    }
    return (
        <>
        <Input type='text' display={"none"}></Input>
            <InputGroup mb={4}>
                <InputRightElement
                    pointerEvents='none'
                ><SearchIcon color="gray.500" /></InputRightElement>
                <Input
                    type="text"
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    onFocus={onFocus}
                   value={input} 
                    />
                {renderAutocomplete()}
            </InputGroup>
        </>
    );
}

export default AutoComplete;