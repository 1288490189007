
import React, { useState, MouseEventHandler, useEffect, forwardRef } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import {
    Box,
    AspectRatio,
    Stack,
    Text,
    Link,
    Button,
    Image,
    useDisclosure,
    useToast,
    HStack,
    Grid,
    useUpdateEffect,
    Skeleton, Flex
} from '@chakra-ui/react'
import { currencyFormatter } from 'src/lib/currencyFormatter'
import { ArrowForwardIcon } from '@chakra-ui/icons';
import { CiLocationOn } from "react-icons/ci";
import { IoAddCircleOutline, IoAddCircleSharp } from "react-icons/io5";
import { MakeEnquiry } from '../Product/makeEnquiry';
import { MakeEnquiryModal } from '../Product/makeEnquiryModal';
import { useAppSelector } from 'src/redux/hooks';
import { BsSpeedometer2 } from "react-icons/bs";

export const ProductCard = ({ product, handleCompare }: any) => {

    const enqModal = useDisclosure();
    const toast = useToast();
    const [payload, setPayload] = useState<any>({
        make: "",
        city: "",
        variant: "",
        model: "",
        leadId: "",
        dealerId: "",
    });


    const handleOpenEnquiry = (item: any) => {
        let newPayload = { ...payload };
        newPayload.make = item.make;
        newPayload.city = item.city;
        newPayload.variant = item.variant;
        newPayload.model = item.model;
        newPayload.leadId = item.lead_id;
        newPayload.dealerId = item.dealer_id;
        setPayload(newPayload);
        enqModal.onOpen();
    }

    return (
        <>
            <Grid
                gap={{ base: "0px", xl: "10px" }}
                templateColumns={{ base: "repeat(1, 1fr)", xl: "repeat(2, 1fr)" }}
            >

                {product && product.map((item: any, key: any) => (
                    <Box
                        display={'block'}
                        bg={'white'}
                        borderRadius={'10px'}
                        boxShadow={'0 10px 40px 0 rgb(0 0 0 / 2%)'}
                        transition={'box-shadow .2s ease-in'}
                        textDecoration={'none'}
                        overflow={'hidden'}
                        _hover={{
                            boxShadow: '0 40px 40px 0 rgb(0 0 0 / 8%)',
                            textDecoration: 'none',
                            '& .card__actionIcon': {
                                ml: { xl: '20px' }
                            }
                        }}
                    >
                        <Link
                            as={RouterLink}
                            to={`/vehicle-details/${btoa(item.lead_id)}`}

                        >
                            <Box position="relative">
                                <AspectRatio
                                    ratio={1.618 / 1}
                                    borderRadius={'10px 10px 0 0'}>
                                    <Image
                                        objectFit={'cover'}
                                        src={item.image_url}
                                        alt={item.lead_id}
                                    />
                                </AspectRatio>
                                {item.certification == "Yes" && <Box as="span"
                                    transform={"rotate(-38deg) translate(-5%,-92%)"} fontWeight="bold" fontSize="md" bg={"primary"} color={"white"} px={"30px"} zIndex={1} position={"absolute"} top={"2rem"} left={0}> Certified</Box>}
                                {item.lead_status == "14" && <Box as="span"
                                    top={0}
                                    right={0} zIndex={1} position={"absolute"} ><Image src={`/images/${process.env.REACT_APP_THEME}/Booked.svg`} alt="booked" height="26px" /></Box>}
                            </Box>
                        </Link>
                        <Box p={'10px 20px'}>
                            <Link
                                as={RouterLink}
                                to={`/vehicle-details/${btoa(item.lead_id)}`}
                                style={{ textDecoration: 'none' }}>
                                <Text
                                    textTransform="capitalize"
                                    fontSize="18px"
                                    fontWeight={'bold'}
                                    color="secondary"
                                    mb={'5px'}
                                >{`${item.make} ${item.model}`}</Text>
                                <Text
                                    as={'h3'}
                                    textTransform="capitalize"
                                    fontSize="md"
                                    letterSpacing="wide"
                                    color="secondary"
                                    height={'20px'}
                                    mb={'5px'}
                                    noOfLines={1}
                                    title={`${item.registration_year} ${item.model} ${item.variant}`}
                                >
                                    {`${item.registration_year ?? ''} ${item.model} ${item.variant}`}
                                </Text>
                            </Link>
                            <Text
                                fontSize="18px"
                                fontWeight={'bold'}
                                color="secondary"
                                mb={'5px'}
                            >{currencyFormatter(item.selling_price)}</Text>
                            <Stack
                                direction={'row'}
                                spacing={'10px'}
                            >
                                <CiLocationOn size={20} />
                                <Text
                                    as='span'
                                    display={'block'}
                                    fontSize="14px"
                                    color="gray"
                                >
                                    {item.dealer_name}
                                </Text>
                            </Stack>
                            <Stack py={3} direction={'row'} spacing={'10px'}>
                                {/* <Image src={`/images/${process.env.REACT_APP_THEME}/icons/mileage.svg`} alt="mileage" boxSize={8} /> */}
                                <BsSpeedometer2 size={20} />
                                <Text
                                    as='span'
                                    display={'block'}
                                    fontSize="14px"
                                    color="gray"
                                    textAlign="center"
                                >
                                    {item.odo_reading} {" "} Km
                                </Text>
                            </Stack>
                        </Box>
                        <Flex justify="space-between" mb={3} mx={2}>
                            <Link
                                as={RouterLink}
                                to={`/vehicle-details/${btoa(item.lead_id)}`}
                                style={{ textDecoration: 'none' }}>
                                <Button leftIcon={<ArrowForwardIcon className='card__actionIcon' transition={'margin .2s ease-in'} />} variant='blank' w={'100%'} color={'primary'} fontSize={'14px'}  >
                                    More Details
                                </Button>
                            </Link>
                            <Button variant='outline' w={'full'} fontSize={'14px'} onClick={() => handleOpenEnquiry(item)} textTransform={"none"}>
                                I am Interested
                            </Button>
                        </Flex>
                        <Button leftIcon={item.btnDisabled ? <IoAddCircleSharp size={20} /> : <IoAddCircleOutline size={20} />} variant='ghost' onClick={() => handleCompare(item)} disabled={item.btnDisabled ? true : false}>
                            {item.compareText ? item.compareText : "Add to compare"}
                        </Button>
                    </Box>
                ))
                }

            </Grid>
            <MakeEnquiryModal open={enqModal.isOpen} close={enqModal.onClose} payload={payload} />
        </>
    )
}
