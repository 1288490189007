import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import ibbApi from "src/api/ibbApi";

import { IToken } from "src/commonTypes";
export const fetchToken = createAsyncThunk(
    "token/fetchToken",
    () => {
        const params = {
            user_name: process.env.REACT_APP_API_USERNAME,
            password: process.env.REACT_APP_API_PASSWORD 
        }
        return ibbApi
            .post(
                'get-access-token',
                params,
            )
            .then((response) => response.data)
            .catch((error) => error)
    }
);

const LSToken = JSON.parse(`${localStorage.getItem('token')}`);
const hasLSToken = (LSToken !== null)
const tokenSlice = createSlice({
    name: "ibbToken",
    initialState: {
        status: "idle",
        token: hasLSToken ? LSToken.token : null,
        expires: hasLSToken ? LSToken.expires : null,
        error: {}
    },
    reducers: {
    },
    extraReducers: {
        [fetchToken.pending.type]: (state) => {
            state.status = "loading"
            state.token = ""
            state.expires = ""
            state.error = {}
        },
        [fetchToken.fulfilled.type]: (state, action: PayloadAction<IToken>) => {
                state.status = "success"
                state.token = action.payload.access_token
                state.expires = action.payload.expires_at
                state.error = {}
                const tokenArr = {
                    "token": action.payload.access_token,
                    "expires": action.payload.expires_at
                }
                localStorage.setItem('token', JSON.stringify(tokenArr))
        },
        [fetchToken.rejected.type]: (state, action: PayloadAction<IToken>) => {
            state.status = "error"
            state.token = ""
            state.expires = ""
            state.error = action.payload
        },
    },
});

export default tokenSlice.reducer;