import React, { useEffect, useState } from 'react';
import {
    Box,
    Text,
    Grid,
    Flex,
    Button,
} from "@chakra-ui/react";
import AutoComplete from '../AutoComplete';
import FetchCitiesByState from 'src/api/getCitiesByState';
import { useAppSelector } from 'src/redux/hooks';

// interface IData {
//     sliderStep: number;
//     nextSliderStep: () => void;
//     setCity: (values: any) => void;
//     state: string | null;
// }

export default function SliderCity({ sliderStep, nextSliderStep, setCity, state }: any) {
    const token = useAppSelector((state: any) => state.ibbToken.token)
    const [cityList, setCityList] = useState<any>([]);
    useEffect(() => {
        const res = FetchCitiesByState(token, state);
        res.then((data: any) => setCityList(data))
    }, []) // eslint-disable-line
    const handleSubmit = (values: any) => {
        setCity(values)
        nextSliderStep();
    };

    return (
        <Flex
            h={"100%"}
            flexDirection="column"
        >
            <Text
                as="h3"
                fontSize={"md"}
                fontWeight={"medium"}
                color={"secondary"}
                pb="20px"
            >
                SELECT CITY
            </Text>
            <AutoComplete
                data={cityList}
                cb={handleSubmit}
            />
            <Box overflowY={"scroll"}>
                <Grid templateColumns="repeat( 1fr)" gap="8px">
                    {(cityList.length !== 0) ? cityList.map((item: any, index: number): any => (
                        <Button
                            key={index}
                            variant={'listBtn'}
                            onClick={() => handleSubmit(item)}
                        >
                            {item}
                        </Button>
                    )) : null}
                </Grid>
            </Box>
        </Flex>
    );
}