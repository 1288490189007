import React, { FormEvent, ChangeEvent, FunctionComponent, useState } from 'react'
import {
    Box,
    Button,
    Drawer,
    DrawerBody,
    DrawerOverlay,
    DrawerContent,
    DrawerCloseButton,
    useDisclosure,
    Text,
    FormControl,
    Input,
    FormErrorMessage,
    Center,
    useToast,
    Image,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription
} from '@chakra-ui/react'
import dealerRequest from 'src/api/dealerRequest'

const styles = {
    title: {
        color: '#343a40',
        fontSize: '20px',
        fontWeight: 'semibold',
        textTransform: 'uppercase',
        mx: "auto"
    },
    formControl: {
        mb: '40px'
    },
    hgroup: {
        display: 'grid',
        mb: '30px',
        gridTemplateColumns: '94px 1fr',
        gap: '14px',
        pb: '30px',
        borderColor: 'borderClr',
        borderStyle: 'solid',
        borderBottomWidth: '1px',
        alignItems: 'flex-start'
    },
    hgroupImg: {
        w: '94px',
        h: '70px',
        objectFit: 'cover',
        borderRadius: '5px'
    },
    hgroupTitle: {
        color: 'black',
        fontSize: '24px',
        fontWeight: 'semibold',
        lineHeight: '1.2',
        mb: '8px'
    },
    hgroupPrice: {
        color: '#6e6e6e',
        fontSize: '18px',
    },
    formGrid: {
        gridTemplateColumns: '1fr 1fr',
        gap: 10
    },
    mobileGrid: {
        gridTemplateColumns: '1fr .3fr',
        gap: 10
    },
}

interface DealerLeadModalProps {
    dealerName: string
    image: string
    address: string,
    dealerId: number
}

const DealerLeadModal: FunctionComponent<DealerLeadModalProps> = ({ dealerName, image, address, dealerId }) => {
    const toast = useToast()
    const { isOpen, onOpen, onClose } = useDisclosure()
    const [name, setName] = useState<string>("")
    const [invalidName, setInvalidName] = useState<boolean>(false)
    const [mobileNo, setMobileNo] = useState<string>("")
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false)
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false)
    const [showMsg, setMsg] = useState<boolean>(false)

    const regexPattern = /^[0-9\b]+$/
    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        if (e.target.value === '' || regexPattern.test(e.target.value)) {
            setMobileNo(e.target.value)
        }
    }

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault()
        setSubmitBtnLoading(true)
        if (name.length === 0) {
            setInvalidName(true)
            setSubmitBtnLoading(false)
            return false
        } else if (mobileNo.length !== 10) {
            setInvalidMobile(true)
            setSubmitBtnLoading(false)
            return false
        } else {
            setInvalidName(false)
            setInvalidMobile(false)
            const params = {
                name: name,
                mobileNo: mobileNo,
                dealerId: dealerId
            }
            const res = dealerRequest(params)
            res.then((data: any) => {
                if (data.status === "success") {
                    setMsg(true)
                } else {
                    setMsg(false)
                    toast({
                        title: data.message,
                        status: 'error',
                        duration: 3000,
                        isClosable: true,
                    })
                }
            })
            setSubmitBtnLoading(false)
        }
    }

    return (
        <>
            <Button size={'xl'} w="full" type="button" onClick={onOpen}>Request Call Back</Button>

            <Drawer
                isOpen={isOpen}
                placement="right"
                onClose={onClose}
                size={'lg'}
            >
                <DrawerOverlay />
                <DrawerContent>
                    <DrawerCloseButton />
                    <DrawerBody p={["20px", "60px"]}>
                        <Box as="hgroup" sx={styles.hgroup}>
                            <Image sx={styles.hgroupImg} src={image} />
                            <Box>
                                <Text as="h3" sx={styles.hgroupTitle}>{dealerName}</Text>
                                <Text sx={styles.hgroupPrice} mb="5px">{address}</Text>
                            </Box>
                        </Box>
                        {showMsg ?
                            <Box>
                                <Alert
                                    status='success'
                                    borderRadius="14px"
                                    mb="30px"
                                >
                                    <AlertIcon boxSize='40px' />
                                    <Box pb="10px">
                                        <AlertTitle mt={4} mb={1} fontSize='lg'>Thanks for contacting!</AlertTitle>
                                        <AlertDescription maxWidth='sm'>Your Request Has been successfully sent to Dealer. Dealer will contact
                                            you soon.</AlertDescription>
                                    </Box>
                                </Alert>
                            </Box> :
                            <Box mb="56px">
                                <form onSubmit={handleSubmit}>
                                    <FormControl id="first-name" isRequired sx={styles.formControl} isInvalid={invalidName}>
                                        <Input placeholder="Enter Your Name" size='lg' value={name} onChange={(e: ChangeEvent<HTMLInputElement>) => setName(e.target.value)} type="text" />
                                        <FormErrorMessage>Your name shouldn't be blank.</FormErrorMessage>
                                    </FormControl>
                                    <FormControl id="mobileno2" isRequired sx={styles.formControl} isInvalid={invalidMobile}>
                                        <Input placeholder="Enter Mobile No" size='lg' value={mobileNo} onChange={validateMobileNo} type="tel" />
                                        <FormErrorMessage>Your Mobile No. is invalid</FormErrorMessage>
                                    </FormControl>
                                    <Center>
                                        <Button
                                            type='submit'
                                            variant={'primary'}
                                            size="lg"
                                            isLoading={submitBtnLoading}
                                            loadingText='Submitting'
                                        >Submit</Button>
                                    </Center>
                                </form>
                            </Box>
                        }
                    </DrawerBody>
                </DrawerContent>
            </Drawer>
        </>
    )
}

export default DealerLeadModal